<template>
  <div v-if="prePaymentInfo" class="">
    <div>
      <div class="label">宿泊料およびキャンセル料に充てる利用指定TVP数</div>
      <div>{{ prePaymentInfo.prepaymentAmount | toThousands }} Pt</div>
    </div>
    <div class="d-flex">
      <div>
        <div class="label">残TVPポイント数</div>
        <div>{{ prePaymentInfo.currentTvp | toThousands }} Pt</div>
      </div>
      <div class="ml-2">
        <v-btn color="var(--bt__red)" outlined @click="change">ポイント数利用・変更</v-btn>
      </div>
    </div>
    <prePaymentChangeDialog
      :visible="dialog"
      :currentTvpPlusRegisteredAmount="currentTvpPlusRegisteredAmount"
      :maxUsePoint="maxUsePoint"
      :targetChargesTotal="prePaymentInfo.targetCharges"
      :currentPrePayment="prePaymentInfo.prepaymentAmount"
      @changed="update"
      @close="dialog = false"
    />
  </div>
  <div v-else></div>
</template>

<script>
import prePaymentChangeDialog from './prePaymentChangeDialog.vue'

export default {
  components: {
    prePaymentChangeDialog
  },
  props: {
    prePaymentInfo: Object,
    updatePrePayment: Function
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    maxUsePoint () {
      return Math.min(this.prePaymentInfo.currentTvp + this.prePaymentInfo.prepaymentAmount, this.prePaymentInfo.targetCharges)
    },
    currentTvpPlusRegisteredAmount () {
      return this.prePaymentInfo.currentTvp + this.prePaymentInfo.prepaymentAmount
    }
  },
  watch: {
    dialog (v) {
      if (v) {
        // reset the value
        this.localPoint = this.prePaymentInfo.prepaymentAmount
      }
    }
  },
  methods: {
    change () {
      this.dialog = true
    },
    async update (newValue) {
      await this.updatePrePayment(newValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  font-weight: bold;
}
.item {
  margin-bottom: 16px;
}
</style>
