export function getPriceUnit (billing) {
  if (billing.spPoint !== null) {
    return 'SP'
  }
  if (billing.fpPoint !== null) {
    return 'FP'
  }
  if (billing.tvpPoint !== null) {
    return 'TVP'
  }
  if (billing.price !== null) {
    return '¥'
  }
  return ''
}

export function pointPrice (billing) {
  return billing.spPoint ?? billing.fpPoint ?? billing.tvpPoint
}
