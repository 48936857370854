import { chargeTypePayableByTVP } from '../../constants/bookingChargeType'

// we just need one of these to be in the list to show the payment method
const VS_FEE = 'バケーションスタイル宿泊'

export default {
  components: {
  },
  props: {
    charges: Array,
    prePaymentInfo: Object,
    paymentInstruction: Object
  },
  computed: {
    maxUsePoint () {
      if (!this.prePaymentInfo) return 0
      return Math.min(this.prePaymentInfo.currentTvp + this.prePaymentInfo.prepaymentAmount, this.prePaymentInfo.targetCharges)
    },
    isDefaultCCPayment () {
      return this.paymentInstruction?.defaultPaymentType === 'RegisteredCC'
    },
    isDefaultOnsitePayment () {
      return this.paymentInstruction?.defaultPaymentType === 'Onsite'
    },
    showCc () {
      return this.paymentInstruction?.registerdCCSubjects.length || this.isDefaultCCPayment
    },
    showOnsite () {
      return this.paymentInstruction?.onsiteSubjects.length || this.isDefaultOnsitePayment
    },
    remainingCharges () {
      return this.charges.filter((c) => !c.bookingInvoiceId && c.priceWithSalesTax > 0)
    },
    paymentForAccommodationCharges () {
      if (this.paymentInstruction.registerdCCSubjects.includes(VS_FEE)) {
        return 'RegisteredCC'
      }
      if (this.paymentInstruction.onsiteSubjects.includes(VS_FEE)) {
        return 'Onsite'
      }
      if (this.isDefaultCCPayment) return 'RegisteredCC'
      return 'Onsite'
    },
    ccCharges () {
      return this.remainingCharges.filter((c) => {
        if (this.paymentInstruction.registerdCCSubjects.includes(c.subject)) {
          return true
        }
        if (this.paymentInstruction.onsiteSubjects.includes(c.subject)) {
          return false
        }
        return this.isDefaultCCPayment
      })
    },
    onsiteCharges () {
      return this.remainingCharges.filter((c) => {
        if (this.paymentInstruction.onsiteSubjects.includes(c.subject)) {
          return true
        }
        if (this.paymentInstruction.registerdCCSubjects.includes(c.subject)) {
          return false
        }
        return this.isDefaultOnsitePayment
      })
    },
    totalCc () {
      return this.calcTotal(this.ccCharges)
    },
    totalOnsite () {
      return this.calcTotal(this.onsiteCharges)
    },
    tvpPayableCharges () {
      return this.remainingCharges.filter((c) => chargeTypePayableByTVP(c.typeId))
    },
    tvpPayableChargeIds () {
      return this.tvpPayableCharges.map((c) => c.id)
    },
    ccChargesAfterTvp () {
      return this.ccCharges.filter((c) => !this.tvpPayableChargeIds.includes(c.id))
    },
    onsiteChargesAfterTvp () {
      return this.onsiteCharges.filter((c) => !this.tvpPayableChargeIds.includes(c.id))
    },
    totalTvpPayableCharges () {
      return this.calcTotal(this.tvpPayableCharges)
    },
    totalCcAfterTvp () {
      return this.calcTotal(this.ccChargesAfterTvp)
    },
    totalOnsiteAfterTvp () {
      return this.calcTotal(this.onsiteChargesAfterTvp)
    },
    paymentTotalPerMethod () {
      let tvpChargeLeft = this.totalTvpPayableCharges
      const prePaymentRemain = this.prePaymentInfo?.remainingPoint || 0
      const totalTvp = Math.min(tvpChargeLeft, prePaymentRemain)
      tvpChargeLeft -= totalTvp

      let cc = this.totalCcAfterTvp
      let onsite = this.totalOnsiteAfterTvp
      if (tvpChargeLeft) {
        if (this.paymentForAccommodationCharges === 'RegisteredCC') {
          cc += tvpChargeLeft
        } else {
          onsite += tvpChargeLeft
        }
      }
      return {
        totalTvp,
        totalCc: cc,
        totalOnsite: onsite
      }
    }
  },
  methods: {
    calcTotal (charges) {
      return charges.reduce((acc, c) => acc + c.priceWithSalesTax * c.quantity, 0)
    }
  }
}
