import gql from 'graphql-tag'

export const BOOKING_CHANGE_HISTORY_LIST = gql`
query ($bookingId: Int!) {
    bookingChangeHistoryList(
      bookingId: $bookingId
    ) {
      changedAt
      changedByName
      fieldName
      valueBefore 
      valueAfter 
    }
  }
`
