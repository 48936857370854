<template>
  <div v-if="paymentInstruction" class="container">
    <div>上記レ点がついた金額で作成</div>
    <div class="ml-3">
      <v-btn color="primary" @click="click" outlined>
        <v-icon>mdi-plus</v-icon>未作成請求書への反映
      </v-btn>
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import WithPaymentInstructionMixin from './WithPaymentInstructionMixin'

export default {
  mixins: [WithPaymentInstructionMixin],
  computed: {
  },
  methods: {
    click () {
      const { totalTvp, totalCc, totalOnsite } = this.paymentTotalPerMethod
      this.$emit('click', {
        totalTvp,
        totalCc,
        totalCash: totalOnsite
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 10px 20px 0 20px;
  display: flex;
  align-items: center;
}
.label {
  font-weight: bold;
  width: 130px;
}
.item {
  margin-bottom: 16px;
  width: 250px;
  text-align: left;
}
.summary-row {
  display: flex;
  margin-top: 10px;
}
</style>
