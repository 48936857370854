import { render, staticRenderFns } from "./certificateDialog.vue?vue&type=template&id=af9ec762&scoped=true&"
import script from "./certificateDialog.vue?vue&type=script&lang=js&"
export * from "./certificateDialog.vue?vue&type=script&lang=js&"
import style0 from "./certificateDialog.vue?vue&type=style&index=0&id=af9ec762&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af9ec762",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VDialog,VIcon})
