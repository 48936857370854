export const BookingPaymentMethodId = {
  Cash: 1, // 現金
  CCTOP: 21, // TOP
  CCDC: 22, // DC
  CCUC: 23, // UC
  CCMaster: 24, // MASTER
  CCVisa: 25, // VISA
  CCJCB: 26, // JCB
  CCUnionPay: 27, // 銀聯
  VoucherStore: 41, // ストア 商品券
  VoucherDepart: 42, // 百貨店 商品券
  VoucherRecreation: 43, // ホテルズ ギフトカード it used to be -> レク券 商品券
  VoucherCampaignDiscount: 44, // キャンペーン割引券 added in TO2020-658
  TokyuPoint: 61, // TOKYUポイント
  TVP: 62, // TVP
  OnAccount: 81, // 売掛
  OTAPayment: 82, // OTA精算
  RegisteredCC: 101 // 登録カード
}
