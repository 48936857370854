<template>
  <v-dialog v-model="visible" @click:outside="closeDialog" width="600">
    <v-form ref="form">
      <v-card>
        <v-card-title>
          <p class="font-weight-medium custom-title">
            {{ $t("calendar.rental.addRentalListingPopup") }}
          </p>
        </v-card-title>
        <v-card-text class="mt-8 mr-5">
          <v-row>
            <v-col cols="12" sm="4" class="d-flex justify-end">
              <div class="d-flex align-center">
                <span class="custom-file-name">{{ $t("calendar.rental.rentalListing") }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="7" class="d-flex align-center">
              <v-select
                @change="getIdSelectRental( $event)"
                v-model="idSelectRental"
                item-text="name"
                item-value="id"
                dense
                outlined
                :items="arrRentalItem"
                hide-details
                class="text-size-normal fied-select"
                :disabled="!checkPerUser"
              />
            </v-col>
            <v-col cols="12" sm="4" class="d-flex justify-end">
              <v-icon class="custom-icon mb-5">mdi-calendar-month</v-icon>
            </v-col>
            <v-col cols="12" sm="7">
              <v-menu
                v-model="menuDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <div>
                    <v-text-field
                      :rules="rule.datePicker"
                      readonly
                      dense
                      outlined
                      :value="formatDate(date)"
                      v-on="on"
                      class="btn-date-picker custom-picker"
                      :disabled="!checkPerUser"
                    ></v-text-field>
                  </div>
                </template>
                <v-date-picker
                  @change="changeDate($event)"
                  :max="checkOutDate"
                  :min="checkInDate"
                  no-title
                  scrollable
                  v-model="date"
                  range
                  :first-day-of-week="0"
                  :locale="$i18n.locale"
                  class="v-date-picker-custom"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    color="var(--bt__blue)"
                    class="white--text"
                    @click="menuDate = false"
                  >
                    {{ $t("buttons.save") }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" class="d-flex justify-end">
              <div class="d-flex align-center">
                <span class="custom-file-name">{{ $t("common.totalQuantity") }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="7" class="d-flex align-center">
              <v-select
                v-model="selectQuanlity"
                dense
                outlined
                :items="itemQuantity"
                hide-details
                class="text-size-normal fied-select"
                :disabled="!checkPerUser"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row class="d-flex align-center justify-end mt-6 mr-12 mb-3">
            <v-btn
              color="var(--bt__red)"
              class="mr-3 d-flex align-center btn_status white--text"
              @click="closeDialog"
            >
              <v-icon class="white--text mt-1"> mdi-close </v-icon>
              {{ $t("common.close") }}
            </v-btn>
            <v-btn
              class="white--text"
              color="var(--bt__blue)"
              @click="actionCreate()"
              :disabled="!checkPerUser"
            >
              {{ $t("common.save") }}
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'

export default {
  name: 'addRentalListing',
  props: {
    visible: Boolean,
    arrRentalItem: Array,
    checkOutDate: String,
    checkInDate: String,
    arrNew: Array
  },
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      countQuantity: 0,
      dateInput: [],
      rule: {
        datePicker: [v => this.checkdatePicker(v) || this.$t('rules.isRequired'),
          v => this.checkDuplicate(v) || this.$t('rules.isInvalid')
        ]
      },
      quantity: [],
      itemQuantity: [],
      idSelectRental: null,
      selectQuanlity: null,
      menuDate: false,
      date: []
    }
  },

  watch: {
    checkInDate () {
      this.setDefaultDate()
    }
  },

  mounted () {
    this.setDefaultDate()
  },

  methods: {
    ...mapMutations({ setBookingRental: 'setBookingRental' }),
    ...mapActions({ actionCreateRental: 'addRentalItemReservation' }),

    getIdSelectRental (event) {
      this.itemQuantity = []
      this.countQuantity = 0
      for (let i = 0; i < this.arrNew.length; i++) {
        if (event === this.arrNew[i].rentalItem.id) {
          this.countQuantity = this.countQuantity + this.arrNew[i].quantity
        }
      }
      for (let i = 1; i < this.arrRentalItem.length; i++) {
        if (event === this.arrRentalItem[i].id) {
          this.quantity = this.arrRentalItem[i].quantity
          for (let i = 1; i <= this.quantity; i++) {
            this.itemQuantity.push(i)
          }
        }
      }
      const item = this.arrRentalItem.find(i => i.id === event)
      for (let i = 1; i <= item.quantity; i++) {
        this.itemQuantity.push(i)
      }
    },

    checkdatePicker (date) {
      if (date !== null) {
        date = date.split(' ~ ')
        if (date.length < 2) {
          return false
        } else {
          return true
        }
      }
    },

    checkDuplicate (date) {
      date = date.split(' ~ ')
      if (date.length === 2) {
        if (date[0] === date[1]) {
          return false
        } else {
          return true
        }
      }
    },

    actionCreate () {
      if (this.$refs.form.validate()) {
        const bookingRental = {
          rentalItemId: this.idSelectRental,
          fromDate: this.date[0],
          toDate: this.date[1],
          quantity: this.selectQuanlity
        }
        this.setBookingRental({ bookingRental: bookingRental })
        this.actionCreateRental()
        this.closeDialog()
      }
    },

    setDefaultDate () {
      this.date = []
      this.date.push(this.checkInDate)
      this.date.push(this.checkOutDate)
    },

    changeDate (event) {
      this.dateInput = event
      const date1 = new Date(this.date[0])
      const date2 = new Date(this.date[1])
      const date3 = date2.getTime() - date1.getTime()
      if (date3 > 0) {
        this.date = []
        this.date.push(date1.toISOString().substr(0, 10))
        this.date.push(date2.toISOString().substr(0, 10))
      } else {
        this.date = []
        this.date.push(date2.toISOString().substr(0, 10))
        this.date.push(date1.toISOString().substr(0, 10))
      }
    },

    formatDate (date) {
      const dates = date.join(' ~ ')
      return dates.replace(/-/g, '/')
    },

    closeDialog () {
      this.$refs.form.resetValidation()
      this.setDefaultDate()
      this.selectQuanlity = null
      this.idSelectRental = null
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .custom-title {
    font-size: 28px !important;
    color: #AAAAAA
  }
  .custom-file-name {
    color: #666666;
    font-size: 18px;
  }
  ::v-deep {
    .v-select__selection--comma {
      font-size: 18px !important;
      color: #666666;
    }
    .custom-icon {
      font-size: 30px !important;
      color: #616161
    }
    .custom-picker input {
      font-size: 18px;
      color:#666666
    }
    .fied-select .theme--light {
      color: #424242 !important;
    }
    .v-btn__content {
      font-size: 14px;
    }
  }
</style>
