<template>
  <div class="pa-5">
    <h3 class="text--title">
      {{ isMw ? 'MWグループ請求明細' : $t("contractDetail.groupMasterBillingStatement") }}
    </h3>
    <v-card class="pa-5 t-card mt-5" elevation="4">
      <v-row>
        <v-col cols="12" class="col-cus-7 overflow-auto">
          <table>
            <tr>
              <div class="t-header">
                <h4 class="fz-13">{{ $t("common.subjectName") }}</h4>
                <h4 class="fz-13">{{ $t("common.unit") }}</h4>
                <h4 class="fz-13">{{ $t("common.unitPrice") }}</h4>
                <h4 v-if="!isMw" class="text--small fz-9">{{ $t("common.points") }}</h4>
                <h4 class="fz-13">{{ $t("common.order") }}</h4>
                <h4 class="fz-10">{{ $t("common.salesTaxRate") }}</h4>
                <h4 class="fz-13">{{ $t("common.subtotal") }}</h4>
                <h4 class="fz-13">請求書</h4>
                <h4 class="text--small fz-10">請求書作成</h4>
              </div>
            </tr>
            <tr v-if="getBillingStatementList.length > 0">
              <div ref="heigthDetailPayment" :style="`height: ${(heigthDetailPayment >= 450 ? '450px': 'auto')}; overflow-y: auto;`">
                <div v-for="(item, index) in getBillingStatementList" :key="index">
                  <div class="bg--gray-dark py-1 px-3 mb-1 text--white fz-10">
                    {{ formatNightIndex(item[0].stayDate) + $t("common.night") }}
                    <span class="ml-3" v-if="item.length > 0">{{ `${formatDateStay(item[0].stayDate)}` }}</span>
                  </div>
                  <template v-if="bookingTypeId">
                    <NightRentItemList
                    v-for="child in item"
                    :key="child.id"
                    :night-point="index+1"
                    :billing-detail="child"
                    class="mb-0"
                    :booking-type-id="bookingTypeId"
                    @fetchList="getListBillingStatement()"
                  />
                  </template>
                </div>
              </div>
            </tr>
            <tr v-else >見つかりませんでした。</tr>
            <tr v-if="getBillingStatementList.length > 0">
              <TaxSummary :totalByTaxRate="getTotalByTaxRate"/>
              <ChargeInfo :chargeInfo="getChargeInfo" class="mt-2" />
              <div class="d-flex justify-space-between bg--silver pa-3 fz-14">
                <PrePaymentInfo :prePaymentInfo="prePaymentInfo" :updatePrePayment="updatePrePayment"/>
                <Summary :billings="getBillingStatementList" />
              </div>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" class="col-cus-5">
          <h4 class="mb-5 fz-14">{{ $t("common.notices") }}</h4>

          <!-- Data text -->
          <charge-remarks :chargeRemarks="chargeRemarks" />
          <div v-if="hideRegisteredCardPayment" class="text--small fz-9">
            登録カード利用不可
          </div>
        </v-col>
      </v-row>

      <div>
        <EasyPaymentPopulate
          v-if="shouldShowNewInvoiceForm"
          :charges="selectedCharges"
          :prePaymentInfo="prePaymentInfo"
          :paymentInstruction="paymentInstruction"
          :disabled="!checkPerUser"
          @click="easyPaymentPopulateClick"
        />
      </div>

      <!-- list payment detail  -->
      <div ref="heigthDetailPaymentMethod" :style="`height: ${(heigthDetailPaymentMethod >= 450 ? '450px': 'auto')}; overflow-y: auto;`">
        <v-card class="invoice pa-5 bg--silver mt-5" elevation="5" v-for="(item, index) in getBillingStatementListPayment" :key="`paymentDetail-${index}`">
          <div class="create-invoice-form">
            <template v-for="(itemPay, indexPay) in item.payments" >
              <div :key="`pay${indexPay}`" class="mb-5">
                <div v-if="isShowCategory(index, indexPay, itemPay)">
                  <h6 class="fz-10">{{ `請求書${index + 1}精算方法${indexPay+1}` }}</h6>
                  <v-select
                    :key="indexPay"
                    v-model="itemPay.bookingPaymentMethod.category"
                    :items="categoryPaymentMethod"
                    dense
                    small
                    outlined
                    hide-details
                    class="v-select-custom mr-3"
                    no-data-text="見つかりませんでした。"
                    disabled
                  ></v-select>
                </div>

                <div class="d-flex">
                  <div class="wd-paymethod">
                    <h6 class="mt-4 fz-10">{{ `請求書${index + 1}精算方法種類${indexPay+1}` }}</h6>
                    <v-select
                      v-model="itemPay.bookingPaymentMethod.id"
                      :items="paymentMethodListRoot"
                      dense
                      small
                      outlined
                      hide-details
                      class="v-select-custom mr-3"
                      item-text="name"
                      item-value="id"
                      no-data-text="見つかりませんでした。"
                      disabled
                    ></v-select>
                  </div>
                  <div class="wd-paymount">
                    <h6 class="mt-4 fz-10">金額{{ indexPay + 1 }}</h6>
                    <v-text-field
                      :value="itemPay.paymentAmount"
                      dense
                      solo
                      hide-details
                      class="amount-pay"
                      disabled
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </template>
            <v-btn
              large
              class="btn--primary mt-4 fz-12"
              :class="{ attention: !item.finalizedAt }"
              @click="detailPayment(item, index + 1)"
              :disabled="!checkPerUser"
            >
              {{ item.finalizedAt!== null ? `請求書${index + 1}を表示（発行登録済）` : `請求書${index + 1}を表示（未発行登録）`}}
            </v-btn>
          </div>
          <div class="remainingChargeSummary">
            <RemainChargeSummary
              v-if="!shouldShowNewInvoiceForm && getBillingStatementListPayment.length === index + 1"
              :charges="charges"
              :prePaymentInfo="prePaymentInfo"
              :paymentInstruction="paymentInstruction"
            />
          </div>
          <div>
            <v-btn large
              v-if="!item.finalizedAt"
              class="btn--primary mt-4 fz-12 attention"
              @click="editPayment(item, index + 1)"
              :disabled="!checkPerUser"
            >
              再編集
            </v-btn>
          </div>
        </v-card>
      </div>
      <!-- option payment -->
      <v-card class="invoice pa-5 bg--silver mt-5" elevation="5" v-if="shouldShowNewInvoiceForm">
        <div>
          <h6 class="fz-10">{{ `請求書${getBillingStatementListPayment.length + 1}精算方法` }}</h6>
          <v-autocomplete
            v-model="categoryPaymentMethodSelected"
            :items="categoryPaymentMethod"
            dense
            small
            outlined
            hide-details
            class="v-select-custom mr-3"
            no-data-text="見つかりませんでした。"
            :disabled="!checkPerUser"
          ></v-autocomplete>

          <!-- signe payment method -->
          <div v-if="!mutiplatePaymentMethods" >
            <h6 class="mt-4 text-card-gray fz-10" :class="categoryPaymentMethodSelected ? 'text-card-pay': ''">{{ `請求書${getBillingStatementListPayment.length + 1}精算方法種類` }}</h6>
            <v-autocomplete
              v-model="listCardSelected"
              :items="listCard"
              dense
              small
              outlined
              hide-details
              class="v-select-custom mr-3"
              item-text="name"
              item-value="id"
              no-data-text="見つかりませんでした。"
              :disabled="!categoryPaymentMethodSelected || !checkPerUser"
            ></v-autocomplete>
          </div>
          <div v-else>
            <!-- mutiplate payment method -->
            <InvoicePaymentLineInput
              :value="{ method: listCardSelectedMutiplate1, amount: amountMutiplate1 }"
              @input="listCardSelectedMutiplate1 = $event.method; amountMutiplate1 = $event.amount"
              :options="listCard"
              :index="1"
              :disabled="!checkPerUser"
            />
            <InvoicePaymentLineInput
              :value="{ method: listCardSelectedMutiplate2, amount: amountMutiplate2 }"
              @input="listCardSelectedMutiplate2 = $event.method; amountMutiplate2 = $event.amount"
              :options="listCard"
              :index="2"
              :disabled="!checkPerUser"
            />
            <InvoicePaymentLineInput
              :value="{ method: listCardSelectedMutiplate3, amount: amountMutiplate3 }"
              @input="listCardSelectedMutiplate3 = $event.method; amountMutiplate3 = $event.amount"
              :options="listCard"
              :index="3"
              :disabled="!checkPerUser"
            />
            <InvoicePaymentLineInput
              :value="{ method: listCardSelectedMutiplate4, amount: amountMutiplate4 }"
              @input="listCardSelectedMutiplate4 = $event.method; amountMutiplate4 = $event.amount"
              :options="listCard"
              :index="4"
              :disabled="!checkPerUser"
            />
          </div>
          <v-btn
            large
            class="btn--primary attention mt-4 fz-12"
            @click="createPaymentBilling"
            :disabled="isDisabledCreatePaymentBillingButton()"
          >
            {{ `チェックした科目で請求書${getBillingStatementListPayment.length + 1}を生成` }}
          </v-btn>
        </div>
        <div>
          <RemainChargeSummary
            :charges="charges"
            :prePaymentInfo="prePaymentInfo"
            :paymentInstruction="paymentInstruction"
          />
        </div>
        <div>
        </div>
      </v-card>
    </v-card>

    <!-- EDIT DIALOG BILLING -->
    <BaseDialog
      v-model="openDialogEditBillingPdf"
      :visible="openDialogEditBillingPdf"
      @close="closeEditBillingPdf"
      title=""
      :width="'80%'"
    >
      <template v-slot:content>
        <v-row>
          <!-- view pdf  -->
          <v-col cols="12" sm="9">
            <embed v-if="pdfFileUrl" :src="pdfUrlHideScrollbar(pdfFileUrl)" width="100%" height="100%">
          </v-col>

          <!-- action  -->
          <v-col cols="12" sm="3">
            <v-row class="justify-end">
              <v-btn dense class="btn--red-dark" @click="closeEditBillingPdf">
                <v-icon class="mr-2">mdi-close</v-icon>
                {{ $t("buttons.close") }}
              </v-btn>
              <v-btn
                dense
                class="btn--primary ml-1"
                :disabled="(Object.keys(getCreateBookingInvoicesCurrent).length !== 0 && (getCreateBookingInvoicesCurrent.finalizedAt !== null)) || !checkPerUser"
                @click="updateBookingInvoices"
              >
                {{ $t("buttons.save") }}
              </v-btn>
            </v-row>
            <v-row class="mt-5">
              <!-- 請求書1 -->
              <v-card
                class="ml-0 mr-0"
                width="100%"
              >
                <v-card-text>
                  <div class="mb-4 fz-20 color-title-text">{{ `請求書${getCreateBookingInvoicesCurrent.indexPayment}`}}</div>
                  <v-form ref="updateBookingInvoices">
                    <v-text-field
                      @input="inputAddressName($event)"
                      :value="addresseeName"
                      dense
                      type="text"
                      label="芳名"
                      class="fz-10 mb-3 input-update"
                      :disabled="!checkPerUser"
                    ></v-text-field>
                    <v-text-field
                      @input="inputReceiptFor($event)"
                      v-model="receiptFor"
                      dense
                      type="text"
                      label="但し書き"
                      class="input-update"
                      :disabled="!checkPerUser"
                    ></v-text-field>
                  </v-form>
                  <p class="fz-14 mb-0 color-title-email-text">{{ `請求書${getCreateBookingInvoicesCurrent.indexPayment}送り先メールアドレスを選択`}}</p>
                  <v-radio-group v-model="radioGroup" hide-details class="fz-14 mt-0 input-radio">
                    <v-radio
                      :key="1"
                      label="発送先メールアドレス"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      :key="2"
                      label="宿泊代表者メールアドレス"
                      :value="2"
                    ></v-radio>
                    <v-radio
                      :key="3"
                      label="入力"
                      :value="3"
                    ></v-radio>
                  </v-radio-group>
                  <v-form ref="finalizeBookingInvoices">
                    <v-text-field
                      v-model="email"
                      dense
                      type="text"
                      class="mt-5 input-email"
                      label="送り先メールアドレス"
                      :rules="[emailBlank, regexMail]"
                      :disabled="disableEmail || !checkPerUser"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
              </v-card>

              <!-- 発行登録・登録カード支払い -->
               <v-card
                class="ml-0 mr-0 mt-3"
                width="100%"
                v-if="(Object.keys(getCreateBookingInvoicesCurrent).length !== 0 && (getCreateBookingInvoicesCurrent.finalizedAt === null))"
              >
                <v-card-text>
                  <div class="fz-20" style="color: #000000">発行登録・登録カード支払い</div>
                  <v-btn
                    dense
                    class="btn--red-dark mt-2"
                    width="150"
                    :disabled="sendMailDone"
                    @click="finalizeBookingInvoices"
                  >
                    {{ btnTextSendMail}}
                  </v-btn>
                  <div class="color-text-pay mt-2 fz-14">
                    <div>1,発行登録後は請求明細書がお客様のページから確認できるようになり、請求明細の編集ができなくなります。</div>
                    <p> 2,支払い方法に会員登録済みカード・新ポイント決済を選択していた場合、このタイミングで請求処理を行います。</p>
                  </div>
                </v-card-text>
              </v-card>

              <!-- 発行登録・登録カード支払い -->
               <v-card
                class="ml-0 mr-0 mt-3"
                width="100%"
                v-if="(Object.keys(getCreateBookingInvoicesCurrent).length !== 0 && (getCreateBookingInvoicesCurrent.finalizedAt !== null))"
              >
                <v-card-text>
                  <div style="color: #000000">発行登録・登録カード支払い</div>
                  <v-btn
                    dense
                    class="btn--gray-dark mt-2"
                    width="150"
                    disabled
                  >
                    発行登録済
                  </v-btn>
                </v-card-text>
              </v-card>

              <!-- 削除 -->
               <v-card
                class="ml-0 mr-0 mt-3"
                width="100%"
              >
                <v-card-text>
                  <div class="fz-20" style="color: #000000">削除</div>
                  <v-btn
                    dense
                    class="btn--red-dark mt-2"
                    width="150"
                    @click="(Object.keys(getCreateBookingInvoicesCurrent).length !== 0 && (getCreateBookingInvoicesCurrent.finalizedAt === null)) ? askDeleteBookingInvoices() : ''"
                    :disabled="(Object.keys(getCreateBookingInvoicesCurrent).length !== 0 && (getCreateBookingInvoicesCurrent.finalizedAt !== null)) || sendMailDone"
                  >
                    {{ `請求書${getCreateBookingInvoicesCurrent.indexPayment}を削除`}}
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </BaseDialog>
    <!-- --- -->
  </div>
</template>
<script>
import BaseDialog from '@/components/Dialog/BaseDialog'
import { mapMutations, mapGetters } from 'vuex'
import NightRentItemList from '@/components/NightRentItem/list'
import gql from 'graphql-tag'
import {
  BOOKING_PAYMENT_METHOD_LIST
} from '@/api/graphql/billingStatement/listBillingStatement'

import Summary from './summary.vue'
import TaxSummary from './TaxSummary.vue'
import ChargeRemarks from './chargeRemarks'
import mixin from './BillingStatementMixin'
import { BookingTypeIds } from '@/constants/bookingType'
import PrePaymentInfo from './prePaymentInfo.vue'
import RemainChargeSummary from './RemainChargeSummary.vue'
import InvoicePaymentLineInput from './InvoicePaymentLineInput.vue'
import EasyPaymentPopulate from './EasyPaymentPopulate.vue'
import ChargeInfo from './ChargeInfo.vue'

export default {
  name: 'ListBillingStatement',
  mixins: [mixin],
  components: {
    NightRentItemList,
    BaseDialog,
    Summary,
    TaxSummary,
    ChargeRemarks,
    PrePaymentInfo,
    RemainChargeSummary,
    InvoicePaymentLineInput,
    EasyPaymentPopulate,
    ChargeInfo,
  },
  computed: {
    ...mapGetters(['getBillingStatementList', 'getChargeIds', 'getChargePrice',
      'getCreateBookingInvoicesCurrent', 'getBillingStatementListPayment',
      'getTotalByTaxRate', 'getChargeInfo',
    ]),
    hideRegisteredCardPayment () {
      const allowedBookingTypes = [
        BookingTypeIds.SP,
        BookingTypeIds.FP,
        BookingTypeIds.DP,
        BookingTypeIds.PW,
        BookingTypeIds.MW,
        BookingTypeIds.MO
      ].includes(this.bookingTypeId) // is 旧商品

      // submembers are not allowed to pay with registered card,
      // unless the client is a corporate member && the booking is pre 2022 旧商品
      // see TO2020-552 for the deatil
      return !!this.createdSubMember?.subMemberTypeId &&
        !(this.isCompany && allowedBookingTypes)
    }
  },
  methods: {
    ...mapMutations({
      setCopyErrorText: 'setCopyErrorText',
      setAlertSuccess: 'setAlertSuccess',
      setAlertError: 'setAlertError',
      setBillingStatementList: 'setBillingStatementList',
      setChargeIdsDefault: 'setChargeIdsDefault',
      setCreateBookingInvoicesCurrent: 'setCreateBookingInvoicesCurrent',
      setLoadingOverlayShow: 'setLoadingOverlayShow',
      setLoadingOverlayHide: 'setLoadingOverlayHide',
      setPermissionUser: 'setPermissionUser',
      setRoleAdminUser: 'setRoleAdminUser'

    }),
    test (...args) {
      console.log(...args)
    },
    async bookingPaymentMethodList () {
      await this.$apollo.query({
        query: gql`${BOOKING_PAYMENT_METHOD_LIST}`
      }).then((data) => {
        if (data.data.enumBookingPaymentMethodList.length > 0) {
          this.paymentMethodListRoot = data.data.enumBookingPaymentMethodList
          // filter category payment method
          const items = data.data.enumBookingPaymentMethodList.map(item => item.category)
          let registerdCCCategory
          if (this.hideRegisteredCardPayment) {
            // family members are not allowed to use the registered credit card, so
            this.paymentMethodListRoot.forEach(i => {
              if (i.id === 101) {
                registerdCCCategory = i.category
                i.disabled = true
              }
            })
          }
          // disabled
          this.categoryPaymentMethod = [...new Set(items), '複数その他'].map(i => ({
            text: i,
            value: i,
            disabled: i === registerdCCCategory
          }))
        }
      }).catch(async (error) => {
        this.setCopyErrorText(this.getCopyErrorTextView(BOOKING_PAYMENT_METHOD_LIST, null, error.graphQLErrors))
        const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-select-custom.v-input {
  max-width: 300px;
  background-color: var(--bg__white);
}
h4,
h5,
h6 {
  font-weight: 500;
}
.t-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 70px;
  padding-right: 15px;
  margin-bottom: 8px;
  h4 {
    text-align: center;
    flex-shrink: 0;
    width: 60px;
    &:nth-child(1) {
      width: 150px;
      text-align: left;
    }
    &:nth-child(5) {
      width: 40px;
    }
    &:nth-child(3),
    &:last-child {
      text-align: right;
    }
  }
}
::v-deep {
  .v-card {
    color: var(--text__dark);
  }
  .t-card .row {
    .col-md-8 {
      overflow-x: auto;
    }
    table {
      width: 100%;
      tr {
        &:not(:first-child, :last-child) {
          font-size: 12px;
        }
      }
    }
  }
}
::v-deep {
  .fz-9 {
    font-size: 9px !important;
  }
  .fz-12 {
    font-size: 12px !important;
  }
  .fz-14 {
    font-size: 14px !important;
  }
  .fz-11 {
    font-size: 11px !important;
  }
  .fz-10 {
    font-size: 10px !important;
  }
  .fz-13 {
    font-size: 13px !important;
  }
  .fz-15 {
    font-size: 15px !important;
  }
  .fz-16 {
    font-size: 16px !important;
  }
  .fz-20 {
    font-size: 20px !important;
  }
  .fz-18 {
    font-size: 18px !important;
  }
  .v-messages__message {
    font-size: 12px;
  }
  .text-card-gray {
    color: #AAAAAA;
  }
  .text-card-pay {
    color: rgb(0, 0, 0)
  }
  .color-text-pay {
    color: #C92D2D;
  }
  .color-title-text {
    color: #000000;
  }
  .color-title-email-text {
    color: #444444;
  }
  .col-cus {
    &-7 {
      @media (min-width: 960px) {
        flex: 0 0 67%;
        max-width: 67%;
      }
      @media (min-width: 1365px) {
        flex: 0 0 61%;
        max-width: 61%;
      }
    }
    &-5 {
      @media (min-width: 960px) {
        flex: 0 0 33%;
        max-width: 33%;
      }
      @media (min-width: 1365px) {
        flex: 0 0 39%;
        max-width: 39%;
      }
    }
  }
}
// ::v-deep .input-update .v-text-field__slot .v-label {
//   font-size: 10px !important;
//   color: #000000;
// }
// ::v-deep .input-email .v-text-field__slot .v-label {
//   font-size: 10px !important;
//   color: #AAAAAA;
// }
::v-deep .input-update {
  .v-input__control .v-text-field__slot{
    input {
      color: #000000 !important;
    }
  }
}
::v-deep .input-email {
  .v-input__control .v-text-field__slot{
    input {
      color: #AAAAAA !important;
    }
  }
}
::v-deep .input-radio .v-label {
  color: #444444;
}
::v-deep .amount-pay .v-input__control {
  max-height: 30px !important;
  min-height: unset !important;
}
.wd-paymount {
  width: 120px;
  margin-top: 1px;
}
.wd-paymethod {
  width: 320px;
}

.invoice {
  display: flex;
  justify-content: space-between;
}
</style>
