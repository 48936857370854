<template>
  <v-dialog v-model="visible" @click:outside="closeDialog" width="600">
   <v-form ref="form">
      <v-card>
      <v-card-title>
        <p class="mt-2 ml-2 custom-title">{{ $t('calendar.parkingCars.addParkingReservation') }}</p>
      </v-card-title>
      <v-card-text class="mt-8 mr-5">
        <v-row>
          <v-col cols="12" sm="4" class="d-flex justify-end">
            <v-icon class="mb-6 icon-calendar">mdi-calendar-month</v-icon>
          </v-col>
          <v-col cols="12" sm="7">
             <v-menu
                v-model="menuDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <div>
                    <v-text-field
                      :rules="rule.datePicker"
                      readonly
                      dense
                      outlined
                      :value="formatDate(date)"
                      v-on="on"
                      class="btn-date-picker custom-input"
                      :disabled="!checkPerUser"
                    ></v-text-field>
                  </div>
                </template>
                <v-date-picker
                  @change="changeDate($event)"
                  :max="checkOutDate"
                  :min="checkInDate"
                  no-title
                  scrollable
                  v-model="date"
                  range
                  :first-day-of-week="0"
                  :locale="$i18n.locale"
                  class="v-date-picker-custom"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    color="var(--bt__blue)"
                    class="white--text"
                    @click="menuDate = false"
                  >
                    {{ $t("buttons.save") }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
          </v-col>
          <v-col cols="12" sm="4"  class="d-flex justify-end">
            <div class="d-flex align-center">
              <span class="custom-field mb-5">車高</span>
            </div>
          </v-col>
          <v-col cols="12" sm="7" class="d-flex align-center">
            <tvos-int-input
              v-model="height"
              :rules="heightRule"
              :max="maxHeight"
              dense
              outlined
              :disabled="!checkPerUser || !parkingId"
            />
          </v-col>
          <v-col cols="12" sm="4"  class="d-flex justify-end">
            <div class="d-flex align-center">
              <span class="custom-field mb-5">{{ $t('calendar.parkingCars.parkingLot') }}</span>
            </div>
          </v-col>
          <v-col cols="12" sm="7" class="d-flex align-center">
            <v-select
              :rules="rule.nameParking"
              v-model="parkingId"
              item-text="name"
              item-value="id"
              dense
              outlined
              :items="listNameParking"
              class="text-size-normal"
              :disabled="!checkPerUser"
            />
          </v-col>
          <v-col cols="12" sm="4"  class="d-flex justify-end">
            <div class="d-flex align-center">
              <span class="custom-field mb-5">{{ $t('calendar.parkingCars.carNumber') }}</span>
            </div>
          </v-col>
          <v-col cols="12" sm="7" class="d-flex align-center">
            <v-text-field
              v-model="carNumber"
              dense
              outlined
              class="text-size-normal"
              :disabled="!checkPerUser"
            />
          </v-col>
          <v-col cols="12" sm="4"  class="d-flex justify-end">
            <div class="d-flex align-center">
              <span class="custom-field ">{{ $t('calendar.parkingCars.carColor') }}</span>
            </div>
          </v-col>
          <v-col cols="12" sm="4" class="d-flex align-center">
            <div class="text-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                  style="border: 1px solid #9E9E9E"
                    :color="color"
                    dark
                    v-on="on"
                    :disabled="!checkPerUser"
                  />
                </template>
                <car-color-picker
                  :rules="rule.color"
                  v-model="color"
                  class="mx-auto"
                />
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row class="d-flex align-center justify-end mt-5 mr-12 mb-3">
          <v-btn
            color="var(--bt__red)"
            class="mr-3 d-flex align-center btn_status white--text"
            @click="closeDialog"
          >
            <v-icon class="white--text"> mdi-close </v-icon>
            <p class="custom-btn">{{ $t('common.close') }}</p>
          </v-btn>
          <v-btn
           @click="actionCreateParking()"
            class="white--text"
            color="var(--bt__blue)"
            :disabled="!checkPerUser"
          >
           <p class="custom-btn"> {{ $t('common.save') }}</p>
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
   </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import CarColorPicker from '../../../../components/Input/CarColorPicker.vue'

export default {
  name: 'addParkingReservation',
  components: { CarColorPicker },
  props: {
    visible: Boolean,
    checkOutDate: String,
    checkInDate: String,
    listNameParking: Array
  },
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      parkingId: null,
      rule: {
        datePicker: [v => this.checkdatePicker(v) || this.$t('rules.isRequired'),
          v => this.checkDuplicate(v) || this.$t('rules.isInvalid')
        ],
        nameParking: [v => !!v || this.$t('rules.isRequired')],
        color: [v => !!v || this.$t('rules.isRequired')],
        carNumber: [v => !!v || this.$t('rules.isRequired'),
          v => v?.length < 26 || this.$t('rules.isInvalid')]
      },
      height: null,
      selectCarHeight: '指定無し',
      selectParkingLot: '機械式1',
      menuDate: false,
      date: [],
      dateInput: [],
      color: '#E0E0E0',
      carNumber: ''
    }
  },

  computed: {
    selectedParking () {
      return this.listNameParking.find(p => p.id === this.parkingId)
    },
    maxHeight () {
      return this.selectedParking?.maxHeight || undefined
    },
    heightRule () {
      if (this.maxHeight) {
        return [this.$rules.required]
      }
      return []
    }
  },

  watch: {
    checkInDate () {
      this.setDefaultDate()
    }
  },

  mounted () {
    this.setDefaultDate()
  },

  methods: {
    ...mapMutations({ setBookingParking: 'setBookingParking' }),
    ...mapActions(['addParkingReservation']),

    isNumber: function (evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 44 && charCode < 58 && charCode !== 47 && charCode !== 46 && charCode !== 45) || (charCode === 13)) {
        return true
      } else {
        evt.preventDefault()
      }
    },

    actionCreateParking () {
      if (this.$refs.form.validate()) {
        const bookingParking = {
          parkingId: this.parkingId,
          fromDate: this.date[0],
          toDate: this.date[1],
          carNumber: this.carNumber,
          color: this.color,
          height: this.height
        }
        this.closeDialog()
        this.setBookingParking({ bookingParking: bookingParking })
        this.addParkingReservation()
      }
    },

    changeDate (event) {
      this.dateInput = event
      const date1 = new Date(this.date[0])
      const date2 = new Date(this.date[1])
      const date3 = date2.getTime() - date1.getTime()
      if (date3 > 0) {
        this.date = []
        this.date.push(date1.toISOString().substr(0, 10))
        this.date.push(date2.toISOString().substr(0, 10))
      } else {
        this.date = []
        this.date.push(date2.toISOString().substr(0, 10))
        this.date.push(date1.toISOString().substr(0, 10))
      }
    },

    checkdatePicker (date) {
      if (date !== null) {
        date = date.split(' ~ ')
        if (date.length < 2) {
          return false
        } else {
          return true
        }
      }
    },

    checkDuplicate (date) {
      date = date.split(' ~ ')
      if (date.length === 2) {
        if (date[0] === date[1]) {
          return false
        } else {
          return true
        }
      }
    },

    formatDate (date) {
      const dates = date.join(' ~ ')
      return dates.replace(/-/g, '/')
    },

    setDefaultDate () {
      this.date = []
      this.date.push(this.checkInDate)
      this.date.push(this.checkOutDate)
    },

    closeDialog () {
      this.$refs.form.resetValidation()
      this.setDefaultDate()
      this.carNumber = ''
      this.color = '#000000'
      this.height = null
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-title {
  font-size: 28px;
  color: #AAAAAA !important;
  font-weight: bold !important;
}
::v-deep {
  .mdi-calendar-month::before {
    font-size: 28px !important;
    color: #616161
  }
   input {
    font-size: 18px !important;
    color: #666666 !important;
  }
  .custom-field {
    font-size: 18px !important;
    color: #666666;
  }
  .v-select__selection--comma {
    font-size: 18px !important;
    color: #666666;
  }
  svg {
    color: #000000 !important;
    width: 30px;
    height: 30px;
  }
  .custom-btn {
    margin-top: 13px;
    font-size: 14px !important;
  }
  .mdi-close::before {
    height: 21px;
    font-size: 23px !important ;
  }
}
</style>
