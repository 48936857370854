<template>
  <div class="text--small fz-9" v-if="chargeRemarks && chargeRemarks.length > 0">
    <p v-for="(item,index) in chargeRemarks" :key="index">
      <charge-remarks-line :line="item" />
    </p>
  </div>
</template>

<script>
import chargeRemarksLine from './chargeRemarksLine.vue'
export default {
  components: { chargeRemarksLine },
  props: {
    chargeRemarks: Array
  }
}
</script>
