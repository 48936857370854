<template>
  <div>
    <template v-if="$apollo.loading">読み込み中...</template>
    <template v-else>
      <!-- (1) -->
      <template v-if="isShowAccomList">
        <AccomItem
          v-for="order in 10"
          :key="order + 'vs-ac'"
          type="vs"
          v-bind="{
            roomId: roomTypeId,
            order,
            dateListAll,
            minPrice,
            maxPrice,
            accommodationPriceDict: loadedPriceDict,
          }"
          @hoverItem="hoverAccomItem"
          @leaveItem="leaveAccomItem"
          @clickItem="clickAccomItem"
        />
      </template>
      <!-- (2) -->
      <template v-if="isShowBookingList">
        <BookingPlanItem
          v-for="rowItem in filterBookingPlan('VS')"
          :key="rowItem.id"
          :type="rowItem.targetBookingType"
          :dateColWidth="dateColWidth"
          v-bind="{ minPrice, maxPrice, fromDate, dateListAll, rowItem }"
        />
      </template>
      <!-- (3) -->
      <template v-if="isShowAccomList">
        <AccomItem
          v-for="order in 10"
          :key="order + 'vm-ac'"
          type="vm"
          v-bind="{
            roomId: roomTypeId,
            order,
            dateListAll,
            minPrice,
            maxPrice,
            accommodationPriceDict: loadedPriceDict,
          }"
          @hoverItem="hoverAccomItem"
          @leaveItem="leaveAccomItem"
          @clickItem="clickAccomItem"
        />
      </template>
      <!-- (4) -->
      <template v-if="isShowBookingList">
        <BookingPlanItem
          v-for="rowItem in filterBookingPlan('MO')"
          :key="rowItem.id"
          :type="rowItem.targetBookingType"
          :dateColWidth="dateColWidth"
          v-bind="{ minPrice, maxPrice, fromDate, dateListAll, rowItem }"
        />
      </template>
      <!-- (5) -->
      <template v-if="isShowAccomList">
        <AccomItem
          type="sp"
          v-bind="{
            roomId: roomTypeId,
            dateListAll,
            minPrice,
            maxPrice,
            accommodationPriceDict: loadedPriceDict,
          }"
          @hoverItem="hoverAccomItem"
          @leaveItem="leaveAccomItem"
          @clickItem="clickAccomItem"
        />
      </template>
      <!-- (6) -->
      <template v-if="isShowBookingList">
        <BookingPlanItem
          v-for="rowItem in filterBookingPlan('SP')"
          :key="rowItem.id"
          :type="rowItem.targetBookingType"
          :dateColWidth="dateColWidth"
          v-bind="{ minPrice, maxPrice, fromDate, dateListAll, rowItem }"
        />
      </template>
      <!-- (7) -->
      <template v-if="isShowAccomList">
        <AccomItem
          type="fp"
          v-bind="{
            roomId: roomTypeId,
            dateListAll,
            minPrice,
            maxPrice,
            accommodationPriceDict: loadedPriceDict,
          }"
          @hoverItem="hoverAccomItem"
          @leaveItem="leaveAccomItem"
          @clickItem="clickAccomItem"
        />
      </template>
      <!-- 8 -->
      <template v-if="isShowBookingList">
        <BookingPlanItem
          v-for="rowItem in filterBookingPlan('FP')"
          :key="rowItem.id"
          :type="rowItem.targetBookingType"
          :dateColWidth="dateColWidth"
          v-bind="{ minPrice, maxPrice, fromDate, dateListAll, rowItem }"
        />
      </template>
      <!-- 9 -->
      <template v-if="isShowAccomList">
        <AccomItem
          type="dp"
          v-bind="{
            roomId: roomTypeId,
            dateListAll,
            minPrice,
            maxPrice,
            accommodationPriceDict: loadedPriceDict,
          }"
          @hoverItem="hoverAccomItem"
          @leaveItem="leaveAccomItem"
          @clickItem="clickAccomItem"
        />
      </template>
      <!-- (10) -->
      <div class="t-calendar-item" v-if="isShowAccomList">
        <div class="t-calendar-item__title">MW</div>
        <div class="t-calendar-item__data-list">
          <div
            v-for="date in dateListAll"
            :key="date"
            class="t-calendar-item__data-item bg--dp"
          >
            {{ findMWItem(date) }}
          </div>
        </div>
      </div>
      <!-- mv -->
      <!-- (11) -->
      <template v-if="isOutOfStock">
        <div v-for="nonProduct in nonProductList" :key="nonProduct.id">
          <div
            class="t-calendar-item"
            v-for="index in 10"
            :key="index + 'non-product'"
            :set="
              (nonProductPrice = findNonProductPrice(roomTypeId, nonProduct.id))
            "
          >
            <div class="t-calendar-item__title">
              {{ nonProduct.name }}{{ index }}
            </div>
            <div class="t-calendar-item__data-list">
              <div
                v-for="date in dateListAll"
                :key="date"
                class="t-calendar-item__data-item"
              >
                {{ getNonProductPriceDate(nonProductPrice, date, index) }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
// components
import BookingPlanItem from './BookingPlanItem'
import { ACCOMMODATION_PRICE_LIST, NON_PRODUCT_PRICE } from '@/api/graphql/accommodation-price'
import { handlError, getCopyErrorTextView, formatCurrency } from '@/constants/functions'
import AccomItem from './AccomItem'
import { getAllDateInBetween2Dates } from '@/utils/handleDate'
import gql from 'graphql-tag'
import { mapMutations } from 'vuex'

export default {
  components: {
    BookingPlanItem,
    AccomItem
  },
  props: {
    fromDate: String,
    toDate: String,
    facility: Object,
    roomType: Object,
    minPrice: Number,
    maxPrice: Number,
    isShowAccomList: {
      type: Boolean,
      default: true
    },
    isShowBookingList: {
      type: Boolean,
      default: true
    },
    isOutOfStock: {
      type: Boolean,
      default: true
    },
    forReservation: Boolean,
    dateColWidth: Number,
    nonProductList: Array
  },
  data () {
    return {
      loadedPriceDict: {},
      loadedPlans: [],
      loadedMwCalendar: {},
      loadedNonProductPriceDict: {}
    }
  },
  computed: {
    bookingPlans () {
      return this.bookingPlanList ?? this.loadedPlans
    },
    dateListAll () {
      return getAllDateInBetween2Dates(this.fromDate, this.toDate)
    },
    roomTypeId () {
      return this.roomType.id
    },
    needsLoad () {
      return !this.accommodationPriceList || !this.bookingPlanList
    }
  },
  methods: {
    async reload () {
      await this.$apollo.queries.loadData.refetch()
    },
    getCopyErrorTextView,
    ...mapMutations(['setCopyErrorText']),
    filterBookingPlan (type) {
      const plans = this.bookingPlans.filter(
        (item) =>
          item.roomTypeId === this.roomTypeId &&
          item.targetBookingType === type &&
          item.enabled
      )
      return plans
    },
    hoverAccomItem (...params) {
      this.$emit('hoverAccomItem', ...params)
    },
    leaveAccomItem (...params) {
      this.$emit('leaveAccomItem', ...params)
    },
    clickAccomItem (...params) {
      this.$emit('clickAccomItem', ...params)
    },
    findMWItem (currentDate) {
      const item = this.loadedMwCalendar[currentDate]

      return item ? item.mwRank : ''
    },
    getBookingPlans () {
      // this is being called outsite (from REservationCalendar is an example)
      return this.bookingPlans
    },
    findNonProductPrice (roomTypeId, nonProductId) {
      const nonProductPrice = this.loadedNonProductPriceDict[nonProductId]

      return nonProductPrice
    },
    getNonProductPriceDate (nonProductPrice, stayDate, index) {
      if (nonProductPrice) {
        const nonProductByDate = nonProductPrice[stayDate]
        if (nonProductByDate) return formatCurrency(nonProductByDate[`price${index}`])
      }
      return ''
    }
  },
  apollo: {
    loadData: {
      query: gql`
        ${ACCOMMODATION_PRICE_LIST}
      `,
      manual: true,
      fetchPolicy: 'no-cache',
      variables () {
        return {
          accommodationFromDate: this.fromDate,
          accommodationToDate: this.toDate,
          nonProductFromDate: this.fromDate,
          nonProductToDate: this.toDate,
          mwWeekCalendarFromDate: this.fromDate,
          mwWeekCalendarToDate: this.toDate,
          bookingPlanFilter: {
            availableFromDate: this.fromDate,
            availableToDate: this.toDate,
            roomTypeId: this.roomTypeId
          },
          facilityId: this.facility.id,
          roomTypeId: this.roomTypeId,
          includeNonProduct: false,
          includeMwCalendar: true
        }
      },
      skip () {
        return !this.needsLoad
      },
      result (result) {
        if (result.errors && result.errors.length) {
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              ACCOMMODATION_PRICE_LIST,
              {
                accommodationFromDate: this.fromDate,
                accommodationToDate: this.toDate,
                nonProductFromDate: this.fromDate,
                nonProductToDate: this.toDate,
                mwWeekCalendarFromDate: this.fromDate,
                mwWeekCalendarToDate: this.toDate,
                bookingPlanFilter: {
                  availableFromDate: this.fromDate,
                  availableToDate: this.toDate,
                  roomTypeId: this.roomTypeId
                },
                roomTypeId: this.roomTypeId,
                includeNonProduct: false,
                includeMwCalendar: false
              },
              result.errors.graphQLErrors
            )
          )
          handlError(result.errors)
        }
        this.loadedPriceDict = result.data.accommodationPriceList.reduce((result, row) => {
          result[row.stayDate] = row
          return result
        }, {})
        this.loadedPlans = result.data.bookingPlanList.items.filter(i => i.enabled)
        this.loadedMwCalendar = result.data.mwWeekCalendarList.reduce((result, row) => {
          result[row.mwDate] = row
          return result
        }, {})
      }
    },
    nonProductPrice: {
      query: gql`${NON_PRODUCT_PRICE}`,
      manual: true,
      fetchPolicy: 'no-cache',
      variables () {
        return {
          nonProductFromDate: this.fromDate,
          nonProductToDate: this.toDate,
          roomTypeId: this.roomTypeId
        }
      },
      skip () {
        return !this.needsLoad || !this.isOutOfStock
      },
      result (result) {
        if (result.errors && result.errors.length) {
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              NON_PRODUCT_PRICE,
              {
                nonProductFromDate: this.fromDate,
                nonProductToDate: this.toDate,
                roomTypeId: this.roomTypeId
              },
              result.errors.graphQLErrors
            )
          )
          handlError(result.errors)
        } else {
          this.loadedNonProductPriceDict = result.data.nonProductPriceList.reduce((result, row) => {
            if (!result[row.nonProductId]) {
              result[row.nonProductId] = {}
            }
            const product = result[row.nonProductId]
            product[row.stayDate] = row
            return result
          }, {})
        }
      }
    }
  }
}
</script>
