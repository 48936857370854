var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-1 mb-5"},[_c('v-card',[_c('v-data-table',{staticClass:"pa-5 table-custom table-no-pagination",attrs:{"disable-sort":"","headers":_vm.header,"items":_vm.dataChangeHistoryList,"footer-props":{
        itemsPerPageText:
          _vm.numLength +
          _vm.$t('common.cases') +
          _vm.itemStart +
          '~' +
          _vm.itemStop +
          _vm.$t('common.showing'),
        itemsPerPageOptions: [10, 20, 50, 100, 200, 500, 1000],
      }},on:{"pagination":_vm.getNumOfPage},scopedSlots:_vm._u([{key:"item.changedAt",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateTimeStingWithSec")(item.changedAt)))])]}},{key:"item.changedBy",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.changedByName))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }