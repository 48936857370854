export const BookingAdditionalChargeTypeId = {
  ACCOMMODATION_FEE_FOR_MEMBERS: 1,
  NON_MEMBER_ACCOMMODATION_FEE: 2,
  FACILITY_FEE: 3,
  RIGHT_OF_USE_CLEANING_FEE: 4,
  OPTIONAL_CLEANING_FEE: 5,
  CANCELLATION_FEE: 6,
  CHARGED_RENTAL_ITEMS: 7,
  ITEMS_SOLD_AT_FRONT_DESK_TXR_10: 8,
  ITEMS_SOLD_AT_FRONT_DESK_TXR_8: 9,
  ITEMS_SOLD_AT_FRONT_DESK_TXR_EXEMPT: 10,
  OTHER_INCOME: 11,
  DEPOSITS_FOR_VARIOUS_SERVICE_CHARGES: 12,
  OTHER_DEPOSITS_RECEIVED: 13,
  ADJUSTMENT_OF_CASH_REGISTERS: 14,
  BATH_TAX: 15,
  LODGING_TAX: 16,
  PLAN_FEE: 17
}

export function chargeTypePayableByTVP (typeId) {
  return [
    BookingAdditionalChargeTypeId.ACCOMMODATION_FEE_FOR_MEMBERS,
    BookingAdditionalChargeTypeId.CANCELLATION_FEE
  ].includes(typeId)
}
