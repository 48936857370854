import { render, staticRenderFns } from "./BaseDialog.vue?vue&type=template&id=a8fe66de&scoped=true&"
import script from "./BaseDialog.vue?vue&type=script&lang=js&"
export * from "./BaseDialog.vue?vue&type=script&lang=js&"
import style0 from "./BaseDialog.vue?vue&type=style&index=0&id=a8fe66de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8fe66de",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VDialog})
