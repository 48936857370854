<template>
  <v-dialog v-model="visible" @click:outside="closeDialog">
    <v-card class="pa-10">
      <div class="d-flex justify-space-between text--small">
        <span>Date. {{ date }}<br />Page.1</span>
        <span>{{ $t("common.reservation") }}No.{{ fileNo }}</span>
      </div>
      <h2 class="page-title-main text-center mt-5 mb-12">{{ typeOfCertificate }}</h2>
      <div class="d-flex align-center">
        <div class="w-68 d-table">
          <div class="d-table-row">
            <span class="d-table-cell font-weight-light">{{
              $t("contractDetail.membershipNumber")
            }}</span>
            <span class="d-table-cell">{{ membershipNumber }}</span>
          </div>
          <div class="d-table-row">
            <span class="d-table-cell font-weight-medium">{{
              $t("contractDetail.yourName")
            }}</span>
            <h4 class="page-title-main d-table-cell">{{ fullName }}</h4>
            <span class="d-table-cell font-weight-medium text-right">{{
              $t("contractDetail.mr")
            }}</span>
          </div>
        </div>
      </div>
      <div v-if="!isTokyuCertificate" class="text-center mt-8">
        <h3 class="text--title font-weight-medium">
          <slot name="note"/>
        </h3>
        <h4 class="mt-3 font-weight-regular">
          スマートフォンをご利用の方はこの画面を提示してください。<br />
          印刷の必要はございません。
        </h4>
      </div>
      <table v-if="!isTokyuCertificate" class="mt-5">
        <tr v-if="rowMembershipNumber">
          <td>{{ $t("contractDetail.subscriberMembershipInformation") }}</td>
          <td>
            {{ rowMembershipNumber }} <br />
            {{ rowMembershipName }}
          </td>
        </tr>
        <tr v-if="rowFacility">
          <td>{{ $t("common.facility") }}</td>
          <td>
            {{ rowFacility }} <br />
            <span class="text--highlight">{{ rowFacilityNumber }}</span>
          </td>
        </tr>
        <tr v-if="rowPeriodStay">
          <td>{{ $t("contractDetail.periodOfStay") }}</td>
          <td>
            {{ rowPeriodStay }} <br />
            {{ rowPeriodStayTotal }}
          </td>
        </tr>
        <tr v-if="rowFees">
          <td>{{ $t("contractDetail.usageFees") }}</td>
          <td>{{ rowFees }}</td>
        </tr>
        <tr v-if="rowExchangeRate">
          <td>{{ $t("contractDetail.exchangeRate") }}</td>
          <td>{{ rowExchangeRate }}</td>
        </tr>
        <tr v-if="rowLodgingPoints">
          <td>{{ $t("contractDetail.lodgingPoints") }}</td>
          <td>{{ rowLodgingPoints }}</td>
        </tr>
        <tr v-if="rowRoomType">
          <td>{{ $t("common.roomType") }}</td>
          <td>{{ rowRoomType }}</td>
        </tr>
      </table>

      <!-- Tokyu Vacations Exchange Confirmation Certificate -->
      <template v-else>
        <h3 class="font-weight-regular mt-15 mb-1">
          {{ $t("contractDetail.exchangeSource") }}
        </h3>
        <table>
          <tr>
            <td>{{ $t("common.facility") }}</td>
            <td>
              {{ listExchangeSource.facility }} <br />
              <span class="text--highlight">{{
                listExchangeSource.facilityNumber
              }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("contractDetail.periodOfStay") }}</td>
            <td>
              {{ listExchangeSource.periodStayWeekRank }} <br />
              {{ listExchangeSource.periodStay }} <br />
              {{ listExchangeSource.poperiodStayTotal }}
            </td>
          </tr>
          <tr>
            <td class="col--small">{{ $t("common.roomType") }}</td>
            <td class="col--small">{{ listExchangeSource.roomType }}</td>
          </tr>
        </table>

        <div class="t-arrow-down">
          <v-icon large color="var(--bg__primary)">mdi-chevron-down</v-icon>
        </div>

        <h3 class="font-weight-regular mb-1">
          {{ $t("contractDetail.exchangeDestination") }}
        </h3>
        <table>
          <tr>
            <td>{{ $t("common.facility") }}</td>
            <td>
              {{ listExchangeDestination.facility }} <br />
              <span class="text--highlight">{{
                listExchangeDestination.facilityNumber
              }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("contractDetail.periodOfStay") }}</td>
            <td>
              {{ listExchangeDestination.periodStayWeekRank }} <br />
              {{ listExchangeDestination.periodStay }} <br />
              {{ listExchangeDestination.poperiodStayTotal }}
            </td>
          </tr>
          <tr>
            <td class="col--small">{{ $t("common.roomType") }}</td>
            <td class="col--small">{{ listExchangeDestination.roomType }}</td>
          </tr>
        </table>
      </template>
      <!-- ------------------------------------------------- -->

      <div class="mt-10">
        <span class="mr-5">{{ $t("common.payableDate") }}</span>
        {{ payableDate }}
      </div>
      <div class="d-flex justify-end">
        <div class="company-info">
          <img
            class="tokyu-logo"
            src="@/assets/images/logo-tokyu.png"
            alt="tokyu vacations"
          />
          <div>
            株式会社東急シェアリング <br />
            東急バケーションズコールセンター <br />
            〒 150-0002 東京都渋谷区渋谷1-16-14 渋谷地下鉄ビル3F <br />
            TEL. 03-3406-0109
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CertificateDialog',
  props: {
    visible: Boolean,

    date: String,
    fileNo: String,
    typeOfCertificate: String,
    membershipNumber: String,
    fullName: String,
    payableDate: String,
    rowMembershipNumber: String,
    rowMembershipName: String,
    rowFacility: String,
    rowFacilityNumber: String,
    rowPeriodStay: String,
    rowPeriodStayTotal: String,
    rowFees: String,
    rowExchangeRate: String,
    rowLodgingPoints: String,
    rowRoomType: String,

    // No Notices - Tokyu certificate -----
    isTokyuCertificate: {
      type: Boolean,
      default: false
    },
    listExchangeSource: {
      type: Array,
      default: () => [
        {
          facility: '',
          facilityNumber: '',
          periodStayWeekRank: '',
          periodStay: '',
          poperiodStayTotal: '',
          roomType: ''
        }
      ]
    },
    listExchangeDestination: {
      type: Array,
      default: () => [
        {
          facility: '',
          facilityNumber: '',
          periodStayWeekRank: '',
          periodStay: '',
          poperiodStayTotal: '',
          roomType: ''
        }
      ]
    }
    // --------------------------------------------------
  },
  computed: {
    dialog: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-dialog {
    width: 794px;
    font-size: 14px;
  }
  .w-68 {
    width: 68%;
    border-collapse: collapse;
    .d-table-row {
      height: 42px;
      &:last-child {
        border-bottom: solid 1px var(--border__inactive) !important;
        span {
          font-size: 16px;
        }
      }
    }
  }
  .qr-code {
    width: 42%;
    text-align: right;
  }
  .text--title {
    color: var(--text__dark) !important;
  }
}
table {
  width: 100%;
  border-collapse: collapse;
  tr,
  td {
    border: solid 1px var(--border__inactive);
    height: 100px;
    &.col--small {
      height: 50px;
    }
  }
  td {
    padding: 0 30px;
    &:first-child {
      background-color: var(--bg__silver);
      width: 200px;
    }
    &:last-child {
      font-weight: 600;
    }
  }
  .company-info {
    div {
      font-size: 13px;
    }
  }
}
.t-qrcode {
  &--big {
    width: 100px;
  }
  &--small {
    width: 70px;
    align-self: flex-end;
    margin-left: 5px;
  }
}
.t-arrow-down {
  height: 40px;
  position: relative;
  .v-icon {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 25px;
    border-left: dotted 4px var(--border__primary);
  }
}
</style>
