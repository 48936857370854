<template>
  <div class="px-main --dark">
    <h1 class="py-4 page-title-main" v-if="bookingTypeId === 11">
      その他予約{{ $route.query.priorityBooking === 'true' ? '(優先)' : ''}}_予約ID_{{ contractId }}
    </h1>
     <h1 class="py-4 page-title-main" v-if="bookingTypeId === 12">
      提携施設_予約ID_{{ contractId }}
    </h1>
    <h1 class="py-4 page-title-main" v-if="bookingTypeId !== 12 &&  bookingTypeId !== 11">
      {{type}}予約_予約ID_{{ contractId }}
    </h1>
    <template>
        <v-tabs v-model="tab" class="mb-10 d-inline-flex t-tab-custom">
          <v-tab v-for="item in items" :key="item.title" :href="'#'+item.tabUrl" class="px-8 fz-16">
            {{ item.title }}
          </v-tab>
        </v-tabs>
        <v-tabs-items :value="tab" touchless :class="isMinScreen ? 'min-width-screen' : ''">
            <v-tab-item v-if="$route.query.tab === 'basic-information'" value="basic-information" >
                <BasicInformation v-if="tab === 'basic-information'" />
            </v-tab-item>
            <v-tab-item value="billing-statement">
                <BillingStatement v-if="tab === 'billing-statement'" :key="`billingStatementKey-${billingStatementKey}`" />
            </v-tab-item>
            <v-tab-item value="guest-details">
                <GuestDetails v-if="tab === 'guest-details'" />
            </v-tab-item>
            <v-tab-item value="rental-listing">
                <RentalListing v-if="tab === 'rental-listing'" />
            </v-tab-item>
            <v-tab-item value="parking-place">
                <ParkingPlace v-if="tab === 'parking-place'" />
            </v-tab-item>
            <v-tab-item value="operation-change-history">
                <OperationChangeHistory v-if="tab === 'operation-change-history'" />
            </v-tab-item>
            <v-tab-item v-if="bookingTypeId !== 8" value="canceled">
                <Canceled v-if="tab === 'canceled'" />
            </v-tab-item>
        </v-tabs-items>
    </template>
  </div>
</template>

<script>
import BasicInformation from './basicInformation'
import BillingStatement from './billingStatement'
import Canceled from './canceled'
import GuestDetails from './guestDetails'
import OperationChangeHistory from './operationChangeHistory'
import ParkingPlace from './parkingPlace'
import RentalListing from './rentalListing.vue'
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import { mapMutations } from 'vuex'
import gql from 'graphql-tag'

export default {
  name: 'ContractDetailTab',
  data () {
    return {
      type: null,
      bookingTypeId: null,
      contractId: this.$route.query.id,
      isMinScreen: false,
      facilityType: null,
      billingStatementKey: 0
    }
  },
  computed: {
    items () {
      let result = []
      switch (this.facilityType) {
        case 'PT':
          result = [
            {
              title: this.$t('contractDetail.basicInformation'),
              tabUrl: 'basic-information'
            },
            {
              title: this.$t('contractDetail.billingStatement'),
              tabUrl: 'billing-statement'
            },
            {
              title: this.$t('contractDetail.guestDetails'),
              tabUrl: 'guest-details'
            },
            {
              title: this.$t('contractDetail.operationChangeHistory'),
              tabUrl: 'operation-change-history'
            },
            {
              title: this.$t('contractDetail.canceled'),
              tabUrl: 'canceled'
            }
          ]
          break
        case 'CNV':
          result = [
            {
              title: this.$t('contractDetail.basicInformation'),
              tabUrl: 'basic-information'
            },
            {
              title: this.$t('contractDetail.billingStatement'),
              tabUrl: 'billing-statement'
            },
            {
              title: this.$t('contractDetail.guestDetails'),
              tabUrl: 'guest-details'
            },
            {
              title: this.$t('contractDetail.parkingPlace'),
              tabUrl: 'parking-place'
            },
            {
              title: this.$t('contractDetail.operationChangeHistory'),
              tabUrl: 'operation-change-history'
            },
            {
              title: this.$t('contractDetail.canceled'),
              tabUrl: 'canceled'
            }
          ]
          break
        default:
          result = [
            {
              title: this.$t('contractDetail.basicInformation'),
              tabUrl: 'basic-information'
            },
            {
              title: this.$t('contractDetail.billingStatement'),
              tabUrl: 'billing-statement'
            },
            {
              title: this.$t('contractDetail.guestDetails'),
              tabUrl: 'guest-details'
            },
            {
              title: this.$t('contractDetail.rentalListing'),
              tabUrl: 'rental-listing'
            },
            {
              title: this.$t('contractDetail.parkingPlace'),
              tabUrl: 'parking-place'
            },
            {
              title: this.$t('contractDetail.operationChangeHistory'),
              tabUrl: 'operation-change-history'
            },
            {
              title: this.$t('contractDetail.canceled'),
              tabUrl: 'canceled'
            }
          ]
      }

      if (this.bookingTypeId === 8) {
        // ota cannot be cancelled
        result = result.filter(({ tabUrl }) => tabUrl !== 'canceled')
      }
      if (this.bookingTypeId === 12) {
        // point booking shoudn't have rental item or parking
        result = result.filter(({ tabUrl }) => tabUrl !== 'rental-listing' && tabUrl !== 'parking-place')
      }
      return result
    },
    tab: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
        this.setWidthScreen()
      },
      get () {
        return this.$route.query.tab
      }
    }
  },
  watch: {
    tab (newVal) {
      if (newVal) {
        switch (newVal) {
          case 'billing-statement':
            this.billingStatementKey = this.billingStatementKey + 1
            break
          default:
            break
        }
      }
    },
    $route: {
      immediate: true,
      handler (to, from) {
        this.setDocumentTitle()
      }
    }
  },

  async created () {
    await this.getBookingList()
  },

  mounted () {
    this.getFacilityList()
    this.setWidthScreen()
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations({
      setCopyErrorText: 'setCopyErrorText',
      setAlertSuccess: 'setAlertSuccess',
      setAlertError: 'setAlertError',
      setPermissionUser: 'setPermissionUser',
      setRoleAdminUser: 'setRoleAdminUser',
      setLoadingOverlayShow: 'setLoadingOverlayShow',
      setLoadingOverlayHide: 'setLoadingOverlayHide'
    }),
    setWidthScreen () {
      this.isMinScreen = this.tab === 'operation-change-history'
    },

    async getFacilityList () {
      await this.$apollo.query({
        query: gql`
        query ($id: Int!) {
          booking(id: $id) {
            room {
              roomType {
                facility {
                  id name type
                }      
              }    
            }
          }
        }`,
        variables: {
          id: parseInt(this.$route.query.id)
        }
      }).then((data) => {
        const facilityType = data.data.booking.room.roomType.facility.type
        this.facilityType = facilityType
      }).catch((error) => {
        console.error(error)
      })
    },

    async getBookingList () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`query ($id: Int!) {
            booking(id: $id) {
            bookingGroupId
            id
            bookingTypeId 
            bookingType { id name code }
          }
        }`,
          variables: {
            id: parseInt(this.$route.query.id)
          },
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.bookingTypeId = data.data.booking.bookingTypeId
          this.type = data.data.booking.bookingType.code
          this.setDocumentTitle()
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.setLoadingOverlayHide()
        })
    },
    setDocumentTitle () {
      if (this.bookingTypeId === 11) {
        this.$route.query.priorityBooking === 'true' ? document.title = 'その他予約(優先)_予約ID_' + this.contractId || '予約' : document.title = 'その他予約_予約ID_' + this.contractId || '予約'
      } else if (this.bookingTypeId === 12) {
        document.title = '提携施設_予約ID_' + this.contractId || '予約'
      } else {
        this.type ? document.title = this.type + '予約_予約ID_' + this.contractId || '予約' : document.title = '予約_予約ID_' + this.contractId || '予約'
      }
    }
  },
  components: {
    BasicInformation,
    BillingStatement,
    Canceled,
    GuestDetails,
    OperationChangeHistory,
    ParkingPlace,
    RentalListing
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    &.v-tabs-bar__content {
      background-color: var(--bg__white)
    }
     .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
    color: #aaaaaa !important;
  }
  }
  .min-width-screen {
    max-width: 80%
  }
  .fz-16 {
    font-size: 16px !important;
  }
</style>
