export function isEmailValid (email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const checkNumber = val => {
  const regex = /^[0-9]+$/
  return regex.test(val)
}

export function checkKatakana (kana) {
  if (kana === '') return true
  // eslint-disable-next-line no-irregular-whitespace
  return /^[ァ-ヶー 　]*$/.test(kana)
}

export function arrayEqual (a, b) {
  if (a === b) return true
  if (a == null || b == null) return true
  if (!Array.isArray(a)) a = [a]
  if (!Array.isArray(b)) b = [b]

  if (a.length !== b.length) return false
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}
