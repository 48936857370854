<template>
  <div class="pa-1 mb-5">
    <v-card>
      <v-data-table
        disable-sort
        :headers="header"
        :items="dataChangeHistoryList"
        class="pa-5 table-custom table-no-pagination"
        :footer-props="{
          itemsPerPageText:
            numLength +
            $t('common.cases') +
            itemStart +
            '~' +
            itemStop +
            $t('common.showing'),
          itemsPerPageOptions: [10, 20, 50, 100, 200, 500, 1000],
        }"
        @pagination="getNumOfPage"
      >
        <template v-slot:item.changedAt="{ item }">
          <span>{{
            item.changedAt | dateTimeStingWithSec
          }}</span>
        </template>

        <template v-slot:item.changedBy="{ item }">
          <span class="text-truncate">{{
            item.changedByName
          }}</span>
        </template>

      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { BOOKING_CHANGE_HISTORY_LIST } from '@/api/graphql/bookingDetail/booking-change-history.js'
import gql from 'graphql-tag'

export default {
  name: 'OperationChangeHistory',
  data () {
    return {
      dataChangeHistoryList: [],
      numLength: 0,
      itemStart: 0,
      itemStop: 0,
      historyList: [],
      header: [
        { text: this.$t('booking.dateTime'), value: 'changedAt', width: '200px' },
        { text: this.$t('booking.user'), value: 'changedBy', width: '200px' },
        { text: this.$t('booking.setting'), value: 'fieldName', width: '200px' },
        { text: this.$t('booking.oldValue'), value: 'valueBefore', width: '200px' },
        {
          text: this.$t('booking.newValue'),
          value: 'valueAfter',
          width: '200px'
        }
      ]
    }
  },
  mounted () {
    this.getHistoryList()
    this.loadList()
  },
  methods: {
    async loadList () {
      await this.$apollo
        .query({
          query: gql`${BOOKING_CHANGE_HISTORY_LIST}`,
          variables: {
            bookingId: parseInt(this.$route.query.id)
          },
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.dataChangeHistoryList = data.data.bookingChangeHistoryList
        })
        .catch((error) => {
          console.error(error)
        })
    },

    getHistoryList () {
      for (let i = 0; i < 15; i++) {
        this.historyList.push({
          id: i,
          dateTime: '2021-05-12 12:00',
          user: 'Kohei Imazu',
          setting: 'ステータス',
          oldValue: '確認済み',
          newValue: 'チェックイン済み'
        })
      }
    },
    getNumOfPage (pagination) {
      this.numLength = pagination.itemsLength
      this.itemStart = pagination.pageStart + 1
      this.itemStop = pagination.pageStop
    }
  },
  components: {}
}
</script>

<style lang="scss" scoped>
::v-deep {
  .table-custom {
    td {
      max-width: 200px !important;
      width: 100px !important;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;

      overflow: hidden;
    }
  }
  .v-data-table {
    thead th span {
      font-weight: normal;
      color: #757575 !important;
      font-size: 14px !important;
    }
    tbody tr td {
      font-size: 16px !important;
      color: #757575 !important;
    }
  }
  .v-data-footer__select {
    color: #757575 !important;
    font-size: 14px;
  }
  .v-select__selection--comma {
    font-size: 14px !important;
    color: #757575 !important;
  }
  .v-data-footer__pagination {
    font-size: 14px !important;
    color: #757575 !important;
  }
}
</style>
