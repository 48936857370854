<template>
  <div
    class="t-calendar-item"
  >
    <div class="t-calendar-item__title">
      {{ rowItem.title }}
    </div>
    <div class="t-calendar-item__data-list plan-row">
      <div
        class="t-calendar-item__data-item--abs"
        :class="getClassObject"
        :style="
          `left: calc(${dateColWidth}px * ${leftCellOffset}); width: calc(${dateColWidth}px * ${planCellWidth})`
        "
      >
        <template v-if="!inDay">
          <div class="plan-price-stay">{{leftCellOffset}}
            {{
                `${getChargeType} ¥${formatCurrency(
                  rowItem.fixedAdditionalCharge
                )}`
            }}
          </div>
        </template>
        <template v-else>
          <Pass
            v-for="el in planDates"
            :key="el + type + 'day'"
            :metadata="{
              price: findBookingChargeItem(rowItem.bookingPlanCharges, el),
            }"
          >
            <span
              slot-scope="{ metadata }"
              :class="{ 'font-weight-bold': isBlackedOut(metadata.price) }"
            >
              {{ metadata.price ? formatCurrency(metadata.price) : "" }}
            </span>
          </Pass>
        </template>
      </div>
      <div
        v-for="date in dateListAll"
        :key="date"
        class="t-calendar-item__data-item"
        :class="getDateClassObjecct"
      ></div>
    </div>
  </div>
</template>
<script>
import Pass from '@/components/shared/Pass.vue'
import { getAdditionalChargeType } from '@/utils/getAdditionalChargeType'
import { formatCurrency } from '@/constants/functions'
import { getDaysArray, getDiff2Days } from '@/utils/handleDate'

export default {
  name: 'BookingPlanItem',
  components: { Pass },
  props: {
    type: String,
    rowItem: Object,
    fromDate: String,
    minPrice: [Number, String],
    maxPrice: [Number, String],
    dateListAll: {
      type: Array,
      default: () => []
    },
    dateColWidth: Number
  },
  computed: {
    inDay () {
      return ['PersonDay', 'RoomDay'].includes(this.additionalChargeType)
    },
    additionalChargeType () {
      return this.rowItem.additionalChargeType
    },
    getChargeType () {
      return getAdditionalChargeType(this.additionalChargeType) + ' | '
    },
    getDateClassObjecct () {
      const type = this.type

      return {
        'bg--vs': type === 'VS',
        'bg--vm': type === 'MO',
        'bg--sp': type === 'SP',
        'bg--fp': type === 'FP'
      }
    },
    getClassObject () {
      const type = this.type

      return {
        '--multi': this.inDay,
        'bg--booking-vs': type === 'VS',
        'bg--booking-mo': type === 'MO',
        'bg--booking-fp': type === 'FP',
        'bg--booking-sp': type === 'SP',
        'font-weight-bold':
          !this.inDay && this.isBlackedOut(this.rowItem.fixedAdditionalCharge)
      }
    },
    leftCellOffset () {
      return this.checkFromDateBooking(this.rowItem.availableFromDate)
    },
    planDates () {
      return this.getRangeDateBooking(
        this.rowItem.availableToDate,
        this.rowItem.availableFromDate
      )
    },
    planCellWidth () {
      return this.planDates.length
    }
  },
  methods: {
    getAdditionalChargeType,
    formatCurrency,
    getDiff2Days,
    isBlackedOut (price) {
      if (!price) return
      const minPrice = this.minPrice ? Number(this.minPrice) : 0
      const maxPrice = this.maxPrice ? Number(this.maxPrice) : 0

      if (minPrice && maxPrice) {
        return price >= minPrice && price <= maxPrice
      }

      return (minPrice && price >= minPrice) || (maxPrice && price <= maxPrice)
    },
    checkFromDateBooking (fromDate) {
      const timeFromDate = new Date(fromDate).getTime()
      const timeFromDateCurrent = new Date(this.fromDate).getTime()

      if (timeFromDate <= timeFromDateCurrent) return 0
      return getDiff2Days(this.fromDate, fromDate)
    },
    getRangeDateBooking (toDate, fromDate) {
      const rangeDate = getDaysArray(new Date(fromDate), new Date(toDate))
      return this.dateListAll.filter((e) => rangeDate.indexOf(e) !== -1)
    },
    findBookingChargeItem (chargeList, stayDate) {
      const item = chargeList.find((el) => el.stayDate === stayDate)
      if (item) return item.fixedAdditionalCharge
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.plan-row {
  position: relative;
}

.t-calendar-item__data-item {
  align-items: flex-end;
  justify-content: flex-end;
  vertical-align: bottom;
  line-height: 1;
}

.plan-price-stay {
  position: sticky;
  left: 270px;
  right: 20px;
}
</style>
