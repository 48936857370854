<template>
  <div class="px-main --dark pl-0">
    <v-btn
      color="var(--bt__red)"
      outlined
      @click="removeBooking($route.query.id)"
      :disabled="!checkPerUser"
    >
      {{ $t('booking.deleteReservation') }}
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'

export default {
  name: 'Canceled',
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute)
    }
  },
  methods: {
    ...mapActions(['removeBooking'])
  }
}
</script>

<style lang="scss" scoped>

</style>
