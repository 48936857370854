<template>
  <div v-if="bookingTypeId">
    <div v-if="permanentBlock">ブロック予約のため非表示</div>
    <ListPointTieUp v-else-if="bookingTypeId === 12"></ListPointTieUp>
    <ListBillingStatement v-else></ListBillingStatement>
  </div>
</template>

<script>
import ListBillingStatement from '@/components/BillingStatement/list'
import ListPointTieUp from '@/components/BillingStatement/listPointTieUp'
import gql from 'graphql-tag'
import { mapMutations } from 'vuex'

export default {
  name: 'BillingStatement',
  components: {
    ListBillingStatement,
    ListPointTieUp
  },

  data () {
    return {
      bookingTypeId: null,
      bookingGroupId: null,
      permanentBlock: false
    }
  },

  created () {
    this.getBookingList()
  },
  methods: {
    ...mapMutations({
      setLoadingOverlayShow: 'setLoadingOverlayShow',
      setLoadingOverlayHide: 'setLoadingOverlayHide'
    }),
    async getBookingList () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`query ($id: Int!) {
            booking(id: $id) {
            bookingGroupId
            id
            bookingTypeId 
            permanentBlock
            bookingType { id name code }
          }
        }`,
          variables: {
            id: parseInt(this.$route.query.id)
          },
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.bookingGroupId = data.data.booking.bookingGroupId
          this.permanentBlock = data.data.booking.permanentBlock
          this.bookingTypeId = data.data.booking.bookingTypeId
        })
        .catch((error) => {
          this.setLoadingOverlayHide()
          console.error(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
