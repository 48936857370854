<template>
  <div class="px-5 px-sm-15 pt-5 py-10">
    <div class="text-right mb-5">
      <v-btn large class="btn--red-dark btn-pd" href="javascript:window.open('','_self').close();">
        <v-icon class="mr-2">mdi-close</v-icon>
        {{ $t("buttons.close") }}
      </v-btn>
      <v-btn large class="btn--primary ml-3 btn-pd" @click="$withDelay(actionUpdate)" :disabled ="!checkPerUser">
        {{ $t("buttons.save") }}
      </v-btn>
    </div>
    <v-row>
      <!-- Start Left Col -->
      <template v-if="booking.blockRoom">
        <v-col cols="12" lg="7" class="t-box--left">
          <p class="title-head">{{ $t("common.status") }}</p>
          <p class="title-head-child mb-2 mt-5">{{ $t("common.status") }}</p>
          <span
            style="color: #444444 !important"
            class="mr-5 mr-sm-16"
            v-if="booking.computed"
            >{{ booking.computed.status }}</span
          >
          <!-- {{ $t('common.confirmed') }} -->
          <template v-if="booking && booking.computed && booking.computed.status && booking.computed.status   !== 'キャンセル' && booking.bookingTypeId !== '7' && booking.bookingTypeId !== '9'">
            <v-btn
              style="font-size: 10px !important"
              outlined
              small
              color="var(--text__red-dark)"
              class="ml-sm-16"
              @click="visible = 1"
            >
              {{ $t("buttons.cancel") }}
            </v-btn>
          </template>

          <v-checkbox
            readonly
            @change="changeCheckBox($event)"
            v-model="booking.roomFixed"
            :label="$t('contractDetail.fixRoomAllocation')"
            hide-details
            class="mt-5 mb-3 cus-checkbox block-true"
          ></v-checkbox>
          <span class="text--small title-head-child">{{
            $t("contractDetail.remarkNote")
          }}</span>

          <div class="custom-text-field">
            <v-text-field @change="changeBookingBarNote" max-width="300px" v-model="booking.bookingBarNote" :disabled="!checkPerUser">
            </v-text-field>
          </div>

          <p class="title-head">
            {{ $t("contractDetail.basicInformation") }}
          </p>

          <div class="d-flex flex-wrap align-end mt-5">
            <v-icon color="#000000" class="align-end mr-3"
              >mdi-calendar-month</v-icon
            >
            <span class="text--small">
              <p class="mb-2 title-head-child">{{ $t("common.checkIn") }}</p>
              <!-- 2020年11月3日 (金) -->
              <span class="title-head-child">{{
                formatDate(booking.checkInDate)
              }}</span>
              <span class="mx-3">~</span>
            </span>
            <span class="text--small mr-5">
              <p class="mb-2 title-head-child">{{ $t("common.checkOut") }}</p>
              <!-- 2020年11月10日 (金) -->
              <span class="title-head-child">{{
                formatDate(booking.checkOutDate)
              }}</span>
            </span>
            <!-- 7泊8日 -->
            <span class="custom-text-stay">{{ getStayDate() }} </span>
            <v-btn style="background: #ffffff; padding: 0 !important"
              @click="showSelectFrame()"
              :disabled="!checkPerUser"
            >
              <v-chip
              outlined
              label
              color="var(--text__red-dark)"
              class="mr-2 ml-sm-5 custom-btn-csv"
              >チェックイン・アウト日変更</v-chip
            >
            </v-btn>
          </div>

          <p class="mb-2 mt-5 title-head-child">{{ $t("common.facility") }}</p>
          <span class="mr-5" style="color: #444444" v-if="booking.room">{{
            booking.room.roomType.facility.name
          }}</span>
          <v-btn
            color="var(--text__primary)"
            outlined
            small
            @click="showFloorPlanPdfUrl()"
            target="_blank"
            class="custom-btn-csv"
          >
            {{ $t("buttons.openTheFacilityFloorPlan") }}
          </v-btn>

          <p class="mb-2 mt-5 title-head-child">{{ $t("common.roomType") }}</p>
          <span class="mr-5" style="color: #444444" v-if="booking.room">{{
            booking.room.roomType.name
          }}</span>

          <p class="mt-5 title-head-child">{{ $t("common.room") }}</p>
          <template v-if="booking.room">
            <span class="custom-select-room-sec text-select">{{booking.room.name}}</span>
          </template>
        </v-col>
      </template>
      <template v-if="listBookingTypeIdFirst() && !booking.blockRoom">
        <v-col cols="12" lg="7" class="t-box--left">
          <p class="title-head">{{ $t("common.status") }}</p>
          <p class="title-head-child mb-2 mt-5">{{ $t("common.status") }}</p>

          <span
            style="color: #444444 !important"
            class="mr-5 mr-sm-16"
            v-if="booking.computed"
            >{{ booking.computed.status }}</span
          >
          <!-- {{ $t('common.confirmed') }} -->
          <template v-if="booking && booking.computed && booking.computed.status && booking.computed.status   !== 'キャンセル' && booking.bookingTypeId !== '7' && booking.bookingTypeId !== '9'">
            <v-btn
              style="font-size: 10px !important"
              outlined
              small
              color="var(--text__red-dark)"
              class="ml-sm-16"
              @click="visible = 1"
              :disabled="!checkPerUser"
            >
              {{ $t("buttons.cancel") }}
            </v-btn>
          </template>

          <v-checkbox
            :disabled="!checkPerUser"
            @change="changeCheckBox($event)"
            v-model="booking.roomFixed"
            :label="$t('contractDetail.fixRoomAllocation')"
            hide-details
            class="mt-5 mb-3 cus-checkbox"
          ></v-checkbox>
          <span class="text--small title-head-child">{{
            $t("contractDetail.remarkNote")
          }}</span>

          <div class="custom-text-field">
            <v-text-field class="text-field" @change="changeBookingBarNote" max-width="00px" v-model="booking.bookingBarNote" :disabled="!checkPerUser">
            </v-text-field>
          </div>

          <p class="title-head">
            {{ $t("contractDetail.basicInformation") }}
          </p>
          <p class="title-head-child mb-2 mt-5">
            {{ $t("contractDetail.usageContract") }}
          </p>

          <template>
            <span class="color-nufour mr-10" v-if="booking.contract">{{
              booking.contract.productType.name
            }}</span>
          </template>
          <!-- contract name -->
          <v-btn
            class="custom-btn-csv"
            color="var(--text__primary)"
            outlined
            small
            @click="showPdf()"
            v-if="booking.bookingCertificateUrl"
            :disabled="!checkPerUser"
          >
            {{ $t("buttons.openCertificates") }}(PDF)
          </v-btn>
          <v-btn
            class="ml-3 custom-btn-csv"
            color="var(--text__primary)"
            outlined
            small
            @click="getLinkCRMContract(booking)"
            :disabled="booking.contract === null || !checkPerUser"
          >
            <!-- @click="visible = 2" -->
            {{ "契約を開く(CRM)" }}
          </v-btn>

          <p class="title-head-child mb-2 mt-5">
            {{ $t("contractDetail.stayingPlan") }}
          </p>
          <span class="color-nufour"> {{ (booking.bookingPlan && booking.bookingPlan.title) || $t("common.none") }} </span>
          <!-- Don't have staying plan -->

          <div class="d-flex flex-wrap align-end mt-5">
            <v-icon color="#000000" class="align-end mr-3"
              >mdi-calendar-month</v-icon
            >
            <span class="text--small">
              <p class="mb-2 title-head-child">{{ $t("common.checkIn") }}</p>
              <!-- 2020年11月3日 (金) -->
              <span class="title-date">{{
                formatDate(booking.checkInDate)
              }}</span>
              <span class="mx-3">~</span>
            </span>
            <span class="text--small mr-5">
              <p class="mb-2 title-head-child">{{ $t("common.checkOut") }}</p>
              <!-- 2020年11月10日 (金) -->
              <span class="title-date">{{
                formatDate(booking.checkOutDate)
              }}</span>
            </span>
            <!-- 7泊8日 -->
            <span class="custom-text-stay">{{ getStayDate() }} </span>
            <v-btn style="background: #ffffff; padding: 0 !important"
              @click="showSelectFrame()"
              :disabled="!checkPerUser"
            >
              <v-chip
              outlined
              label
              color="var(--text__red-dark)"
              class="mr-2 ml-sm-5 custom-btn-csv"
              >チェックイン・アウト日変更</v-chip
            >
            </v-btn>
          </div>

          <p class="mb-2 mt-5 title-head-child">{{ $t("common.facility") }}</p>
          <span class="mr-5" style="color: #444444" v-if="booking.room">{{
            booking.room.roomType.facility.name
          }}</span>
          <v-btn
            color="var(--text__primary)"
            outlined
            small
            target="_blank"
            class="custom-btn-csv"
            @click="showFloorPlanPdfUrl()"
            :disabled="!checkPerUser || !(booking.room && booking.room.roomType && booking.room.roomType.facility && booking.room.roomType.facility.floorPlanPdfUrl)"
          >
            {{ $t("buttons.openTheFacilityFloorPlan") }}
          </v-btn>

          <p class="mb-2 mt-5 title-head-child">{{ $t("common.roomType") }}</p>
          <span class="mr-5" style="color: #444444" v-if="booking.room">{{
            booking.room.roomType.name
          }}</span>

          <p class="mt-5 title-head-child">{{ $t("common.room") }}</p>
          <template v-if="booking.room">
            <span class="cus-item-select color-nufour">{{booking.room.name}}</span>
          </template>
          <p class="title-head mt-7">
            {{ $t("reservation.memberInformation") }}
          </p>
          <span class="text--inactive" v-if="booking.contract">
            <p
              class="mt-5 mb-2 title-head-child"
              style="color: #bdbdbd !important"
            >
              会員番号
            </p>
            <span style="color: #bdbdbd !important">{{
              booking.contract.client.id
            }}</span>
          </span>
          <v-btn
            color="var(--text__primary)"
            outlined
            small
            target="_blank"
            :class="booking.contract ? 'ml-10' : ''"
            @click="getLinkCRMCustomerDetails(booking)"
            :disabled="booking.contract === null || !checkPerUser"
          >
            {{ $t("buttons.openMemberInformation") }}(CRM)
          </v-btn>

          <p class="mt-7 title-head-child">{{ "会員名" }}</p>
          <span class="color-nufour" v-if="booking.contract">
            {{ clientName }}</span
          >

          <p class="mt-5 title-head-child">
            {{ $t("contractDetail.individualCorporationClassification") }}
          </p>
          <span class="color-nufour" v-if="booking.contract">{{
            booking.contract.client.isCompany ? "法人" : "個人"
          }}</span>

          <p class="title-head mt-7">
            {{ $t("reservation.reservationSource") }}
          </p>
          <p
            class="mt-5 text--inactive"
            style="font-size: 10px !important; color: #bdbdbd !important"
          >
            {{ $t("contractDetail.reservationSourceSite") }}
          </p>
          <v-text-field
            style="color: #bdbdbd !important"
            hide-details
            readonly
            v-model="booking.salesChannel"
          ></v-text-field>

          <p
            class="mt-5 text--inactive title-head-child"
            style="color: #bdbdbd !important"
          >
            {{ $t("reservation.reservationSource") }}ID
          </p>
          <template v-if="booking.bookingGroup">
            <v-text-field
              hide-details
              readonly
              v-model="booking.bookingGroup.otaBookingId"
            ></v-text-field>
          </template>
        </v-col>
      </template>
      <template v-else-if="listBookingTypeIdSecond() && !booking.blockRoom">
        <v-col cols="12" lg="7" class="t-box--left">
          <p class="title-head">{{ $t("common.status") }}</p>
          <p class="mb-2 mt-5 title-head-child">{{ $t("common.status") }}</p>

          <span
            style="color: #444444 !important"
            class="mr-5 mr-sm-16"
            v-if="booking.computed"
            >{{ booking.computed.status }}</span
          >

          <v-checkbox
            @change="changeCheckBox($event)"
            v-model="booking.roomFixed"
            :label="$t('contractDetail.fixRoomAllocation')"
            hide-details
            class="mt-5 mb-3"
            :disabled="!checkPerUser"
          ></v-checkbox>
          <span class="text--small title-head-child">{{
            $t("contractDetail.remarkNote")
          }}</span>
          <div class="custom-text-field">
            <v-text-field max-width="300px" @change="changeBookingBarNote" v-model="booking.bookingBarNote" :disabled="!checkPerUser">
            </v-text-field>
          </div>

          <p class="title-head">
            {{ $t("contractDetail.basicInformation") }}
          </p>

          <div class="d-flex flex-wrap align-end mt-5">
            <v-icon color="#000000" class="align-end mr-3"
              >mdi-calendar-month</v-icon
            >
            <span class="text--small">
              <p class="mb-2 title-head-child">{{ $t("common.checkIn") }}</p>
              <!-- 2020年11月3日 (金) -->
              <span class="title-head-child">{{
                formatDate(booking.checkInDate)
              }}</span>
              <span class="mx-3">~</span>
            </span>
            <span class="text--small mr-5">
              <p class="mb-2 title-head-child">{{ $t("common.checkOut") }}</p>
              <!-- 2020年11月10日 (金) -->
              <span class="title-head-child">{{
                formatDate(booking.checkOutDate)
              }}</span>
            </span>
            <!-- 7泊8日 -->
            <span class="custom-text-stay">{{ getStayDate() }} </span>
          </div>

          <p class="mb-2 mt-5 title-head-child">{{ $t("common.facility") }}</p>
          <span class="mr-5" style="color: #444444" v-if="booking.room">{{
            booking.room.roomType.facility.name
          }}</span>
          <v-btn
            color="var(--text__primary)"
            outlined
            small
            @click="showFloorPlanPdfUrl()"
            target="_blank"
            class="custom-btn-csv"
            :disabled="!checkPerUser"
          >
            <v-icon class="mr-2" style="font-size: 15px !important"
              >mdi-plus</v-icon
            >
            {{ $t("buttons.openTheFacilityFloorPlan") }}
          </v-btn>
          <p class="mb-2 mt-5">{{ $t("common.roomType") }}</p>
          <span class="text--gray-darken mr-5" v-if="booking.room">{{
            booking.room.roomType.name
          }}</span>

          <p class="mt-5 title-head-child">{{ $t("common.room") }}</p>
          <template v-if="booking.room">
            <span class="custom-select-room-sec text-select">{{booking.room.name}}</span>
          </template>

          <p class="title-head mt-7">
            {{ $t("reservation.reservationSource") }}
          </p>
          <p class="mt-5 title-head-child">
            {{ $t("contractDetail.reservationSourceSite") }}
          </p>
          <span style="color: #000000 !important">{{
            booking.bookingGroup.otaName || ''
          }}</span>
          <p class="mt-5 title-head-child">
            {{ $t("reservation.reservationSource") }}ID
          </p>
          <template v-if="booking.bookingGroup">
            <span style="color: #000000 !important">{{
              booking.bookingGroup.otaBookingId
            }}</span>
          </template>
        </v-col>
      </template>
      <template v-else-if="listBookingTypeIdThird() && !booking.blockRoom">
        <v-col cols="12" lg="7" class="t-box--left">
          <p class="title-head">{{ $t("common.status") }}</p>
          <p class="mb-2 mt-5 title-head-child">{{ $t("common.status") }}</p>

          <span
            style="color: #444444 !important"
            class="mr-5 mr-sm-16"
            v-if="booking.computed"
            >{{ booking.computed.status }}</span
          >
          <!-- {{ $t('common.confirmed') }} -->
          <template v-if="booking && booking.computed && booking.computed.status && booking.computed.status   !== 'キャンセル' && booking.bookingTypeId !== '7' && booking.bookingTypeId !== '9'">
            <v-btn
              style="font-size:10px"
              outlined
              small
              color="var(--text__red-dark)"
              class="ml-sm-16"
              @click="visible = 1"
              :disabled="!checkPerUser"
            >
              {{ $t("buttons.cancel") }}
            </v-btn>
          </template>

            <p class="text--small title-head-child mt-10 mb-0">{{
            $t("contractDetail.remarkNote")
          }}</p>

          <div class="custom-text-field">
            <v-text-field max-width="300px" @change="changeBookingBarNote" v-model="booking.bookingBarNote" :disabled="!checkPerUser">
            </v-text-field>
          </div>

          <p class="title-head">
            {{ $t("contractDetail.basicInformation") }}
          </p>
          <p class="mb-2 mt-5 title-head-child" style="color: #333333 !important">
            {{ $t("contractDetail.usageContract") }}
          </p>

          <template>
            <span style="color: #333333 !important" class="mr-10" v-if="booking.contract">{{
              booking.contract.productType.name
            }}</span>
          </template>

          <v-btn
            class="custom-btn-csv"
            color="var(--text__primary)"
            outlined
            small
            @click="showPdf()"
            v-if="booking.bookingCertificateUrl"
            :disabled="!checkPerUser"
          >
            {{ $t("buttons.openCertificates") }}(PDF)
          </v-btn>

          <!-- contract name -->
          <v-btn
            class="ml-3 custom-btn-csv"
            color="var(--text__primary)"
            outlined
            small
            @click="getLinkCRMContract(booking)"
            :disabled="booking.contract === null || !checkPerUser"
          >
            <!-- @click="visible = 2" -->
            {{ "契約を開く(CRM)" }}
          </v-btn>

          <div class="d-flex flex-wrap align-end mt-5">
            <v-icon color="#000000" class="align-end mr-3"
              >mdi-calendar-month</v-icon
            >
            <span class="text--small">
              <p class="mb-2 title-head-child">{{ $t("common.checkIn") }}</p>
              <!-- 2020年11月3日 (金) -->
              <span class="title-head-child">{{
                formatDate(booking.checkInDate)
              }}</span>
              <span class="mx-3">~</span>
            </span>
            <span class="text--small mr-5">
              <p class="mb-2 title-head-child">{{ $t("common.checkOut") }}</p>
              <!-- 2020年11月10日 (金) -->
              <span class="title-head-child">{{
                formatDate(booking.checkOutDate)
              }}</span>
            </span>
            <!-- 7泊8日 -->
            <span class="custom-text-stay">{{ getStayDate() }} </span>
          </div>

          <p class="mb-2 mt-5 title-head-child">{{ $t("common.facility") }}</p>
          <span class="mr-5" style="color: #444444" v-if="booking.room">{{
            booking.room.roomType.facility.name
          }}</span>
          <v-btn
            color="var(--text__primary)"
            outlined
            small
            target="_blank"
            class="custom-btn-csv"
            @click="showFloorPlanPdfUrl()"
            :disabled="!checkPerUser || !(booking.room && booking.room.roomType && booking.room.roomType.facility && booking.room.roomType.facility.floorPlanPdfUrl)"
          >
           <v-icon class="mr-2" style="font-size: 15px !important"
              >mdi-plus</v-icon
            >
            {{ $t("buttons.openTheFacilityFloorPlan") }}
          </v-btn>

          <p class="mb-2 mt-5">{{ $t("common.roomType") }}</p>
          <span class="text--gray-darken mr-5" v-if="booking.room">{{
            booking.room.roomType.name
          }}</span>

          <p class="title-head mt-7">
            {{ $t("reservation.memberInformation") }}
          </p>
          <span class="text--inactive" v-if="booking.contract">
            <p
              class="mt-5 mb-2 title-head-child"
              style="color: #bdbdbd !important"
            >
              <!-- {{ $t("common.member") }}ID -->
              会員番号
            </p>
            <span style="color: #bdbdbd !important">{{
              booking.contract.client.id
            }}</span>
          </span>
          <v-btn
            color="var(--text__primary)"
            outlined
            small
            target="_blank"
            :class="booking.contract ? 'ml-10' : ''"
            @click="getLinkCRMCustomerDetails(booking)"
            :disabled="booking.contract === null || !checkPerUser"
          >
            {{ $t("buttons.openMemberInformation") }}(CRM)
          </v-btn>

          <p class="mt-5 title-head-child">{{ $t("common.memberName") }}</p>
          <span class="custom-text" v-if="booking.contract">
            {{ booking.contract.client.name }}</span
          >

          <p class="mt-5 title-head-child">
            {{ $t("contractDetail.individualCorporationClassification") }}
          </p>
          <span class="custom-text" v-if="booking.contract">{{
            booking.contract.client.isCompany ? "法人" : "個人"
          }}</span>

          <p class="title-head mt-7">
            {{ $t("reservation.reservationSource") }}
          </p>
          <p class="mt-5 text--inactive" style="font-size: 10px">
            {{ $t("contractDetail.reservationSourceSite") }}
          </p>
          <v-text-field
            style="color: #bdbdbd !important; font-size: 14px !important"
            hide-details
            readonly
            v-model="booking.salesChannel"
            :disabled="!checkPerUser"
          ></v-text-field>

          <p
            class="mt-5 text--inactive title-head-child"
            style="color: #bdbdbd !important"
          >
            {{ $t("reservation.reservationSource") }} ID
          </p>
          <template v-if="booking.bookingGroup">
            <v-text-field
              hide-details
              readonly
              v-model="booking.bookingGroup.otaBookingId"
              :disabled="!checkPerUser"
            ></v-text-field>
          </template>
        </v-col>
      </template>
      <template v-if="listBookingTypeIdFour() && !booking.blockRoom">
        <v-col cols="12" lg="7" class="t-box--left">
          <p class="title-head">{{ $t("common.status") }}</p>
          <p class="mb-2 mt-5 title-head-child">{{ $t("common.status") }}</p>

          <span
            class="text--gray-darken mr-5 mr-sm-16"
            v-if="booking.computed"
            >{{ booking.computed.status }}</span
          >
          <v-checkbox
            @change="changeCheckBox($event)"
            v-model="booking.roomFixed"
            :label="$t('contractDetail.fixRoomAllocation')"
            hide-details
            class="mt-5 mb-3"
            :disabled="!checkPerUser"
          ></v-checkbox>
          <span class="text--small">{{ $t("contractDetail.remarkNote") }}</span>

          <div class="custom-text-field">
            <v-text-field max-width="300px" @change="changeBookingBarNote" v-model="booking.bookingBarNote" :disabled="!checkPerUser">
            </v-text-field>
          </div>

          <p class="title-head">
            {{ $t("contractDetail.basicInformation") }}
          </p>
          <p class="mb-2 mt-5 title-head-child">
            {{ $t("contractDetail.usageContract") }}
          </p>

          <template>
            <span class="text--gray-darken mr-10" v-if="booking.contract">{{
              booking.contract.productType.name
            }}</span>
          </template>
          <!-- contract name -->
          <v-btn
            color="var(--text__primary)"
            outlined
            small
            @click="showPdf()"
            :disabled="booking.bookingCertificateUrl === null"
          >
            {{ $t("buttons.openCertificates") }}(PDF)
          </v-btn>
          <v-btn
            class="ml-3 custom-btn-csv"
            color="var(--text__primary)"
            outlined
            small
            @click="getLinkCRMContract(booking)"
            :disabled="booking.contract === null || !checkPerUser"
          >
            <!-- @click="visible = 2" -->
            {{ "契約を開く(CRM)" }}
          </v-btn>

          <div class="d-flex flex-wrap align-end mt-5">
            <v-icon class="align-end mr-3">mdi-calendar-month</v-icon>
            <span class="text--small">
              <p class="mb-2 title-head-child">{{ $t("common.checkIn") }}</p>
              <!-- 2020年11月3日 (金) -->
              {{ formatDate(booking.checkInDate) }}
              <span class="mx-3">~</span>
            </span>
            <span class="text--small mr-5">
              <p class="mb-2 title-head-child">{{ $t("common.checkOut") }}</p>
              <!-- 2020年11月10日 (金) -->
              {{ formatDate(booking.checkOutDate) }}
            </span>
            <!-- 7泊8日 -->
            {{ getStayDate() }}
          </div>

          <p class="mb-2 mt-5 title-head-child">{{ $t("common.facility") }}</p>
          <span class="text--gray-darken mr-5" v-if="booking.room">{{
            booking.room.roomType.facility.name
          }}</span>
          <v-btn
            color="var(--text__primary)"
            outlined
            small
            @click="showFloorPlanPdfUrl()"
            target="_blank"
          >
            {{ $t("buttons.openTheFacilityFloorPlan") }}
          </v-btn>

          <p class="mb-2 mt-5">{{ $t("common.roomType") }}</p>
          <span class="text--gray-darken mr-5" v-if="booking.room">{{
            booking.room.roomType.name
          }}</span>

          <p class="mt-5 title-head-child">{{ $t("common.room") }}</p>
          <template v-if="booking.room">
            <span>{{booking.room.name}}</span>
          </template>

          <p class="title-head mt-7">
            {{ $t("reservation.memberInformation") }}
          </p>
          <span class="text--inactive" v-if="booking.contract">
            <p class="mt-5 mb-2 title-head-child">
              <!-- {{ $t("common.member") }}ID -->
              会員番号
            </p>
            {{ booking.contract.client.id }}
          </span>
          <v-btn
            color="var(--text__primary)"
            outlined
            small
            target="_blank"
            :class="booking.contract ? 'ml-10' : ''"
            @click="getLinkCRMCustomerDetails(booking)"
            :disabled="booking.contract === null || !checkPerUser"
          >
            {{ $t("buttons.openMemberInformation") }}(CRM)
          </v-btn>

          <p class="mt-5">{{ $t("common.memberName") }}</p>
          <span class="text--gray-darken" v-if="booking.contract">
            {{ booking.contract.client.name }}</span
          >

          <p class="mt-5">
            {{ $t("contractDetail.individualCorporationClassification") }}
          </p>
          <span class="text--gray-darken" v-if="booking.contract">{{
            booking.contract.client.isCompany ? "法人" : "個人"
          }}</span>

          <p class="title-head mt-7">
            {{ $t("reservation.reservationSource") }}
          </p>
          <p class="mt-5 text--inactive title-head-child">
            {{ $t("contractDetail.reservationSourceSite") }}
          </p>
          <v-text-field
            hide-details
            readonly
            v-model="booking.salesChannel"
            :disabled="!checkPerUser"
          ></v-text-field>

          <p class="mt-5 text--inactive title-head-child">
            {{ $t("reservation.reservationSource") }}
          </p>
          <template v-if="booking.bookingGroup">
            <v-text-field
              hide-details
              readonly
              v-model="booking.bookingGroup.otaBookingId"
              :disabled="!checkPerUser"
            ></v-text-field>
          </template>
        </v-col>
      </template>
      <!-- End Left Col -->

      <!-- Start Right Col -->
      <template v-if="!booking.blockRoom">
        <v-col cols="12" lg="5" class="t-box--right pa-5">
          <basic-information-group-bookings :booking="booking" :checkPerUser="checkPerUser" @addGroup="addGroup" />

          <p class="title-head mt-5 mb-0">
            {{ $t("contractDetail.accommodationRemarks") }}
          </p>

          <template v-if="listBookingTypeIdFirst() && !booking.blockRoom">
            <v-row>
              <v-col cols="12" sm="6">
                <v-checkbox
                  hide-details
                  @change="changeToothBrush($event)"
                  v-model="booking.toothbrush"
                  :label="$t('contractDetail.toothbrushReady')"
                  class="mb-9 cus-checkbox"
                  :disabled="!checkPerUser"
                ></v-checkbox>
                <v-checkbox
                  class="cus-checkbox"
                  @change="changeMailBookingCertificate($event)"
                  hide-details
                  v-model="booking.mailBookingCertificate"
                  :label="
                    $t('contractDetail.requestForMailingOfUsageCertificate')
                  "
                  :disabled="!checkPerUser"
                ></v-checkbox>
                <v-checkbox
                  class="cus-checkbox"
                  @change="changeMailPointCertificate($event)"
                  hide-details
                  v-model="booking.mailPointCertificate"
                  :label="
                    $t(
                      'contractDetail.pointBalanceCertificateDocumentMailingRequest'
                    )
                  "
                  :disabled="!checkPerUser"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  @change="changeYukata($event)"
                  hide-details
                  v-model="booking.yukata"
                  :label="$t('contractDetail.yukataPreparationHope')"
                  class="mb-9 cus-checkbox"
                  :disabled="!checkPerUser"
                ></v-checkbox>
                <v-checkbox
                  class="cus-checkbox"
                  @change="changeMailFacilityGuide($event)"
                  hide-details
                  v-model="booking.mailFacilityGuide"
                  :label="
                    $t(
                      'contractDetail.requestToMailFacilityInformationMaterials'
                    )
                  "
                  :disabled="!checkPerUser"
                ></v-checkbox>
                <v-checkbox
                  class="cus-checkbox"
                  @change="changeMailAds($event)"
                  hide-details
                  v-model="booking.mailAds"
                  :label="$t('contractDetail.hopeToMailLeaflets')"
                  :disabled="!checkPerUser"
                ></v-checkbox>
              </v-col>
            </v-row>
          </template>
          <template v-if="listBookingTypeIdFour()">
            <v-row>
              <v-col cols="12" sm="6">
                <v-checkbox
                  hide-details
                  @change="changeToothBrush($event)"
                  v-model="booking.toothbrush"
                  :label="$t('contractDetail.toothbrushReady')"
                  class="mb-9"
                  :disabled="!checkPerUser"
                ></v-checkbox>
                <v-checkbox
                  @change="changeMailBookingCertificate($event)"
                  hide-details
                  v-model="booking.mailBookingCertificate"
                  :label="
                    $t('contractDetail.requestForMailingOfUsageCertificate')
                  "
                  :disabled="!checkPerUser"
                ></v-checkbox>
                <v-checkbox
                  @change="changeMailPointCertificate($event)"
                  hide-details
                  v-model="booking.mailPointCertificate"
                  :label="
                    $t(
                      'contractDetail.pointBalanceCertificateDocumentMailingRequest'
                    )
                  "
                  :disabled="!checkPerUser"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  @change="changeYukata($event)"
                  hide-details
                  v-model="booking.yukata"
                  :label="$t('contractDetail.yukataPreparationHope')"
                  class="mb-9"
                  :disabled="!checkPerUser"
                ></v-checkbox>
                <v-checkbox
                  @change="changeMailFacilityGuide($event)"
                  hide-details
                  v-model="booking.mailFacilityGuide"
                  :label="
                    $t(
                      'contractDetail.requestToMailFacilityInformationMaterials'
                    )
                  "
                  :disabled="!checkPerUser"
                ></v-checkbox>
                <v-checkbox
                  @change="changeMailAds($event)"
                  hide-details
                  v-model="booking.mailAds"
                  :label="$t('contractDetail.hopeToMailLeaflets')"
                  :disabled="!checkPerUser"
                ></v-checkbox>
              </v-col>
            </v-row>
          </template>

          <template v-if="listBookingTypeIdThird()">
            <v-row>
              <v-col cols="12" sm="6">
                <v-checkbox
                  @change="changeMailBookingCertificate($event)"
                  hide-details
                  v-model="booking.mailBookingCertificate"
                  :label="
                    $t('contractDetail.requestForMailingOfUsageCertificate')
                  "
                  :disabled="!checkPerUser"
                ></v-checkbox>
                <v-checkbox
                  @change="changeMailPointCertificate($event)"
                  hide-details
                  v-model="booking.mailPointCertificate"
                  :label="
                    $t(
                      'contractDetail.pointBalanceCertificateDocumentMailingRequest'
                    )
                  "
                  :disabled="!checkPerUser"
                ></v-checkbox>
              </v-col>
            </v-row>
          </template>

          <template>
            <template v-if="acceptsPet && !showOldPetInfo">
              <div class="mb-2 mt-5 title-head-child">
                <div class="d-flex align-center">
                  <div>{{ $t("common.petInfo") }}</div>
                  <div class="ml-2 text--large">
                    <span v-if="booking.hasAgreedPetPolicy" class="color-main">
                      愛犬家族利用規則同意済み
                    </span>
                    <span v-else class="color-gray">
                      愛犬家族利用規則未同意
                    </span>
                  </div>
                </div>
                <span class="color-red text--large">コールセンター以外入力禁止：以下の入力内容はそのまま会員に公開されます。</span>
              </div>
              <v-textarea
                class="custom-table-text bg--light-pink"
                hide-details
                @change="changePetInfo($event)"
                v-model="booking.petInfo"
                outlined
                :disabled="!checkPerUser"
                :rows="6"
              ></v-textarea>
            </template>

            <p class="mb-1 mt-5 title-head-child">{{ $t("common.remark") }}</p>
            <p class="title-head">フロントへの伝達事項、その他</p>
            <v-textarea
              class="custom-table-text"
              hide-details
              @change="changeRemarks($event)"
              v-model="booking.remarks"
              outlined
              :disabled="!checkPerUser"
            ></v-textarea>

            <p class="title-head mt-6">予約作成会員</p>
            <div>
              {{ createdByName }}
            </div>
            <!-- --- -->
            <p class="title-head mt-6">
              {{ $t("contractDetail.accommodationRepresentative") }}
            </p>
            <template>
              <v-form ref="form">
                <template v-if="booking.representative && booking.bookingTypeId !== 11">
                <v-combobox
                v-if="booking.representative.clientId"
                class="text-select"
                @change="changeSubMember($event)"
                :items="listSubMember"
                item-text="name"
                item-value="clientId"
                :value="booking.representative.name"
                placeholder="代表者名"
                :no-data-text="$t('rules.noData')"
                :disabled="!checkPerUser"
              ></v-combobox>
              <v-combobox
                v-else-if="booking.representative.subMemberId"
                class="text-select"
                @change="changeSubMember($event)"
                :items="listSubMember"
                item-text="name"
                item-value="id"
                :value="booking.representative.name"
                placeholder="代表者名"
                :no-data-text="$t('rules.noData')"
                :disabled="!checkPerUser"
              ></v-combobox>
              <v-combobox
                  v-else
                  :value="booking.representative.name"
                  @change="changeSubMember($event)"
                   item-text="name"
                    item-value="id"
                  :items="listSubMember"
                  placeholder="代表者名"
                  :disabled="!checkPerUser"
                ></v-combobox>
              </template>
              <template v-else>
                <v-combobox
                    class="text-select"
                    :value="objUpdate.representative.name"
                    @change="changeSubMember($event)"
                    :items="listSubMember"
                    item-text="name"
                    item-value="id"
                    placeholder="代表者名"
                    :disabled="!checkPerUser"
                  ></v-combobox>
              </template>

              </v-form>
              <v-text-field
                v-model="objUpdate.representative.kana"
                placeholder="読みがな"
                :rules="[$rules.checkKatakana, $rules.checkLenghInput(255)]"
                class="text-field"
                :disabled="!checkPerUser"
              ></v-text-field>

              <!-- <v-text-field
                v-model="objUpdate.representative.tel"
                placeholder="電話"
                :rules="[$rules.checkNumber, $rules.checkLenghInput(15)]"
                class="text-field"
                :disabled="!checkPerUser"
              ></v-text-field> -->
              <v-text-field
                v-model="objUpdate.representative.tel"
                placeholder="電話"
                class="text-field"
                :disabled="!checkPerUser"
              ></v-text-field>

              <v-text-field
                v-model="objUpdate.representative.email"
                placeholder="メール"
                :rules="[$rules.isEmailValid]"
                class="text-field"
                :disabled="!checkPerUser"
              ></v-text-field>

              <v-text-field
                v-model="objUpdate.representative.address1"
                placeholder="住所"
                :rules="[$rules.checkLenghInput(255)]"
                class="text-field"
                :disabled="!checkPerUser"
              ></v-text-field>

              <one-line-inputs>
                <v-text-field
                  v-model="objUpdate.representative.occupation"
                  placeholder="職業"
                  :rules="[$rules.checkLenghInput(255)]"
                  class="text-field"
                  :disabled="!checkPerUser"
                ></v-text-field>
                <tvos-int-input
                  v-model="objUpdate.representative.age"
                  placeholder="年齢"
                  class="text-field"
                  :disabled="!checkPerUser"
                />
                <guest-gender-select
                  v-model="objUpdate.representative.sex"
                  class="text-field"
                  :disabled="!checkPerUser"
                  dense
                />
              </one-line-inputs>

              <div class="w-50">
                <p class="mt-5 title-head-child">
                  {{ $t("common.nationality") }}
                </p>
                <v-select
                  class="pa-0 select-nationality text-select-custom"
                  v-model="objUpdate.representative.nationality"
                  :items="['日本', 'その他']"
                  :disabled="!checkPerUser"
                >
                </v-select>
              </div>

              <p class="mt-5 title-head-child">
                {{ $t("contractDetail.passportOrResidenceImage") }}
              </p>
              <v-file-input
                v-if="booking.representative"
                @change="
                  getChangePhoto($event)
                "
                accept="image/png, image/jpeg, image/bmp"
                prepend-icon="mdi-camera"
                :disabled="!checkPerUser"
              ></v-file-input>
              <v-file-input
                v-else
                @change="
                  getChangePhoto($event)
                "
                accept="image/png, image/jpeg, image/bmp"
                prepend-icon="mdi-camera"
                :disabled="!checkPerUser"
                placeholder="ファイルをアップロード"
              ></v-file-input>
              <v-card
                class="pl-3 bg-contain"
                height="150px"
                width="250px"
                outlined
                :img="srcImgPre || objUpdate.representative.passportPhotoUrl"
                v-if="srcImgPre || objUpdate.representative.passportPhotoUrl"
              >
              </v-card>
              <div class="w-50">
                <p class="mt-5 title-head-child">
                  {{ "パスポート・在留資格番号(画像ない場合)" }}
                </p>
                <v-text-field
                  v-model="objUpdate.representative.passportNumber"
                  class="pa-0 text-field"
                  :rules="[$rules.checkLenghInput(255)]"
                  :disabled="!checkPerUser"
                >
                </v-text-field>
              </div>
            </template>
            <!-- --- -->
          </template>
        </v-col>
      </template>
      <!-- End Right Col -->
    </v-row>

    <!-- ===================== Dialogs ===================== -->
    <SettingCancelDialog
      :title="$t('buttons.cancel')"
      :visible="visible === 1"
      v-if="visible === 1"
      @close="visible = -1"
    />

    <SettingMoveDialog
      v-if="showMoveDialog"
      :bookingId="booking.id"
      :oldCheckInDate="booking.checkInDate"
      :oldCheckOutDate="booking.checkOutDate"
      :bookingTypeId="booking.bookingTypeId"
      @close="showMoveDialog = false"
    />

    <!-- 6 dialogs with different props ( in certificateDialog.vue file ) -->
    <CertificateDialog
      :visible="visible === 2"
      @close="visible = -1"
      :typeOfCertificate="$t('contractDetail.dayPlanCertificate')"
      date="2020/12/24"
      fileNo="12345"
      membershipNumber="0000001"
      fullName="東急  太郎"
      rowMembershipNumber="0000001"
      rowMembershipName="東急  三郎"
      rowFacility="東急バケーションズ 軽井沢"
      rowFacilityNumber="0267-41-1093"
      rowPeriodStay="2021年4月1日 〜 2021年4月3日"
      rowPeriodStayTotal="2泊"
      rowFees="55,000円(税込)"
      rowRoomType="禁煙ルーム"
      payableDate="2021年2月1日"
    >
      <template v-slot:note>
        以下の通り予約を承りました。<br />
        ご利用の際フロントにご提示ください。
      </template>
    </CertificateDialog>
    <!-- ===================== End Dialogs ===================== -->
  </div>
</template>

<script>
import SettingCancelDialog from './settingCancelDialog'
import SettingMoveDialog from './settingMoveDialog'
import CertificateDialog from './certificateDialog'

import {
  BOOKING,
  BOOKING_TYPES_LIST
} from '@/api/graphql/bookingDetail/booking-detail-basic'
import { CLIENT_MEMBER_INFO } from '@/api/graphql/bookingDetail/booking-guest'
import { UPLOAD_PASSPORT_URL } from '@/api/graphql/bookingDetail/setting-cancel'
import axios from 'axios'
import { formatDate } from '@/constants/functions'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'

import { isEmailValid } from '@/utils/validators.js'
import gql from 'graphql-tag'
import BasicInformationGroupBookings from './BasicInformationGroupBookings.vue'
import GuestGenderSelect from '../../../components/Input/GuestGenderSelect.vue'
import OneLineInputs from '@/components/Form/OneLineInputs'

export default {
  name: 'BasicInformation',
  data () {
    return {
      show: false,
      checkName: true,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      roomType: {},
      facilityId: null,
      showMoveDialog: false,
      fileSelect: null,
      passportPhoto: null,
      uploadUrl: '',
      srcImgPre: null,
      bookingTypesList: [],
      roomTypeId: null,
      listRoomItem: [],
      booking: {},
      roomName: '201号室*',
      visible: -1,
      objClient: null,
      objUpdate: {
        representative: {
          clientId: null,
          subMemberId: null,
          name: null,
          kana: null, // #読みがな
          tel: null, // #電話
          email: null, // #メール
          address1: null, // #住所
          sex: null, // 性別
          occupation: null,
          nationality: null, // #国籍
          passportPhoto: null, // #パスポート写真
          passportNumber: null // #パスポート番号
        }
      },
      listSubMember: [],
      listBookingTypes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      rules: {
        text: [v => (v && v.length <= 255) || !v || this.$t('rules.maximumNCharacter', { value: 255 })],
        email: [
          v =>
            !v ||
            isEmailValid(v) ||
            this.$t('rules.emailIsInvalid'),
          v => (v && v.length <= 255) || !v || this.$t('rules.maximumNCharacter', { value: 255 })
        ]
        // checkNameInput: [v => !!v || this.$t('rules.isInvalid')]
      }
    }
  },

  watch: {
    getStatus () {
      this.getBookingList()
    },
    doneTodosCount (value) {
      if (value !== null) {
        setTimeout(() => {
          this.setAlertSuccess(null)
        }, 2000)
      }
    },
    doneTodosCountError (value) {
      // if (value !== null) {
      //   setTimeout(() => {
      //     this.setAlertError(null)
      //   }, 2000)
      // }
    }
  },

  computed: {
    ...mapGetters(['getStatus', 'getCopyErrorText', 'alertSuccess', 'alertError']),
    dataGetObjClient: {
      get () {
        return this.objClient
      },
      set (value) {
        if (value) {
          this.objClient.name = value
          console.log('aaaaaaaa', this.objClient)
        } else {
          this.objClient = value
          console.log('value null', this.objClient)
        }
      }
    },
    doneTodosCount () {
      return this.alertSuccess
    },
    doneTodosCountError () {
      return this.alertError
    },
    acceptsPet () {
      return !!(this.booking.room?.roomType.acceptsPet)
    },
    showOldPetInfo () {
      return [].includes(this.facilityId) // all facilities are using the new pet flow since TO2020-847
    },
    createdByName () {
      if (this.booking.bookingGroup) {
        const bg = this.booking.bookingGroup
        if (bg.createdSubMember?.name) {
          return bg.createdSubMember.name
        }
      }
      return this.clientName
    },
    clientName () {
      return this.booking.contract?.client.name
    }
  },

  components: {
    SettingCancelDialog,
    CertificateDialog,
    BasicInformationGroupBookings,
    GuestGenderSelect,
    OneLineInputs,
    SettingMoveDialog,
  },
  created () {
    this.getBookingTypeList()
    this.setAlertSuccess(null)
    this.setAlertError(null)
  },

  mounted () {
    this.getBookingList()
  },

  methods: {
    formatDate,
    ...mapMutations(['setAlertSuccess', 'setAlertError', 'setBookingBasicInfomation', 'setLoadingOverlayHide', 'setLoadingOverlayShow', 'setCopyErrorText']),
    ...mapActions(['bookingBasicUpdate']),
    copyErrorToClipboard () {
      navigator.clipboard.writeText(this.getCopyErrorText)
      this.setCopyErrorText(null)
      this.setAlertError(null)
    },

    showSelectFrame () {
      this.showMoveDialog = true
    },

    showPdf () {
      if (this.booking.bookingCertificateUrl) {
        window.open(this.booking.bookingCertificateUrl, '_blank')
      }
    },
    showFloorPlanPdfUrl () {
      if (this.booking.room && this.booking.room.roomType && this.booking.room.roomType.facility && this.booking.room.roomType.facility.floorPlanPdfUrl) {
        window.open(this.booking.room.roomType.facility.floorPlanPdfUrl, '_blank')
      }
    },

    checkDisabled (data) {
      if (data === 'キャンセル') {
        return true
      } else return false
    },

    changeCheckBox (event) {
      this.objUpdate.roomFixed = event
    },

    changeBookingBarNote (event) {
      this.objUpdate.bookingBarNote = event
    },

    changeMailBookingCertificate (event) {
      this.objUpdate.mailBookingCertificate = event
    },

    changeToothBrush (event) {
      this.objUpdate.toothbrush = event
    },

    changeMailPointCertificate (event) {
      this.objUpdate.mailPointCertificate = event
    },

    changeYukata (event) {
      this.objUpdate.yukata = event
    },

    changeMailFacilityGuide (event) {
      this.objUpdate.mailFacilityGuide = event
    },

    changeMailAds (event) {
      this.objUpdate.mailAds = event
    },

    changeRemarks (event) {
      this.objUpdate.remarks = event
    },
    changePetInfo (event) {
      this.objUpdate.petInfo = event
    },
    checkNameInput () {
      if (this.checkName) return true
      else return false
    },

    changeSubMember (event) {
      if (this.listSubMember.includes(event)) {
        this.checkName = true
        if (event.id !== 99999) {
          this.objUpdate.representative.subMemberId = parseInt(event.id)
          this.objUpdate.representative.clientId = null
          this.objUpdate.representative.name = event.name
        } else {
          this.objUpdate.representative.subMemberId = null
          this.objUpdate.representative.clientId = parseInt(event.clientId)
          this.objUpdate.representative.name = event.name
        }
      } else {
        this.checkName = false
        this.objUpdate.representative.subMemberId = null
        this.objUpdate.representative.clientId = null
        this.objUpdate.representative.name = event
      }
    },

    getChangePhoto (event, item) {
      if (event) {
        this.show = true
      } else {
        this.show = false
      }
      this.fileSelect = event
      if (event !== null && typeof event !== 'undefined') {
        const theReader = new FileReader()
        theReader.onloadend = async () => {
          this.srcImgPre = await theReader.result
        }
        theReader.readAsDataURL(event)
        this.$apollo
          .mutate({
            mutation: UPLOAD_PASSPORT_URL,
            variables: {
              contentType: event.type,
              contentLength: event.size
            }
          })
          .then((data) => {
            this.uploadUrl = data.data.generateUploadUrl.uploadUrl
            this.objUpdate.representative.passportPhoto =
              data.data.generateUploadUrl.fileUrl
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        this.uploadUrl = ''
        this.srcImgPre = ''
        this.objUpdate.representative.passportPhoto = ''
      }
    },

    async actionUpdate () {
      if (this.$refs.form.validate()) {
        if (this.fileSelect) {
          await axios.put(
            this.uploadUrl,
            this.fileSelect,
            {
              headers: {
                'Content-Type': this.fileSelect.type
              }
            }
          )
        }
        this.setBookingBasicInfomation({
          bookingBasicInfomation: this.objUpdate
        })
        await this.bookingBasicUpdate()
      }
    },

    async getListName (clientId) {
      await this.$apollo
        .query({
          query: gql`${CLIENT_MEMBER_INFO}`,
          variables: {
            id: clientId
          }
        })
        .then((data) => {
          this.listSubMember = data.data.clientMemberInfo.subMembers
          const item = {
            id: 99999,
            clientId: data.data.clientMemberInfo.id,
            name: data.data.clientMemberInfo.name,
            nameKana: data.data.clientMemberInfo.nameKana,
            email: data.data.clientMemberInfo.email,
            tel: data.data.clientMemberInfo.telPreference,
            address1: ''
          }
          this.listSubMember.unshift(item)
        })
        .catch((error) => {
          console.error(error)
        })
    },

    async getBookingList () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`${BOOKING}`,
          variables: {
            id: parseInt(this.$route.query.id)
          },
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.setLoadingOverlayHide()
          this.booking = data.data.booking
          this.roomTypeId = this.booking.room.roomType.id
          this.objUpdate.bookingId = parseInt(this.$route.query.id)

          if (this.booking.contract) {
            if (this.booking.contract.client.id) {
              this.objUpdate.representative.clientId = parseInt(
                this.booking.contract.client.id
              )
            }
          }
          if (this.booking.room) {
            if (this.booking.room.roomType) {
              if (this.booking.room.roomType.facility.id) {
                this.facilityId = this.booking.room.roomType.facility.id
                this.roomType = this.booking.room.roomType
              }
            }
          }
          if (this.booking.representative) {
            // eslint-disable-next-line no-unused-vars
            const { __typename, ...repData } = this.booking.representative
            this.objUpdate.representative = {
              ...this.objUpdate.representative,
              ...repData
            }
          }
          if (this.booking.contract) {
            this.getListName(this.booking.contract.client.id)
          }
        })
        .catch((error) => {
          this.setLoadingOverlayHide()
          console.error(error)
        })
    },

    async getBookingTypeList () {
      await this.$apollo
        .query({
          query: gql`${BOOKING_TYPES_LIST}`
        })
        .then((data) => {
          this.bookingTypesList = data.data.bookingTypesList
        })
        .catch((error) => {
          console.error(error)
        })
    },

    getNameBookingType () {
      for (let i = 0; i < this.bookingTypesList.length; i++) {
        if (this.booking.contract.id === this.bookingTypesList[i].id) {
          return this.bookingTypesList[i].name
        } else return ''
      }
    },

    getStayDate () {
      const date =
        (new Date(this.booking.checkOutDate) -
          new Date(this.booking.checkInDate)) /
        (1000 * 60 * 60 * 24)
      return date + '泊' + (date + 1) + '日'
    },

    listBookingTypeIdFirst () {
      const arrTypeFirst = [1, 2, 3, 4, 5, 6, 7, 9, 11, 13]
      return arrTypeFirst.find((item) => item === this.booking.bookingTypeId)
    },

    listBookingTypeIdSecond () {
      const arrTypeSecond = [8]
      return arrTypeSecond.find((item) => item === this.booking.bookingTypeId)
    },

    listBookingTypeIdThird () {
      const arrTypeThird = [12]
      return arrTypeThird.find((item) => item === this.booking.bookingTypeId)
    },

    listBookingTypeIdFour () {
      const arrTypeFour = [10]
      return arrTypeFour.find((item) => item === this.booking.bookingTypeId)
    },
    listBookingTypeIdFifth () {
      const arrTypeFifth = [11]
      return arrTypeFifth.find((item) => item === this.booking.bookingTypeId)
    },

    // No 55 CRM
    getLinkCRMContract (booking) {
      if (booking.contract && booking.bookingType && booking.contract.id && booking.bookingType.code) {
        window.open(`${process.env.VUE_APP_URL_CRM}/get/contract/${booking.contract.id}`)
      }
    },

    // No 22 PMS
    async addGroup () {
      localStorage.setItem('bookingGroupId', JSON.stringify(this.booking.bookingGroup.id))
      await localStorage.setItem('contractId', JSON.stringify(this.booking.contract.id))
      await localStorage.setItem('statusAddGroup', JSON.stringify(true))

      await window.open('/reservation/add-group?clientId=' + this.booking.contract.client.id, '_blank')
    },

    // No 17 CRM
    getLinkCRMCustomerDetails (booking) {
      if (booking.contract && booking.contract.client.id) {
        const link = `${process.env.VUE_APP_URL_CRM}/customer-details/${booking.contract.client.id}?typeClient=${booking.contract.client.type}&fromPage=pms`
        window.open(link)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  &.v-input--checkbox label {
    color: var(--text__dark);
    font-size: 14px;
  }
  &.v-divider {
    border-width: thin;
    max-width: 190px;
  }
  &.v-select.pa-0 {
    .v-select__selection--comma {
      color: var(--text__red);
    }
  }
  .t-box {
    &--left,
    &--right {
      .v-input--is-readonly {
        padding: 0;
        pointer-events: none;
        * {
          color: var(--text__inactive) !important;
        }
      }
    }
    &--left .v-input--is-readonly {
      max-width: 300px;
    }
    &--right .v-input--is-readonly {
      margin-bottom: 25px;
    }
  }
  .t-item .v-btn {
    align-self: flex-end;
  }
  .custom-text-field .theme--light.v-input {
    width: 300px;
  }
  .select-nationality .v-select__selection--comma {
    color: #000000 !important;
  }
  .v-file-input__text {
    background-color: #f0f0f0;
  }
  .custom-select-room-sec .v-select__selection--comma {
    color: #444444 !important;
  }
  .cus-checkbox label {
    font-size: 12px !important;
    color: #000000 !important;
  }
  .cus-item-select svg {
    color: #333333 !important;
  }
  .custom-table-text textarea {
    color: #444444 !important;
  }
  .text-select .v-select__selection--comma {
    color: #444444 !important;
  }
  .text-select svg {
    color: #333333 !important;
  }
}
.t-item {
  display: flex;
  padding: 8px;
  border-bottom: solid 2px var(--border__gray-light);
  span:not(:last-child) {
    margin-right: 20px;
  }
}
.title-head {
  color: #0c6786 !important;
  font-size: 20px !important;
  font-weight: bold;
}
.title-head-child {
  font-weight: 600;
  font-size: 10px !important;
  color: #000000 !important;
}
.title-date{
  font-weight: 500;
  font-size: 10px !important;
  color: #000000 !important;
}
.custom-btn-csv {
  font-size: 10px !important;
}
.custom-text {
  font-size: 14px !important;
  color: #000000 !important;
}
.custom-text-stay {
  font-size: 12px !important;
  color: #000000 !important;
}
.custom-table-text {
  color: #444444 !important;
  font-size: 14px !important;
}
.text-combobox-custom{
  ::v-deep{
    .v-select__slot{
      // label{
      //   font-weight: 500;
      //   font-size: 14px;
      //   color: #444444;
      // }
      input {
        font-weight: 500;
        font-size: 14px;
        color: #444444;
        &::placeholder{
          font-size: 14px;
          color: #BDBDBD!important;
          font-weight: 500;
          opacity: 1;
        }
      }
    }
  }
}
.text-select-custom{
  ::v-deep{
    .v-select__slot{
      label{
        font-weight: 500;
        font-size: 14px;
        color: #444444;
      }
    }
  }
}
.text-field{
  ::v-deep{
    .v-text-field__slot{
      .v-label--active{
        font-size: 10px !important;
        color: #000000 !important;
        font-weight: 600 !important;
      }
      label{
        font-size: 14px;
        color: #BDBDBD;
        font-weight: 500;
      }
      input{
        font-weight: 500;
        font-size: 14px;
        color: #444444;
        &::placeholder{
          font-size: 14px;
          color: #BDBDBD!important;
          font-weight: 500;
          opacity: 1;
        }
      }
    }
  }
}
.w-50 {
  width: 50%;
}
.bg-contain {
  background-size: contain !important;
}
::v-deep{
  .v-file-input{
    .v-input__append-inner{
      display: none !important;
    }
  }
}
.main-layout-container.sidebar-open {
  width: calc(100% - 56px);
  margin-left: 56px;
}
.alert-default {
  position: fixed;
  // width: 300px;
  z-index: 1000;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  &.--center {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
