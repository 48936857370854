<template>
  <div>
    <div class="header-main">
      <h3 class="font-weight-bold py-4 page-title-main">
        枠を選択してください
      </h3>

      <div class="d-flex">
        <v-btn color="error" @click="close" class="mr-2">
          {{ $t("buttons.close") }}
        </v-btn>
        <v-btn color="primary" @click="next" :disabled="!ready">
          {{ $t("buttons.next") }}
        </v-btn>
      </div>
    </div>
    <div v-if="$apollo.queries.booking.loading || !booking">読み込み中...</div>
    <div v-else class="px-main">
      <facility-room-type-calendar
        ref="calendar"
        hideCancelWait
        height="800px"
        fixedFacility
        :default-values="{ facilityId, roomTypeId, fromDate: oldCheckInDate }"
      >
        <!-- facility,
          mwCalendarCols, -->
        <template
          v-slot:default="{
            facility,
            fromDate,
            toDate,
            roomType,
            completed,
            refreshCount,
            dateRange,
            dateColWidth,
          }"
        >
          <div class="d-flex calendar-block_tt">
            <div class="t-calendar-item__title">
              <div
                @click="togglePriceClosed(roomType.id)"
                class="d-flex align-center cursor-pointer"
              >
                <v-icon v-if="!isPriceClosed[roomType.id]"
                  >mdi-chevron-down</v-icon
                >
                <v-icon v-else>mdi-chevron-up</v-icon>
                <div>料金表示</div>
              </div>
            </div>
            <div
              class="t-calendar-item__data-item"
              v-for="date in dateRange"
              :key="date"
            >
              &nbsp;
            </div>
          </div>
          <fees-and-points-room-type
            v-show="isPriceClosed[roomType.id]"
            :facility="facility"
            :fromDate="fromDate"
            :toDate="toDate"
            :roomType="roomType"
            :isShowAccomList="true"
            :isShowBookingList="true"
            :mwWeekCalendarList="[]"
            forReservation
            :ref="feesAndPointKey(roomType.id)"
            :dateColWidth="dateColWidth"
          />

          <frame-setting-calendar-room-type
            :refreshCount="refreshCount"
            :fromDate="fromDate"
            :toDate="toDate"
            :roomType="roomType"
            :completed="completed"
            @inventoryClick="inventoryClick"
            :isInventorySelected="isInventorySelected"
            :isInventoryValid="isInventoryValid"
            mode="booking"
          />
        </template>
      </facility-room-type-calendar>
    </div>
  </div>
</template>

<script>
import FacilityRoomTypeCalendar from '@/components/FacilityRoomTypeCalendar'
import {
  addDays,
  getNumberOfDaysBetween,
  getDateRangeArray
} from '@/utils/dateUtil'
import FrameSettingCalendarRoomType from '@/views/dashboard/frameSetting/frameSettingCalendar/frameSettingCalendarRoomType'
import FeesAndPointsRoomType from '@/views/dashboard/feesPoints/feesAndPoints/components/FeesAndPointsRoomType'

import { isForbiddenInventoryType, forbiddenInventoryTypeAlert } from '@/utils/inventory'
import gql from 'graphql-tag'

export default {
  name: 'ReservationCalendarForMove',
  components: {
    FacilityRoomTypeCalendar,
    FrameSettingCalendarRoomType,
    FeesAndPointsRoomType
  },
  props: {
    bookingId: Number,
    defaultSelectedInventories: Array
  },

  async mounted () {
    await this.$store.dispatch('loadInventoryTypes')
  },
  data () {
    return {
      isPriceClosed: {},
      selectedDays: {},
      booking: null
    }
  },
  computed: {
    ready () {
      return this.checkInDate
    },
    checkInDate () {
      return Object.keys(this.selectedDays).sort()[0]
    },
    checkOutDate () {
      return addDays(this.checkInDate, this.days)
    },
    days () {
      return Object.keys(this.selectedDays).length
    },
    selectedInventories () {
      return Object.keys(this.selectedDays)
        .sort()
        .map((stayDate) => {
          return {
            stayDate,
            inventoryTypeId: this.selectedDays[stayDate].inventoryTypeId
          }
        })
    },
    facilityId() {
      return this.booking?.room.roomType.facilityId
    },
    roomTypeId() {
      return this.booking?.room.roomType.id
    },
    oldCheckInDate() {
      return this.booking?.checkInDate
    }
  },
  methods: {
    togglePriceClosed (roomTypeId) {
      this.$set(
        this.isPriceClosed,
        roomTypeId,
        !this.isPriceClosed[roomTypeId]
      )
    },
    getDayObj ({ inventory, ind }) {
      return {
        stayDate: inventory.stayDate,
        inventoryTypeId: inventory.inventoryTypeId,
        roomTypeId: inventory.roomTypeId,
        ind
      }
    },
    inventoryClick (payload) {
      const aDay = this.getDayObj(payload)

      if (isForbiddenInventoryType(aDay.inventoryTypeId)) {
        this.$alert(forbiddenInventoryTypeAlert())
        return
      }

      // cleanup if there are other room types selected
      if (
        Object.values(this.selectedDays).some(
          (day) => day.roomTypeId !== aDay.roomTypeId
        )
      ) {
        this.selectedDays = {}
      }
      // if not the first cell to click, it must be
      // 1. next to a already selected day
      // 2. or updating already selected day
      if (
        Object.keys(this.selectedDays).length &&
        !this.selectedDays[aDay.stayDate]
      ) {
        const before = addDays(aDay.stayDate, -1)
        const after = addDays(aDay.stayDate, 1)
        if ([before, after].every((date) => !this.selectedDays[date])) {
          return false // don't select
        }
      }
      const current = this.selectedDays[aDay.stayDate]
      if (!current) {
        this.$set(this.selectedDays, aDay.stayDate, aDay)
      } else {
        if (
          current.inventoryTypeId === aDay.inventoryTypeId &&
          current.ind === aDay.ind
        ) {
          // and the days after this until checkout should be removed as well
          const range = getDateRangeArray(
            aDay.stayDate,
            this.checkOutDate,
            true
          )
          for (const date of range) {
            this.$delete(this.selectedDays, date)
          }
        } else {
          this.$set(this.selectedDays, aDay.stayDate, aDay)
        }
      }
    },

    isInventorySelected (payload) {
      const aDay = this.getDayObj(payload)
      const current = this.selectedDays[aDay.stayDate]
      if (!current) return false

      return (
        current.roomTypeId === aDay.roomTypeId &&
        current.ind === aDay.ind &&
        current.inventoryTypeId === aDay.inventoryTypeId
      )
    },

    isInventoryValid (payload) {
      const keys = Object.keys(this.selectedDays)
      if (keys.length === 0) return false

      const aDay = this.getDayObj(payload)

      // get min and max dates in selected days
      const firstDate = keys.reduce(function (a, b) {
        return a < b ? a : b
      })

      const lastDate = keys.reduce(function (a, b) {
        return a > b ? a : b
      })

      // if room type id or inventory type id is not same as day in the selected days
      if (
        this.selectedDays[firstDate].roomTypeId !== aDay.roomTypeId ||
        this.selectedDays[firstDate].inventoryTypeId !== aDay.inventoryTypeId
      ) {
        return false
      }

      if (
        (aDay.stayDate < firstDate &&
          Math.abs(getNumberOfDaysBetween(aDay.stayDate, firstDate)) > 1) ||
        (aDay.stayDate > lastDate &&
          Math.abs(getNumberOfDaysBetween(aDay.stayDate, lastDate)) > 1)
      ) {
        return true
      }

      return false
    },

    feesAndPointKey (roomTypeId) {
      return `feesAndPointKey${roomTypeId}`
    },

    next () {
      this.$emit('next', {
        selectedInventories: this.selectedInventories,
      })
    },

    close () {
      this.$emit('close')
    }
  },
  apollo: {
    booking: {
      query: gql`
        query bookingForMove($id: Int!) {
          bookingForMove(id: $id) {
            id
            checkInDate
            checkOutDate
            room {
              roomType {
                id
                facilityId
              }
            }
            accommodationPrices {
              stayDate
              inventoryTypeId
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables () {
        return {
          id: this.bookingId
        }
      },
      skip () {
        return !this.bookingId
      },
      update: (data) => data.bookingForMove,
      result ({ data }) {
        if (data && data.bookingForMove) {
          if (this.defaultSelectedInventories?.length) {
            this.selectedDays = this.defaultSelectedInventories.reduce((acc, { stayDate, inventoryTypeId }) => {
              acc[stayDate] = { stayDate, inventoryTypeId, roomTypeId: this.roomTypeId, ind: 0 }
              return acc
            }, {})
          } else {
              this.selectedDays = this.booking.accommodationPrices.reduce((acc, { stayDate, inventoryTypeId }) => {
                acc[stayDate] = { stayDate, inventoryTypeId, roomTypeId: this.booking.room.roomType.id, ind: 0 }
                return acc
              }, {})
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::v-deep {
  .modal-custom_select {
    label {
      font-size: 13px !important;
    }
    .v-select__selections input {
      font-size: 12px !important;
    }
  }
}
.select-people {
  width: 30px !important;
}
.select-child {
  width: 73px !important;
}
.contract-select {
  width: 100px;
}
.plan-select {
  width: 100px;
}
.text-red {
  color: #ff0000;
  font-size: 14px;
}
.text-blue {
  color: #2a7edc;
}
.modal-custom_block {
  color: #000;
}
.modal-custom_txt {
  font-size: 10px;
  white-space: nowrap;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.main-calendar {
  &_l {
    @media (min-width: 1600px) {
      flex: 0 0 33.3333333333%;
      max-width: 70%;
      .modal-custom_block {
        width: 100%;
      }
    }
  }
  &_r {
    @media (min-width: 1600px) {
      flex: 0 0 66.6666666667%;
      .modal-custom_block {
        width: 100%;
      }
    }
  }
}
.cursor-pointer {
  height: 20px;
}
.calendar-block_tt {
  .t-calendar-item__data-item {
    border-top: 1px solid $calendar-border-color;
  }
}
</style>
