<template>
  <div>
    <v-dialog v-model="visible" @click:outside="closeDialog" width="800" v-if="statusSelect">
      <v-card class="pa-2">
        <v-card-title>
          <p class="custom-title mb-0"> キャンセル </p>
        </v-card-title>
        <v-form ref="form">
            <v-card-text class="mt-4 pr-1">
          <p class="title-calendar ml-5 mb-0">キャンセル日</p>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <div>
                <v-icon class="icon-calendar">mdi-calendar-month</v-icon>
                <v-btn
                  outlined
                  v-model="date"
                  v-on="on"
                  small
                  color="#888888"
                  class="bg--btn-white mr-5 datePicker"
                  >{{ formatDate(date) }}</v-btn
                >
              </div>
            </template>
            <v-date-picker
              v-model="date"
              :first-day-of-week="0"
              :locale="$i18n.locale"
              scrollable
              @input="menu = false"
              class="v-date-picker-custom"
            ></v-date-picker>
          </v-menu>
          <v-col cols="6" class="pl-0">
            <v-select
              v-if="statusShowSelect"
              v-model="typeCancel"
              :items="['連絡なしノーショー', '通常キャンセル']"
              :label="$t('contractDetail.selectCancellationOnTheDay')"
              color="#C92D2D"
            />
          </v-col>
          <v-form ref="formCancelBooking">
            <div class="d-flex" v-for="(item, index) in cancelDays" :key="index">
              <template>
                <v-col cols="3" class="pl-0">
                  <div class="title-list-day ">{{ index + 1 }}日目</div>
                  <div class="list-day">{{formatDate(item.stayDate)}}</div>
                </v-col>
                <v-col cols="3" class="pl-0 text-right">
                  <div class="title-list-day">{{ $t('contractDetail.settingCancellationPolicy') }}</div>
                  <div class="value-rate">{{setValue(item.originalPenaltyRate)}}</div>
                </v-col>
                <v-col cols="2">
                  <tvos-int-input
                    :label="$t('contractDetail.cancellationRate')"
                    color="#C92D2D"
                    :value="item.penaltyRate * 100"
                    @input="$set(item, 'penaltyRate', $event / 100)"
                    required
                    prefix="%"
                    reverse
                    :max="100"
                  >
                  </tvos-int-input>
                </v-col>
                <v-col cols="4">
                  <cancel-penalty-data :item="item" :cancelDate="date" />
                </v-col>
              </template>
            </div>
          </v-form>
        </v-card-text>
        </v-form>
        <v-card-text class="d-flex justify-end align-center pr-4">
          <v-btn
            color="var(--bt__red)"
            class="mr-3 d-flex align-center btn_status white--text"
            @click="closeDialog"
          >
            <v-icon class="white--text"> mdi-close </v-icon>
            {{ $t('common.close') }}
          </v-btn>
          <v-btn
            class="white--text"
            color="var(--bt__blue)"
            @click="actionUpdate()"
          >
            {{ $t('buttons.changeToCancel') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { CALCULATE_BOOKING_CANCEL_PENALTY } from '@/api/graphql/bookingDetail/setting-cancel'
import moment from 'moment'
import { formatDate } from '@/constants/functions'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import gql from 'graphql-tag'
import { isoDate } from '@/utils/dateUtil'
import CancelPenaltyData from './cancelPenaltyData.vue'

export default {
  name: 'SettingCancelDialog',
  components: { CancelPenaltyData },
  props: {
    visible: Boolean,
    title: String,
    lastDayCancel: Boolean
  },
  data () {
    return {
      statusSelect: true,
      listItemRate: [],
      listCancelBooking: null,
      secondaryConfirmer: '今津康平',
      menu: false,
      date: isoDate(new Date()),
      typeCancel: '通常キャンセル',
      // statusShowSelect: null,
      isNumberCost (v) {
        return (!v || /^[0-9.]*$/i.test(v)) || 'キャンセル率用の形式が不正です。'
      },
      maxNumber (v) {
        return (parseFloat(v) <= 100) || '入力内容に誤りがあります。'
      }
    }
  },

  mounted () {
    this.getListCancelPenalty()
  },

  watch: {
    async typeCancel () {
      this.getListCancelPenalty()
    },

    getStatus () {
      this.closeDialog()
    },
    date () {
      this.getListCancelPenalty()
    }
  },

  computed: {
    ...mapGetters(['getStatus']),
    cancelDays () {
      if (!this.listCancelBooking) return []

      if (this.lastDayCancel) {
        return [this.listCancelBooking[this.listCancelBooking.length - 1]]
      } else {
        return this.listCancelBooking
      }
    },
    statusShowSelect () {
      if (!this.cancelDays || this.cancelDays.length === 0) return false
      var day1 = new Date()
      var day2 = new Date(this.cancelDays[0].stayDate)

      var difference = day1.getTime() - day2.getTime()
      return difference >= 0
    }
  },

  methods: {
    formatDate,
    ...mapMutations({
      setBookingCancel: 'setBookingCancel',
      setLoadingOverlayShow: 'setLoadingOverlayShow',
      setLoadingOverlayHide: 'setLoadingOverlayHide'
    }),
    ...mapActions({ actionUpdateCancel: 'cancelBooking' }),

    async changeTypeCancel (event) {
      await this.getListCancelPenalty(event)
    },

    setValue (item) {
      return item * 100 + '%'
    },

    formatDateSort (value) {
      return moment(value).format('D日目')
    },

    async getListCancelPenalty () {
      const noShow = this.typeCancel !== '通常キャンセル'
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`${CALCULATE_BOOKING_CANCEL_PENALTY}`,
          variables: {
            bookingId: parseInt(this.$route.query.id),
            cancelDate: this.date,
            noShow
          },
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.setLoadingOverlayHide()
          this.listCancelBooking = data.data.calculateBookingCancelPenalty.penalties.map(p => {
            return {
              ...p,
              originalPenaltyRate: p.penaltyRate
            }
          })
        })
        .catch((error) => {
          this.setLoadingOverlayHide()
          console.error(error)
        })
    },
    async actionUpdate () {
      if (this.$refs.formCancelBooking.validate()) {
        const arrUpdate = []
        for (let i = 0; i < this.cancelDays.length; i++) {
          const item = {
            penaltyRate: this.cancelDays[i].penaltyRate,
            stayDate: this.cancelDays[i].stayDate
          }
          arrUpdate.push(item)
          const bookingCancel = {
            penaltyRates: arrUpdate
          }
          this.setBookingCancel({ bookingCancel: bookingCancel })
        }
        const result = await this.actionUpdateCancel()
        if (result.data.cancelBooking.needsTvpAdjustment) {
          this.$store.dispatch('tvpPrePaymentConfirm')
        }
      }
    },
    closeDialog () {
      this.getListCancelPenalty()
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.custom-title {
  font-size: 20px;
  color: #000000 ;
  font-weight: bold !important;
}
.icon-calendar {
  color: #333333
}
.title-calendar {
  font-size: 10px;
  color: #000000
}
.title-list-day {
  font-size: 10px;
  color: #212121
}
.list-day {
  font-size: 14px;
  color:#212121 !important;
}
.value-rate {
  font-size: 14px;
  color: #212121;
}
::v-deep {
  .v-select__slot label {
    color: #C92D2D;
    font-size: 10px;
  }
  .v-select__slot input {
    color: #C92D2D;
    font-size: 10px;
  }
  .v-select__selection--comma {
    color: #C92D2D;
  }
  .datePicker .v-btn__content {
    color: #000000
  }
  .v-input {
    font-size: 10px !important;
  }
  .error--text .v-messages__message {
    font-size: 12px;
  }
}
</style>
