<template>
  <div class="t-calendar-item">
    <div class="t-calendar-item__title">
      {{ getItemName }}
    </div>
    <div class="t-calendar-item__data-list">
      <Pass
        :disabled="!checkPerUser"
        v-for="date in dateListAll"
        :key="date"
        :metadata="{
          price: getPrice(date),
        }"
      >
        <div
          class="t-calendar-item__data-item"
          slot-scope="{ metadata }"
          :class="getClassObject(metadata.price)"
          @mouseover="handleMouseOver($event, metadata.price, date)"
          @mouseleave="handleMouseLeave(metadata.price)"
          @click="checkPerUser ? handleClick(metadata.price, date) : () => {}"
        >
          {{ findAccomItem(metadata.price) }}
        </div>
      </Pass>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/constants/functions'
import Pass from '@/components/shared/Pass.vue'
import { checkPermissionUserCurrent } from '@/utils/permissions'
// lodash
import debounce from 'lodash/debounce'

export default {
  name: 'AccomItem',
  components: { Pass },
  props: {
    type: String,
    roomId: [Number, String],
    order: [Number, String],
    minPrice: [Number, String],
    maxPrice: [Number, String],
    dateListAll: {
      type: Array,
      default: () => []
    },
    accommodationPriceDict: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    getItemName () {
      switch (this.type) {
        case 'vs':
          return `VS${this.order}人`
        case 'vm':
          return `MO${this.order}人`
        case 'sp':
          return 'SP'
        case 'fp':
          return 'FP'
        case 'dp':
          return 'DP'
        default:
          return ''
      }
    },
    getKeyValue () {
      const order = this.order
      const type = this.type

      if (order) {
        return `${type}Price${order}`
      } else if (type === 'sp') {
        return 'spPoint'
      } else if (type === 'fp') {
        return 'fpPoint'
      } else if (type === 'dp') {
        return 'dpPrice'
      }

      return ''
    }
  },
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute)
    }
  },
  methods: {
    getClassObject (price) {
      const type = this.type
      return {
        'bg--vs': type === 'vs',
        'bg--vm': type === 'vm',
        'bg--sp': type === 'sp',
        'bg--dp': type === 'dp',
        'bg--fp': type === 'fp',
        'font-weight-bold': this.isBlackedOut(price)
      }
    },
    isBlackedOut (price) {
      if (!price) return
      const minPrice = this.minPrice ? Number(this.minPrice) : 0
      const maxPrice = this.maxPrice ? Number(this.maxPrice) : 0

      if (minPrice && maxPrice) {
        return price >= minPrice && price <= maxPrice
      }

      return (minPrice && price >= minPrice) || (maxPrice && price <= maxPrice)
    },
    getPrice (stayDate) {
      const item = this.accommodationPriceDict[stayDate]

      if (!item) return

      return item[this.getKeyValue]
    },
    findAccomItem (price) {
      const type = this.type
      if (!price) return

      if (this.order || type === 'dp') {
        return `${formatCurrency(price)}`
      } else if (type === 'sp' || type === 'fp') {
        return `${formatCurrency(price)}pt`
      }
    },
    // ================= HANDLE MIN OR MAX PRICE ========================
    // after 500 milliseconds since the last value was changed,
    // This is good for the system to avoid calling the API many times in a short time
    handleMouseOver: debounce(function (event, price, date) {
      // returns the size of table container and its position relative to the viewport.
      // https://caniuse.com/getboundingclientrect
      const position = event.target.getBoundingClientRect()
      if (price) {
        this.$emit(
          'hoverItem',
          position,
          this.getItemName,
          this.getKeyValue,
          date,
          this.roomId
        )
      }
    }, 500),
    handleMouseLeave: debounce(function (price) {
      if (!price) this.$emit('leaveItem')
    }, 500),
    handleClick (price, date) {
      this.$emit(
        'clickItem',
        price,
        date,
        this.roomId,
        this.order ? `${this.type}${this.order}` : this.type,
        this.getKeyValue
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.t-calendar-item__data-item {
  align-items: flex-end;
  justify-content: flex-end;
  vertical-align: bottom;
  line-height: 1;
}
</style>
