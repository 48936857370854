<template>
  <div class="pa-5">
    <h3 class="text--title fz-18">
      請求明細
    </h3>
    <v-card class="pa-5 t-card mt-5" elevation="4">
      <v-row>
        <v-col cols="12" md="8">
          <table>
            <tr>
              <div class="t-header">
                <h4 class="fz-13">{{ $t("common.subjectName") }}</h4>
                <h4 class="fz-13">{{ $t("common.unit") }}</h4>
                <h4 class="fz-13">{{ $t("common.unitPrice") }}</h4>
                <h4 class="text--small fz-9">{{ $t("common.points") }}</h4>
                <h4 class="fz-13">{{ $t("common.order") }}</h4>
                <h4 class="fz-10">{{ $t("common.salesTaxRate") }}</h4>
                <h4 class="fz-13">{{ $t("common.subtotal") }}</h4>
              </div>
            </tr>
            <tr v-if="getBillingStatementList.length > 0">
              <div ref="heigthDetailPayment" :style="`height: ${(heigthDetailPayment >= 450 ? '450px': 'auto')}; overflow-y: auto;`">
                <div v-for="(item, index) in getBillingStatementList" :key="index">
                  <div class="bg--gray-dark py-1 px-3 mb-1 text--white fz-10">
                    {{ (index+1) + $t("common.night") }}
                    <span class="ml-3" v-if="item.length > 0">{{ `${formatDateStay(item[0].stayDate)}` }}</span>
                  </div>
                  <template v-if="bookingTypeId">
                    <NightRentItemList
                    v-for="child in item"
                    :key="child.id"
                    :night-point="index+1"
                    :billing-detail="child"
                    class="mb-0"
                    :booking-type-id="bookingTypeId"
                    @fetchList="getListBillingStatement()"
                  />
                  </template>
                </div>
              </div>
            </tr>
            <tr v-else >見つかりませんでした。</tr>
            <tr v-if="getBillingStatementList.length > 0">
              <TaxSummary :totalByTaxRate="getTotalByTaxRate"/>
              <ChargeInfo :chargeInfo="getChargeInfo" class="mt-2" />
              <Summary :billings="getBillingStatementList" />
            </tr>
          </table>
        </v-col>
        <v-col cols="12" md="4">
          <h4 class="mb-5 fz-14">{{ $t("common.notices") }}</h4>

          <!-- Data text -->
          <div class="text--small fz-9" v-if="chargeRemarks.length > 0">
            <p v-for="(item,index) in chargeRemarks" :key="index">{{ item }}</p>
          </div>
        </v-col>
      </v-row>
    </v-card>

  </div>
</template>
<script>
import moment from 'moment'
import { formatCurrency, handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import { mapMutations, mapGetters } from 'vuex'
import NightRentItemList from '@/components/NightRentItem/list'
import { isEmailValid } from '@/utils/validators.js'
import gql from 'graphql-tag'
import {
  BOOKING
} from '@/api/graphql/bookingDetail/booking-detail-basic'

import {
  BILLING_STATEMENT_LIST,
  CHARGE_REMARKS
} from '@/api/graphql/billingStatement/listBillingStatement'
import Summary from './summary.vue'
import TaxSummary from './TaxSummary.vue'
import ChargeInfo from './ChargeInfo.vue'

export default {
  name: 'ListPointTieUp',
  components: {
    NightRentItemList,
    Summary,
    TaxSummary,
    ChargeInfo,
  },
  computed: {
    ...mapGetters(['getBillingStatementList', 'getChargeIds', 'getChargePrice',
      'getCreateBookingInvoicesCurrent', 'getBillingStatementListPayment',
      'getTotalByTaxRate', 'getChargeInfo',
    ])
  },
  async created () {
    await this.getBookingList()
    await this.getListBillingStatement()
    this.chargeRemarksInfo()
  },
  data () {
    return {
      arrDate: [],
      // payment methods
      paymentMethodListRoot: [],
      categoryPaymentMethod: [],
      categoryPaymentMethodSelected: null,
      listCard: [],
      listCardSelected: null,

      // list billing statement
      heigthDetailPayment: 0,
      heigthDetailPaymentMethod: 0,
      chargeRemarks: [],
      bookingTypeId: null,
      bookingGroupId: null,
      bookingGroupListCharges: [],

      // modal preview pdf billing
      openDialogEditBillingPdf: false,
      radioGroup: 1,
      email: '',
      pdfFileUrl: '',
      disableEmail: true,
      addresseeName: '',
      receiptFor: '',
      addresseeNameBlank (v) {
        return !!v || '芳名を入力してください'
      },
      receiptForBlank (v) {
        return !!v || '但し書きを入力してください'
      },
      emailBlank (v) {
        return !!v || '送り先メールアドレスを入力してください'
      },
      regexMail (v) {
        return (!v || isEmailValid(v)) || this.$t('rules.emailIsInvalid')
      }
    }
  },
  methods: {
    formatCurrency,
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations({
      setCopyErrorText: 'setCopyErrorText',
      setAlertSuccess: 'setAlertSuccess',
      setAlertError: 'setAlertError',
      setBillingStatementList: 'setBillingStatementList',
      setChargeIdsDefault: 'setChargeIdsDefault',
      setLoadingOverlayShow: 'setLoadingOverlayShow',
      setLoadingOverlayHide: 'setLoadingOverlayHide',
      setPermissionUser: 'setPermissionUser',
      setRoleAdminUser: 'setRoleAdminUser'
    }),

    async getBookingList () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`${BOOKING}`,
          variables: {
            id: parseInt(this.$route.query.id)
          },
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.getDates(data.data.booking.checkInDate, data.data.booking.checkOutDate)
          this.bookingTypeId = data.data.booking.bookingTypeId
          this.bookingGroupId = data.data.booking.bookingGroupId
        })
        .catch((error) => {
          this.setLoadingOverlayHide()
          console.error(error)
        })
    },
    getDates (checkIn, checkOut) {
      var dateArray = []
      var currentDate = moment(checkIn)
      var stopDate = moment(checkOut)
      while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
        currentDate = moment(currentDate).add(1, 'days')
      }
      dateArray.pop()
      this.arrDate = dateArray
    },

    formatDateStay (value) {
      return moment(value).format('yyyy/MM/DD')
    },
    groupBy (arr, prop) {
      const map = new Map(Array.from(arr, obj => [obj[prop], []]))
      arr.forEach(obj => map.get(obj[prop]).push(obj))
      return Array.from(map.values())
    },
    async getListBillingStatement (reload = false) {
      this.setLoadingOverlayShow()
      this.setBillingStatementList({ billingStatementList: [], billingStatementListPayment: [], totalByTaxRate: [] })
      this.heigthDetailPayment = 0
      this.heigthDetailPaymentMethod = 0
      const variables = {
        bookingGroupId: parseInt(this.bookingGroupId)
      }
      await this.$apollo.query({
        query: gql`${BILLING_STATEMENT_LIST}`,
        variables: variables,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        const charges = data.data.bookingGroupListChargesV2.charges
        for (let i = 0; i < this.arrDate.length; i++) {
          if (!charges.some(el => el.stayDate === this.arrDate[i])) {
            const item = {
              booking: {
                id: this.$route.query.id,
                room: {
                  name: ''
                }
              },
              bookingId: this.$route.query.id,
              bookingInvoiceId: null,
              externalPaymentMethodId: null,
              fpPoint: null,
              id: null,
              note: null,
              price: null,
              priceChangeAllowed: null,
              priceWithSalesTax: null,
              quantity: null,
              salesTax: null,
              showCreate: true,
              showEdit: false,
              spPoint: null,
              stayDate: this.arrDate[i],
              subPoint: null,
              subPrice: '',
              subject: '',
              taxIncluded: false,
              tvpPoint: null,
              type: {
                name: ''
              },
              typeId: null
            }
            charges.push(item)
          }
        }
        const dataTmp = charges
        if (dataTmp.length > 0) {
          // 合計:現金, SP
          dataTmp.map((item) => {
            if (item.price !== null) {
              item.showEdit = true
            } else {
              item.showEdit = false
            }
            item.subPrice = item.priceWithSalesTax * item.quantity
            const pointPrice = this.checkShowValueSP(item) * item.quantity
            item.subPoint = pointPrice

            return item
          })

          this.bookingGroupListCharges = this.groupBy(dataTmp, 'stayDate')
          this.bookingGroupListCharges.map((item) => {
            const itemLast = [...item].pop()
            itemLast.showCreate = true
            return item
          })
          this.setBillingStatementList({
            billingStatementList: this.bookingGroupListCharges,
            billingStatementListPayment: data.data.bookingInvoiceList,
            totalByTaxRate: data.data.bookingGroupListChargesV2.totalByTaxRate
          })
        }
        // height list billing detail
        this.$nextTick(() => {
          this.heigthDetailPayment = this.$refs.heigthDetailPayment ? this.$refs.heigthDetailPayment.clientHeight : 0
          this.heigthDetailPaymentMethod = this.$refs.heigthDetailPaymentMethod ? this.$refs.heigthDetailPaymentMethod.clientHeight : 0
        })
        this.setLoadingOverlayHide()
      }).catch(async (error) => {
        this.setLoadingOverlayHide()
        this.setCopyErrorText(this.getCopyErrorTextView(BILLING_STATEMENT_LIST, variables, error.graphQLErrors))
        const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      })
    },
    checkShowValueSP (billingDetailValueSP) {
      if (billingDetailValueSP) {
        if (billingDetailValueSP.spPoint !== null) {
          return `${billingDetailValueSP.spPoint}`
        }
        if (billingDetailValueSP.fpPoint !== null) {
          return `${billingDetailValueSP.fpPoint}`
        }
        if (billingDetailValueSP.tvpPoint !== null) {
          return `${billingDetailValueSP.tvpPoint}`
        }
      }
      return 0
    },
    async chargeRemarksInfo () {
      const variables = {
        bookingGroupId: parseInt(this.bookingGroupId)
      }
      await this.$apollo.query({
        query: gql`${CHARGE_REMARKS}`,
        variables: variables
      }).then((data) => {
        if (data.data.bookingGroup.chargeRemarks.length > 0) {
          this.chargeRemarks = data.data.bookingGroup.chargeRemarks
        }
      }).catch(async (error) => {
        this.setLoadingOverlayHide()
        this.setCopyErrorText(this.getCopyErrorTextView(CHARGE_REMARKS, variables, error.graphQLErrors))
        const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      })
    }
  },
  beforeDestroy () {
    this.setChargeIdsDefault()
  }

}
</script>

<style lang="scss" scoped>
.v-select-custom.v-input {
  max-width: 300px;
  background-color: var(--bg__white);
}
h4,
h5,
h6 {
  font-weight: 500;
}
.t-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 30px;
  margin-bottom: 8px;
  h4 {
    text-align: center;
    flex-shrink: 0;
    width: 40px;
    &:nth-child(1) {
      width: 130px;
      text-align: left;
    }
    &:nth-child(5) {
      width: 40px;
    }
    &:nth-child(3),
    &:last-child {
      text-align: right;
    }
  }
}
::v-deep {
  .v-card {
    color: var(--text__dark);
  }
  .t-card .row {
    .col-md-8 {
      overflow-x: auto;
    }
    table {
      width: 100%;
      tr {
        &:not(:first-child, :last-child) {
          font-size: 12px;
        }
      }
    }
  }
}
::v-deep {
  .fz-9 {
    font-size: 9px !important;
  }
  .fz-12 {
    font-size: 12px !important;
  }
  .fz-14 {
    font-size: 14px !important;
  }
  .fz-11 {
    font-size: 11px !important;
  }
  .fz-10 {
    font-size: 10px !important;
  }
  .fz-13 {
    text-align: center !important;
    font-size: 13px !important;
  }
  .fz-15 {
    font-size: 15px !important;
  }
  .fz-16 {
    font-size: 16px !important;
  }
  .fz-20 {
    font-size: 20px !important;
  }
  .fz-18 {
    font-size: 18px !important;
  }
  .v-messages__message {
    font-size: 12px;
  }
  .text-card-gray {
    color: #AAAAAA;
  }
  .text-card-pay {
    color: rgb(0, 0, 0)
  }
  .color-text-pay {
    color: #C92D2D;
  }
  .color-title-text {
    color: #000000;
  }
  .color-title-email-text {
    color: #444444;
  }
}
::v-deep .input-update .v-text-field__slot .v-label {
  font-size: 10px !important;
  color: #000000;
}
::v-deep .input-email .v-text-field__slot .v-label {
  font-size: 10px !important;
  color: #AAAAAA;
}
::v-deep .input-radio .v-label {
  color: #444444;
}
::v-deep span .fz-10 {
  text-align: center !important;
}
</style>
