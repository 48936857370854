import _ from 'lodash'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import moment from 'moment'
import {
  BOOKING, GET_PRE_PAYMENT_INFO, BOOKING_PRE_PAYMENT_UPDATE
} from '@/api/graphql/bookingDetail/booking-detail-basic'
import gql from 'graphql-tag'
import { mapMutations } from 'vuex'
import { pdfUrlHideScrollbar } from '@/utils/urls'
import { isEmailValid } from '@/utils/validators.js'
import { formatCurrency, handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import {
  BILLING_STATEMENT_LIST,
  CREATE_BOOKING_INVOICES,
  CHARGE_REMARKS,
  DELETE_BOOKING_INVOICES,
  UPDATE_BOOKING_INVOICES,
  FINALIZE_BOOKING_INVOICES,
  DETAIL_BOOKING_INVOICES
} from '@/api/graphql/billingStatement/listBillingStatement'
import { BookingTypeIds, canUseTvp } from '../../constants/bookingType'
import { BookingPaymentMethodId } from '../../constants/bookingPaymentType'

const MULTIPLE_OTHER = '複数その他'

export default {
  computed: {
    bookingId () {
      return parseInt(this.$route.query.id)
    },
    bookingTypeId () {
      return this.booking?.bookingTypeId
    },
    bookingGroupId () {
      return this.booking?.bookingGroupId
    },
    checkInDate () {
      return this.booking?.checkInDate
    },
    checkOutDate () {
      return this.booking?.checkOutDate
    },
    isCompany () {
      return this.booking?.contract.client.isCompany
    },
    showGroupCharges () {
      // see TO2020-841 for the detail
      return this.booking?.checkedInAt && new Date(this.booking.checkedInAt) < new Date(process.env.VUE_APP_TO2020_841_THRESHOLD)
    },
    arrDate () {
      var dateArray = []
      var currentDate = moment(this.checkInDate)
      var stopDate = moment(this.checkOutDate)
      while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
        currentDate = moment(currentDate).add(1, 'days')
      }
      dateArray.pop()
      return dateArray
    },
    canUseTvp () {
      return canUseTvp(this.bookingTypeId)
    },
    isMw () {
      return this.bookingTypeId === BookingTypeIds.MW
    },
    shouldShowNewInvoiceForm () {
      if (this.getBillingStatementList) {
        for (const item of this.getBillingStatementList) {
          const obj = item.find(e => !e.bookingInvoiceId && e.price !== null)
          if (obj) {
            return true
          }
        }
      }
      return false
    },
    selectedCharges () {
      return this.charges.filter((c) => this.getChargeIds.includes(c.id))
    }
  },
  data () {
    return {
      booking: null,
      charges: [],
      // payment methods
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      paymentMethodListRoot: [],
      categoryPaymentMethod: [],
      categoryPaymentMethodSelected: null,
      listCard: [],
      listCardSelected: null,
      listCardSelectedMutiplate1: null,
      listCardSelectedMutiplate2: null,
      listCardSelectedMutiplate3: null,
      listCardSelectedMutiplate4: null,
      amountMutiplate1: null,
      amountMutiplate2: null,
      amountMutiplate3: null,
      amountMutiplate4: null,
      mutiplatePaymentMethods: false,
      prePaymentInfo: null,

      // list billing statement
      totalAllPrice: 0,
      totalAllPoint: 0,
      heigthDetailPayment: 0,
      heigthDetailPaymentMethod: 0,
      chargeRemarks: [],
      paymentInstruction: null,
      createdSubMember: null,
      bookingGroupListCharges: [],

      // modal preview pdf billing
      btnTextSendMail: '発行登録',
      sendMailDone: false,
      openDialogEditBillingPdf: false,
      radioGroup: 1,
      email: '',
      pdfFileUrl: '',
      disableEmail: true,
      addresseeName: '',
      receiptFor: '',
      addresseeNameBlank (v) {
        return !!v || '芳名を入力してください'
      },
      receiptForBlank (v) {
        return !!v || '但し書きを入力してください'
      },
      emailBlank (v) {
        return !!v || '送り先メールアドレスを入力してください'
      },
      regexMail (v) {
        return (!v || isEmailValid(v)) || this.$t('rules.emailIsInvalid')
      },
      pdfUrlHideScrollbar
    }
  },
  watch: {
    categoryPaymentMethodSelected (newVal) {
      if (newVal) {
        if (newVal === MULTIPLE_OTHER) {
          this.mutiplatePaymentMethods = true
          this.listCard = this.paymentMethodListRoot
          this.listCardSelected = null
        } else {
          this.mutiplatePaymentMethods = false
          this.listCardSelectedMutiplate1 = null
          this.listCardSelectedMutiplate2 = null
          this.listCardSelectedMutiplate3 = null
          this.listCardSelectedMutiplate4 = null
          this.amountMutiplate1 = null
          this.amountMutiplate2 = null
          this.amountMutiplate3 = null
          this.amountMutiplate4 = null
          this.listCard = this.paymentMethodListRoot.filter((item) => {
            return item.category === newVal
          })
        }
      }
    },
    radioGroup (newVal) {
      if (newVal) {
        switch (newVal) {
          case 1:
            this.email = this.getCreateBookingInvoicesCurrent.defaultEmailTo
            this.disableEmail = true
            this.$refs.finalizeBookingInvoices.resetValidation()
            break
          case 2:
            this.email = this.getCreateBookingInvoicesCurrent.representativeEmailTo
            this.disableEmail = true
            this.$refs.finalizeBookingInvoices.resetValidation()
            break
          case 3:
            this.disableEmail = false
            this.email = ''
            break
          default:
            this.email = ''
            break
        }
      }
    }
  },
  async created () {
    await this.getBookingList()
    await this.getListBillingStatement()
    await this.chargeRemarksInfo()
    await this.bookingPaymentMethodList()
  },
  methods: {
    formatCurrency,
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations({
      setLoadingOverlayShow: 'setLoadingOverlayShow',
      setLoadingOverlayHide: 'setLoadingOverlayHide'
    }),
    isShowCategory (index, indexPay, itemPay) {
      const listCategory = this.getBillingStatementListPayment[index].payments.filter(e => e.bookingPaymentMethod.category === this.getBillingStatementListPayment[index].payments[indexPay].bookingPaymentMethod.category)
      if (listCategory.length > 1) {
        if (listCategory.findIndex(e => e.id === itemPay.id) === 0) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    formatDateStay (value) {
      return moment(value).format('yyyy/MM/DD')
    },
    formatNightIndex (value) {
      const ind = this.arrDate.indexOf(value)
      if (ind >= 0) return `${ind + 1}`
      return '-'
    },
    groupBy (arr, prop) {
      const map = new Map(Array.from(arr, obj => [obj[prop], []]))
      arr.forEach(obj => map.get(obj[prop]).push(obj))
      return Array.from(map.values())
    },
    async getBookingList () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`${BOOKING}`,
          variables: {
            id: this.bookingId
          },
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.booking = data.data.booking
        })
        .catch((error) => {
          this.setLoadingOverlayHide()
          console.error(error)
        })
    },
    isDisabledCreatePaymentBillingButton () {
      if (!this.mutiplatePaymentMethods) {
        if (this.listCardSelected && this.getChargeIds.length > 0) {
          return false
        } else {
          return true
        }
      } else {
        if (this.getChargeIds.length > 0) {
          if (this.listCardSelectedMutiplate1) {
            if (this.amountMutiplate1 && this.amountMutiplate1 !== '') {
              if (this.listCardSelectedMutiplate2) {
                if (this.amountMutiplate2 && this.amountMutiplate2 !== '') {
                  if (this.listCardSelectedMutiplate3) {
                    if (this.amountMutiplate3 && this.amountMutiplate3 !== '') {
                      if (this.listCardSelectedMutiplate4) {
                        if (this.amountMutiplate4 && this.amountMutiplate4 !== '') {
                          return false
                        } else {
                          return true
                        }
                      } else {
                        return false
                      }
                    } else {
                      return true
                    }
                  } else {
                    return false
                  }
                } else {
                  return true
                }
              } else {
                return false
              }
            } else {
              return true
            }
          } else if (this.listCardSelectedMutiplate2) {
            if (this.amountMutiplate1 && this.amountMutiplate1 !== '') {
              if (this.listCardSelectedMutiplate3) {
                if (this.amountMutiplate3 && this.amountMutiplate3 !== '') {
                  if (this.listCardSelectedMutiplate4) {
                    if (this.amountMutiplate4 && this.amountMutiplate4 !== '') {
                      return false
                    } else {
                      return true
                    }
                  } else {
                    return false
                  }
                } else {
                  return true
                }
              } else {
                return false
              }
            } else {
              return true
            }
          } else if (this.listCardSelectedMutiplate3) {
            if (this.amountMutiplate1 && this.amountMutiplate1 !== '') {
              if (this.listCardSelectedMutiplate2) {
                if (this.amountMutiplate2 && this.amountMutiplate2 !== '') {
                  if (this.listCardSelectedMutiplate4) {
                    if (this.amountMutiplate4 && this.amountMutiplate4 !== '') {
                      return false
                    } else {
                      return true
                    }
                  } else {
                    return false
                  }
                } else {
                  return true
                }
              } else {
                return false
              }
            } else {
              return true
            }
          } else if (this.listCardSelectedMutiplate4) {
            if (this.amountMutiplate1 && this.amountMutiplate1 !== '') {
              if (this.listCardSelectedMutiplate2) {
                if (this.amountMutiplate2 && this.amountMutiplate2 !== '') {
                  if (this.listCardSelectedMutiplate3) {
                    if (this.amountMutiplate3 && this.amountMutiplate3 !== '') {
                      return false
                    } else {
                      return true
                    }
                  } else {
                    return false
                  }
                } else {
                  return true
                }
              } else {
                return false
              }
            } else {
              return true
            }
          } else {
            return true
          }
        } else {
          return true
        }
      }
    },
    async getListBillingStatement (reload = false) {
      this.setLoadingOverlayShow()
      await this.$apollo.queries.prePaymentInfo.refetch()
      this.setBillingStatementList({
        billingStatementList: [],
        billingStatementListPayment: [],
        totalByTaxRate: [],
        chargeInfo: {},
      })
      this.heigthDetailPayment = 0
      this.heigthDetailPaymentMethod = 0
      const variables = {
        bookingGroupId: this.bookingGroupId,
        bookingIds: this.showGroupCharges ? [] : [this.bookingId]
      }
      await this.$apollo.query({
        query: gql`${BILLING_STATEMENT_LIST}`,
        variables: variables,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        const charges = data.data.bookingGroupListChargesV2.charges
        this.charges = [...charges]
        for (let i = 0; i < this.arrDate.length; i++) {
          if (!charges.some(el => el.stayDate === this.arrDate[i])) {
            const item = {
              booking: {
                id: this.$route.query.id,
                room: {
                  name: ''
                }
              },
              bookingId: this.$route.query.id,
              bookingInvoiceId: null,
              externalPaymentMethodId: null,
              fpPoint: null,
              id: null,
              note: null,
              price: null,
              priceChangeAllowed: null,
              priceWithSalesTax: null,
              quantity: null,
              salesTax: null,
              showCreate: true,
              showEdit: false,
              spPoint: null,
              stayDate: this.arrDate[i],
              subPoint: null,
              subPrice: '',
              subject: '',
              taxIncluded: false,
              tvpPoint: null,
              type: {
                name: ''
              },
              typeId: null
            }
            charges.push(item)
          }
        }
        const dataTmp = charges
        if (dataTmp.length > 0) {
          // 合計:現金, SP
          dataTmp.forEach((item) => {
            if (item.price !== null) {
              item.showEdit = true
            } else {
              item.showEdit = false
            }
            item.subPrice = item.priceWithSalesTax * item.quantity
            item.subPoint = this.checkShowValueSP(item) * item.quantity
          })
          this.totalAllPrice = dataTmp.reduce((n, { subPrice }) => n + subPrice, 0)
          this.totalAllPoint = dataTmp.reduce((n, { subPoint }) => n + subPoint, 0)
          dataTmp.forEach((item) => {
            if (item.bookingInvoiceId) {
              data.data.bookingInvoiceList.forEach((pay, index) => {
                if (parseInt(item.bookingInvoiceId) === parseInt(pay.id)) {
                  item.bookingInvoiceFinalizedAt = pay.finalizedAt
                  item.indexPay = `請求書${index + 1}`
                }
              })
            }
          })

          const getSortKey = (charges) => {
            const stayDate = charges[0].stayDate
            if (this.arrDate.includes(stayDate)) {
              // show the charges within arrDate must be shown first
              // to do that add a prefix so the order is always before
              // charges outside of arrDate
              return `0-${stayDate}`
            } else {
              return `1-${stayDate}`
            }
          }
          const result = this.groupBy(dataTmp, 'stayDate')
          result.forEach((item) => {
            const itemLast = [...item].pop()
            itemLast.showCreate = true
          })
          result.sort((a, b) => {
            const keyA = getSortKey(a)
            const keyB = getSortKey(b)

            return keyA.localeCompare(keyB)
          })
          this.setBillingStatementList({
            billingStatementList: result,
            billingStatementListPayment: data.data.bookingInvoiceList,
            totalByTaxRate: data.data.bookingGroupListChargesV2.totalByTaxRate,
            chargeInfo: data.data.bookingGroupListChargesV2.chargeInfo,
          })
        }
        // height list billing detail
        this.$nextTick(() => {
          this.heigthDetailPayment = this.$refs.heigthDetailPayment ? this.$refs.heigthDetailPayment.clientHeight : 0
          this.heigthDetailPaymentMethod = this.$refs.heigthDetailPaymentMethod ? this.$refs.heigthDetailPaymentMethod.clientHeight : 0
        })
        this.setLoadingOverlayHide()
      }).catch(async (error) => {
        console.error(error)
        this.setLoadingOverlayHide()
        this.setCopyErrorText(this.getCopyErrorTextView(BILLING_STATEMENT_LIST, variables, error.graphQLErrors))
        const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      })
    },
    checkShowValueSP (billingDetailValueSP) {
      if (billingDetailValueSP) {
        if (billingDetailValueSP.spPoint !== null) {
          return `${billingDetailValueSP.spPoint}`
        }
        if (billingDetailValueSP.fpPoint !== null) {
          return `${billingDetailValueSP.fpPoint}`
        }
        if (billingDetailValueSP.tvpPoint !== null) {
          return `${billingDetailValueSP.tvpPoint}`
        }
      }
      return 0
    },
    async chargeRemarksInfo () {
      const data = await this.$gqlLoading({
        query: gql`${CHARGE_REMARKS}`,
        variables: {
          bookingGroupId: this.bookingGroupId
        }
      })

      if (data.data.bookingGroup.chargeRemarks.length > 0) {
        this.chargeRemarks = data.data.bookingGroup.chargeRemarks
      }
      this.createdSubMember = data.data.bookingGroup.createdSubMember
      this.paymentInstruction = {
        registerdCCSubjects: data.data.bookingGroup.registerdCCSubjects,
        onsiteSubjects: data.data.bookingGroup.onsiteSubjects,
        defaultPaymentType: data.data.bookingGroup.defaultPaymentType
      }
    },
    async createPaymentBilling () {
      if (this.getChargeIds.length > 0) {
        this.setLoadingOverlayShow()
        const arrPaymentMethods = []
        if (this.categoryPaymentMethodSelected === MULTIPLE_OTHER) {
          if (this.listCardSelectedMutiplate1 && this.amountMutiplate1) {
            arrPaymentMethods.push({
              paymentMethodId: parseInt(this.listCardSelectedMutiplate1),
              amount: parseInt(this.amountMutiplate1)
            })
          }
          if (this.listCardSelectedMutiplate2 && this.amountMutiplate2) {
            arrPaymentMethods.push({
              paymentMethodId: parseInt(this.listCardSelectedMutiplate2),
              amount: parseInt(this.amountMutiplate2)
            })
          }
          if (this.listCardSelectedMutiplate3 && this.amountMutiplate3) {
            arrPaymentMethods.push({
              paymentMethodId: parseInt(this.listCardSelectedMutiplate3),
              amount: parseInt(this.amountMutiplate3)
            })
          }
          if (this.listCardSelectedMutiplate4 && this.amountMutiplate4) {
            arrPaymentMethods.push({
              paymentMethodId: parseInt(this.listCardSelectedMutiplate4),
              amount: parseInt(this.amountMutiplate4)
            })
          }
        } else {
          arrPaymentMethods.push({
            paymentMethodId: parseInt(this.listCardSelected),
            amount: this.getChargePrice.reduce((n, { price }) => n + price, 0)
          })
        }
        const variables = {
          bookingGroupId: this.bookingGroupId,
          chargeIds: this.getChargeIds,
          payments: [...arrPaymentMethods]
        }
        await this.$apollo.mutate({
          mutation: gql`${CREATE_BOOKING_INVOICES}`,
          variables: variables,
          fetchPolicy: 'no-cache'
        }).then((data) => {
          if (data.data.createBookingInvoices && data.data.createBookingInvoices.id) {
            this.setChargeIdsDefault()
            data.data.createBookingInvoices.indexPayment = this.getBillingStatementListPayment.length + 1
            this.setCreateBookingInvoicesCurrent({ createBookingInvoicesCurrent: data.data.createBookingInvoices })
            this.getListBillingStatement()
            this.mutiplatePaymentMethods = false
            this.categoryPaymentMethodSelected = null
            this.listCardSelected = null
            this.listCardSelectedMutiplate1 = null
            this.listCardSelectedMutiplate2 = null
            this.listCardSelectedMutiplate3 = null
            this.listCardSelectedMutiplate4 = null
            this.amountMutiplate1 = null
            this.amountMutiplate2 = null
            this.amountMutiplate3 = null
            this.amountMutiplate4 = null
            // show modal preview billing
            this.pdfFileUrl = data.data.createBookingInvoices.pdfFileUrl
            this.email = data.data.createBookingInvoices.defaultEmailTo
            this.addresseeName = data.data.createBookingInvoices.addresseeName
            this.receiptFor = data.data.createBookingInvoices.receiptFor
            this.openDialogEditBillingPdf = true
            this.$nextTick(() => {
              this.$refs.updateBookingInvoices.resetValidation()
              this.$refs.finalizeBookingInvoices.resetValidation()
            })
            this.setLoadingOverlayHide()
          }
        }).catch(async (error) => {
          this.setLoadingOverlayHide()
          this.setCopyErrorText(this.getCopyErrorTextView(CREATE_BOOKING_INVOICES, variables, error.graphQLErrors))
          const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          } else {
            this.setAlertError(this.$t('messages.createError'))
          }
        })
      }
    },
    async finalizeBookingInvoices () {
      if (this.$refs.updateBookingInvoices.validate() && this.$refs.finalizeBookingInvoices.validate()) {
        this.$swal.fire(
          {
            title: '発行登録しますか？',
            showCancelButton: true,
            confirmButtonText: '発行登録',
            confirmButtonColor: '#ff5252',
            cancelButtonText: '閉じる',
            cancelButtonColor: '#1976d2',
            reverseButtons: true
          })
          .then(async (result) => {
          /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              await this.updateBookingInvoices(false)
              if (!(_.isEmpty(this.getCreateBookingInvoicesCurrent))) {
                this.setLoadingOverlayShow()
                const variables = {
                  bookingInvoiceId: parseInt(this.getCreateBookingInvoicesCurrent.id),
                  invoiceSendToEmails: this.email.split(',')
                }
                await this.$apollo.mutate({
                  mutation: gql`${FINALIZE_BOOKING_INVOICES}`,
                  variables: variables,
                  fetchPolicy: 'no-cache'
                }).then((data) => {
                  if (data.data.finalizeBookingInvoices && data.data.finalizeBookingInvoices.id) {
                    this.btnTextSendMail = '発行登録済'
                    this.sendMailDone = true
                    this.reloadBookingInvoices(true)
                    this.setAlertSuccess(this.$t('messages.successfulUpdate'))
                  }
                  this.setLoadingOverlayHide()
                }).catch(async (error) => {
                  this.setLoadingOverlayHide()
                  this.setCopyErrorText(this.getCopyErrorTextView(FINALIZE_BOOKING_INVOICES, variables, error.graphQLErrors))
                  const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
                  if (errorTmp) {
                    this.setAlertError(errorTmp, { root: true })
                  } else {
                    this.setAlertError(this.$t('messages.createError'))
                  }
                })
              }
            }
          })
      }
    },
    async deleteBookingInvoices (id) {
      const data = await this.$gqlLoading({
        query: gql`${DELETE_BOOKING_INVOICES}`,
        variables: {
          bookingInvoiceId: id
        },
        fetchPolicy: 'no-cache'
      })

      if (data.data.deleteBookingInvoices) {
        this.closeEditBillingPdf()
        this.setAlertSuccess(this.$t('messages.deletedSuccessfully'))
      }
    },
    askDeleteBookingInvoices () {
      if (!(_.isEmpty(this.getCreateBookingInvoicesCurrent))) {
        this.$swal.fire(
          {
            title: '削除しますか？',
            showCancelButton: true,
            confirmButtonText: '削除',
            confirmButtonColor: '#ff5252',
            cancelButtonText: '閉じる',
            cancelButtonColor: '#1976d2',
            reverseButtons: true
          })
          .then(async (result) => {
          /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              await this.deleteBookingInvoices(this.getCreateBookingInvoicesCurrent.id)
            }
          })
      }
    },
    inputAddressName (event) {
      this.addresseeName = event
    },
    inputReceiptFor (event) {
      this.receiptFor = event
    },
    async updateBookingInvoices (notice = true) {
      if (this.addresseeName === null) {
        this.addresseeName = ''
      }
      if (this.receiptFor === null) {
        this.receiptFor = ''
      }
      if (this.$refs.updateBookingInvoices.validate()) {
        if (!(_.isEmpty(this.getCreateBookingInvoicesCurrent))) {
          const variables = {
            bookingInvoiceId: parseInt(this.getCreateBookingInvoicesCurrent.id),
            addresseeName: this.addresseeName,
            receiptFor: this.receiptFor
          }
          this.setLoadingOverlayShow()
          await this.$apollo.mutate({
            mutation: gql`${UPDATE_BOOKING_INVOICES}`,
            variables: variables,
            fetchPolicy: 'no-cache'
          }).then((data) => {
            if (data.data.updateBookingInvoices && data.data.updateBookingInvoices.id) {
              // reload detail
              if (notice) {
                this.reloadBookingInvoices()
                this.setAlertSuccess(this.$t('messages.successfulUpdate'))
              }
            }
            this.setLoadingOverlayHide()
          }).catch(async (error) => {
            console.log('error', error)
            this.setLoadingOverlayHide()
            this.setCopyErrorText(this.getCopyErrorTextView(UPDATE_BOOKING_INVOICES, variables, error.graphQLErrors))
            const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
            if (errorTmp) {
              this.setAlertError(errorTmp, { root: true })
            } else {
              this.setAlertError(this.$t('messages.createError'))
            }
          })
        }
      }
    },
    async reloadBookingInvoices (reload = false) {
      this.setLoadingOverlayShow()
      this.$refs.updateBookingInvoices.resetValidation()
      const variables = {
        id: parseInt(this.getCreateBookingInvoicesCurrent.id)
      }
      await this.$apollo.mutate({
        mutation: gql`${DETAIL_BOOKING_INVOICES}`,
        variables: variables,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        if (data.data.bookingInvoice && data.data.bookingInvoice.id) {
          data.data.bookingInvoice.indexPayment = this.getCreateBookingInvoicesCurrent.indexPayment
          this.setCreateBookingInvoicesCurrent({ createBookingInvoicesCurrent: data.data.bookingInvoice })
          this.pdfFileUrl = data.data.bookingInvoice.pdfFileUrl
          this.addresseeName = data.data.bookingInvoice.addresseeName
          this.receiptFor = data.data.bookingInvoice.receiptFor
          this.$refs.updateBookingInvoices.resetValidation()
          if (!reload) {
            this.email = data.data.bookingInvoice.defaultEmailTo
          }
          if (reload) {
            this.$refs.finalizeBookingInvoices.resetValidation()
          }
        }
        this.setLoadingOverlayHide()
      }).catch(async (error) => {
        this.setLoadingOverlayHide()
        this.setCopyErrorText(this.getCopyErrorTextView(DETAIL_BOOKING_INVOICES, variables, error.graphQLErrors))
        const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        } else {
          this.setAlertError(this.$t('messages.createError'))
        }
      })
    },
    printJSPdf (url) {
      window.open(url, '', 'width=1024,height=700')
    },
    detailPayment (paymentCurrent, indexPayment = 1) {
      paymentCurrent.indexPayment = indexPayment
      this.setCreateBookingInvoicesCurrent({ createBookingInvoicesCurrent: paymentCurrent })
      this.pdfFileUrl = paymentCurrent.pdfFileUrl
      this.email = paymentCurrent.defaultEmailTo
      this.addresseeName = paymentCurrent.addresseeName
      this.receiptFor = paymentCurrent.receiptFor
      this.openDialogEditBillingPdf = true
      this.$nextTick(() => {
        this.$refs.updateBookingInvoices.resetValidation()
        this.$refs.finalizeBookingInvoices.resetValidation()
      })
    },
    closeEditBillingPdf () {
      // eslint-disable-next-line no-unused-expressions
      this.$refs.updateBookingInvoices?.resetValidation()
      // eslint-disable-next-line no-unused-expressions
      this.$refs.finalizeBookingInvoices?.resetValidation()
      this.openDialogEditBillingPdf = false
      this.email = ''
      this.disableEmail = true
      this.addresseeName = ''
      this.receiptFor = ''
      this.radioGroup = 1
      this.btnTextSendMail = '発行登録'
      this.sendMailDone = false
      this.setCreateBookingInvoicesCurrent({ createBookingInvoicesCurrent: {} })
      this.getListBillingStatement()
      this.setChargeIdsDefault()
    },
    async updatePrePayment (newPrePaymentAmount) {
      await this.$gqlLoading(BOOKING_PRE_PAYMENT_UPDATE, {
        prePayment: newPrePaymentAmount,
        bookingId: this.bookingId
      })
      await this.$apollo.queries.prePaymentInfo.refetch()
    },
    async editPayment (invoice, indexPlus1) {
      const dataToUpdate = {}
      dataToUpdate.categoryPaymentMethodSelected = MULTIPLE_OTHER
      for (let i = 0; i < invoice.payments.length; i++) {
        dataToUpdate[`listCardSelectedMutiplate${i + 1}`] = invoice.payments[i].bookingPaymentMethod.id
        dataToUpdate[`amountMutiplate${i + 1}`] = invoice.payments[i].paymentAmount
      }
      const writeDataAndDelInvoice = async () => {
        // TO2020-802: if we want to preserve the charges selected for the invoice,
        // we'd have to change the api to return the charges with their id, and
        // this.$store.commit('setChargeIdsDefault')
        // for (const charge of invoice.) {
        //   store.commit.setChargeIds({ chargeIds: charge.id })
        Object.assign(this, dataToUpdate)
        await this.deleteBookingInvoices(invoice.id)
      }

      // make sure the values to be update is empty
      const ask = Object.keys(dataToUpdate).some(key => this[key])

      if (ask) {
        this.$confirm({
          message: '現在入力中の支払い方法が上書きされます。\nまた、請求書が削除されます。よろしいですか？（この処理は取り消せません）',
          ok: writeDataAndDelInvoice
        })
      } else {
        this.$confirm({
          message: '請求書が削除されます。よろしいですか？（この処理は取り消せません）',
          ok: writeDataAndDelInvoice
        })
      }
    },
    easyPaymentPopulateClick (data) {
      this.resetPaymentForm()
      this.categoryPaymentMethodSelected = MULTIPLE_OTHER
      let index = 1
      const set = (paymentMethodId, amount) => {
        if (!amount) return
        this[`listCardSelectedMutiplate${index}`] = paymentMethodId
        this[`amountMutiplate${index}`] = amount
        index++
      }
      set(BookingPaymentMethodId.TVP, data.totalTvp)
      set(BookingPaymentMethodId.RegisteredCC, data.totalCc)
      set(this.isCompany ? BookingPaymentMethodId.Cash : null, data.totalCash)
    },
    resetPaymentForm () {
      this.categoryPaymentMethodSelected = null
      this.listCardSelectedMutiplate1 = null
      this.listCardSelectedMutiplate2 = null
      this.listCardSelectedMutiplate3 = null
      this.listCardSelectedMutiplate4 = null
      this.amountMutiplate1 = null
      this.amountMutiplate2 = null
      this.amountMutiplate3 = null
      this.amountMutiplate4 = null
    }
  },
  beforeDestroy () {
    this.setChargeIdsDefault()
  },
  apollo: {
    prePaymentInfo: {
      query: gql`${GET_PRE_PAYMENT_INFO}`,
      variables () {
        return {
          bookingId: this.bookingId
        }
      },
      skip () {
        return !this.bookingId || !this.canUseTvp
      },
      update: (data) => data.getPrePaymentInfo,
      fetchPolicy: 'no-cache'
    }
  }
}
