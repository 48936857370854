<template>
  <span>
    <span v-for="(line, ind) in lineParts" :key="ind" :class="line.cssClass">
      {{ line.text }}
    </span>
  </span>
</template>

<script>
export default {
  props: {
    line: String
  },
  computed: {
    lineParts () {
      if (!this.line) return []

      const split = this.line.split(/(登録カード|入湯税・宿泊税・温泉維持費|有料サービス)/g)

      return split.map((text, index) => {
        return {
          text,
          cssClass: index % 2 ? 'text--red' : ''
        }
      }).filter(t => !!t.text)
    }
  }
}
</script>

<style>

</style>
