<template>
  <div v-if="paymentInstruction" class="container">
    <div class="font-weight-bold">請求明細内訳 未作成分</div>
    <div v-if="showCc" class="summary-row">
      <div class="label">・登録カード</div>
      <div class="value">¥{{ paymentTotalPerMethod.totalCc | toThousands }}</div>
    </div>
    <div v-if="prePaymentInfo && prePaymentInfo.prepaymentAmount > 0" class="summary-row">
      <div class="label">・利用TVP</div>
      <div class="value">{{ prePaymentInfo.remainingPoint | toThousands }}ポイント</div>
    </div>
    <div v-if="showOnsite" class="summary-row">
      <div class="label">・現地ご請求金額</div>
      <div class="value">¥{{ paymentTotalPerMethod.totalOnsite | toThousands }}</div>
    </div>
  </div>
  <div v-else>.</div>
</template>

<script>
import WithPaymentInstructionMixin from './WithPaymentInstructionMixin'

export default {
  mixins: [WithPaymentInstructionMixin]
}
</script>

<style lang="scss" scoped>
.container {
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.1);
}
.label {
  font-weight: bold;
  width: 130px;
}
.item {
  margin-bottom: 16px;
  width: 250px;
  text-align: left;
}
.summary-row {
  display: flex;
  margin-top: 10px;
}
</style>
