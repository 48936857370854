<template>
  <v-card class="px-5 px-sm-10 pt-5 py-10" :key="componentKey">
    <v-card-text>
      <v-row class="d-flex align-center justify-end">
        <v-btn
          color="var(--bt__red)"
          class="mr-3 d-flex align-center btn_status white--text"
          href="javascript:window.open('','_self').close();"
        >
          <v-icon class="white--text"> mdi-close </v-icon>
          {{ $t("common.close") }}
        </v-btn>
        <v-btn
          class="white--text"
          color="var(--bt__blue)"
          @click="actionUpdate()"
          :disabled="!checkPerUser"
        >
          {{ $t("common.save") }}
        </v-btn>
      </v-row>
      <v-row class="d-flex align-center justify-end">
        <v-btn
          class="mt-10 btn-add-parking"
          style="border: solid 3px; color: #4683d5"
          @click="createNewParking()"
          :disabled="!checkPerUser"
        >
          <v-icon> mdi-plus </v-icon>
          <p class="custom-add-value mt-3">
            {{ $t("calendar.parkingCars.addParkingReservation") }}
          </p>
        </v-btn>
      </v-row>
    </v-card-text>
    <v-card-text class="mt-4">
      <v-data-table
        disable-sort
        :headers="header"
        :items="arrNew"
        hide-default-footer
        disable-pagination
        class="table-cus-th"
      >
        <template v-slot:[`item.period`]="{ item }">
          <div class="ml-4 d-flex align-center cus-picker">
            <v-menu
              :ref="'menu' + item.id"
              :value="item.showDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex align-center">
                  <v-icon
                    class="ml-n8 mr-2"
                    style="
                      color: #616161 !important;
                      font-size: 30px !important;
                    "
                    >mdi-calendar-month</v-icon
                  >
                  <v-text-field
                    :value="formatDate(item.dates)"
                    @click.stop="item.showDate = true"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    dense
                    outlined
                    class="mt-5 custom-input"
                    :disabled="!checkPerUser || !!item.notifiedAt"
                  ></v-text-field>
                </div>
              </template>
              <v-date-picker
                @input="changeDate($event, item.id, item)"
                no-title
                scrollable
                :value="item.dates"
                range
                :first-day-of-week="0"
                :locale="$i18n.locale"
                class="v-date-picker-custom"
              >
                <v-spacer></v-spacer>
                <v-btn
                  color="var(--bt__blue)"
                  class="white--text"
                  @click="$refs['menu' + item.id].save(item.dates)"
                  :disabled="item.dates.length < 2"
                >
                  {{ $t("buttons.save") }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </template>

        <template v-slot:[`item.parkingLot`]="{ item }">
          <v-select
            @change="changeFacilityParking($event, item)"
            v-model="item.facilityParking.id"
            dense
            hide-details
            item-text="name"
            item-value="id"
            outlined
            :items="listNameParking"
            class="text-size-normal custom-select"
            :disabled="!checkPerUser || !!item.notifiedAt"
          >
          </v-select>
        </template>
        <template v-slot:[`item.vehicleHeight`]="{ item }">
          <v-text-field
            :readonly="item.isCancelWait"
            @change="changeHeight($event, item)"
            outlined
            @keypress="isNumber($event)"
            class="text--black text--small"
            :value="item.height ? item.height : '指定無し'"
          ></v-text-field>
        </template>
        <template v-slot:[`item.carNumber`]="{ item }">
          <v-text-field
            :readonly="item.isCancelWait"
            @change="changeCarNumber($event, item)"
            outlined
            class="text--black text--small"
            :value="item.carNumber ? item.carNumber : '指定無し'"
          ></v-text-field>
        </template>
        <template v-slot:[`item.color`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                :color="item.color || 'white'"
                v-on="item.isCancelWait ? {} : on"
                :disabled="!checkPerUser"
                class="bordered"
                v-text="item.color ? '' : '未選択'"
              />
            </template>
            <car-color-picker
              @input="changeColor($event, item)"
              :value="item.color || '#FEFEFE'"
              class="mx-auto"
              :disabled="!checkPerUser"
            />
          </v-menu>
        </template>
        <!-- <template v-slot:[`item.status`]="{ item }">
          <span class="red--text">{{ item.notifiedAt ? item.notifiedAt : 'キャンセル待ち通知' }}</span>
        </template> -->
        <template v-slot:[`item.status`]="{ item }">
          <span class="red--text" v-if="item.isCancelWait">{{
           item.notifiedAt ? 'キャンセル待ち通知済み' : 'キャンセル待ち'
          }}</span>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            color="#ffffff"
            @click="(dialog = true), (idDel = item.id)"
            :disabled="!checkPerUser"
          >
            <span style="color: #ff0000; font-size: 16px">{{
              $t("buttons.delete")
            }}</span>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <AddParkingReservation
      :key="idx"
      :visible="addParkingVisible"
      :checkInDate="checkInDate"
      :checkOutDate="checkOutDate"
      :listNameParking="listNameParking"
      @close="addParkingVisible = false"
    />
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5"> 削除しますか？ </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="var(--bt__red)"
              class="mr-3 d-flex align-center btn_status white--text"
              @click="dialog = false"
            >
              いいえ
            </v-btn>
            <v-btn
              class="white--text"
              color="var(--bt__blue)"
              :disabled="!checkPerUser"
              @click="checkPerUser ? (del(), (dialog = false)) : ''"
            >
              はい
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import AddParkingReservation from '../calendar/carPark/addParkingReservation'
import {
  BOOKING
} from '@/api/graphql/bookingDetail/booking-detail-parking'
import { FACILITY_PARKING_LIST } from '@/api/graphql/facility-item-parking'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import gql from 'graphql-tag'
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import CarColorPicker from '../../../components/Input/CarColorPicker.vue'

export default {
  name: 'ParkingPlace',
  data () {
    return {
      componentKey: 0,
      arrUpdateParkingReservation: [],
      arrUpdateFacilityParkingCancelWaitList: [],
      idx: 0,
      idDel: null,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      checkInDate: '',
      checkOutDate: '',
      dialog: false,
      arrNew: [],
      facilityId: null,
      listNameParking: [],
      listItemParking: [],
      addParkingVisible: false,
      parkingLotList: [],
      selectParkingLotList: [],
      selectValue: [],
      header: [
        {
          text: this.$t('calendar.period'),
          value: 'period',
          align: 'center',
          width: '300px'
        },
        {
          text: this.$t('calendar.parkingCars.parkingLot'),
          value: 'parkingLot',
          width: '200px',
          align: 'center'
        },
        {
          text: '車高',
          value: 'vehicleHeight',
          align: 'center',
          width: '150px'
        },
        {
          text: this.$t('calendar.parkingCars.carNumber'),
          value: 'carNumber',
          align: 'center',
          width: '150px'
        },
        {
          text: this.$t('calendar.parkingCars.carColor'),
          value: 'color',
          align: 'center',
          width: '200px'
        },
        {
          text: this.$t('calendar.parkingCars.status'),
          align: 'center',
          value: 'status'
        },
        {
          align: 'center',
          value: 'action'
        }
      ]
    }
  },

  watch: {
    getStatusParking () {
      this.getRentalitemList()
    },

    getReloadDataParkingPlace () {
      this.componentKey += 1
    }
  },

  computed: {
    ...mapGetters(['getStatusParking', 'getReloadDataParkingPlace'])
  },

  mounted () {
    this.getParkingLotList()
    this.getRentalitemList()
  },

  methods: {
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
      'setAlertSuccess',
      'setLoadingOverlayHide',
      'setLoadingOverlayShow',
      'setParkingReservationBulk'
    ]),
    ...mapActions(['updateParkingReservationBulk']),
    isNumber: function (evt) {
      evt = evt || window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (
        (charCode > 44 &&
          charCode < 58 &&
          charCode !== 47 &&
          charCode !== 46 &&
          charCode !== 45) ||
        charCode === 13
      ) {
        return true
      } else {
        evt.preventDefault()
      }
    },
    del () {
      this.setLoadingOverlayShow()
      const query = `
            mutation (
              $id: Int!
            ) {
              cancelParkingReservation(facilityParkingReservationId: $id) {
                __typename
              }
            }
          `
      const variables = {
        id: parseInt(this.idDel)
      }
      this.$apollo
        .mutate({
          mutation: gql`${query}`,
          variables: variables
        })
        .then((data) => {
          this.setLoadingOverlayHide()
          this.getRentalitemList()
          this.setAlertSuccess(this.$t('messages.deletedSuccessfully'))
        })
        .catch(async (error) => {
          this.setLoadingOverlayHide()
          this.setCopyErrorText(this.getCopyErrorTextView(query, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser
          )
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        })
    },
    changeDate (event, item, items) {
      const arrNew = [...this.arrNew]
      const index = arrNew.findIndex((e) => e.id === item)
      if (index !== -1) {
        if (event.length === 2) {
          const arr = []
          const date1 = new Date(event[0])
          const date2 = new Date(event[1])
          const date3 = date2.getTime() - date1.getTime()
          if (date3 > 0) {
            arr.push(date1.toISOString().substr(0, 10))
            arr.push(date2.toISOString().substr(0, 10))
          } else {
            arr.push(date2.toISOString().substr(0, 10))
            arr.push(date1.toISOString().substr(0, 10))
          }
          items.fromDate = arr[0]
          items.toDate = arr[1]
          this.addOrReplaceParking(items)
        }
        arrNew[index].dates = event
      }
      this.arrNew = arrNew
    },

    addOrReplaceParking (item) {
      if (!item.isCancelWait) {
        const data = {
          facilityParkingReservationId: item.id,
          fromDate: item.fromDate,
          toDate: item.toDate,
          facilityParkingId: item.facilityParking.id,
          height: item.height,
          carNumber: item.carNumber,
          color: item.color
        }
        const ind = this.arrUpdateParkingReservation.findIndex(r => r.facilityParkingReservationId === data.facilityParkingReservationId)
        if (ind >= 0) {
          // replace
          this.arrUpdateParkingReservation.splice(ind, 1, data)
        } else {
          this.arrUpdateParkingReservation.push(data)
        }
      } else {
        const data = {
          facilityParkingWaitlistId: item.id,
          data: {
            fromDate: item.dates[0],
            toDate: item.dates[1],
            facilityParkingId: item.facilityParking.id
          }
        }

        const ind = this.arrUpdateFacilityParkingCancelWaitList.findIndex(r => r.facilityParkingWaitlistId === data.facilityParkingWaitlistId)
        if (ind >= 0) {
          // replace
          this.arrUpdateFacilityParkingCancelWaitList.splice(ind, 1, data)
        } else {
          this.arrUpdateFacilityParkingCancelWaitList.push(data)
        }
      }
    },
    changeFacilityParking (event, item) {
      this.addOrReplaceParking(item)
    },

    changeHeight (event, item) {
      item.height = parseInt(event)
      this.addOrReplaceParking(item)
    },

    changeCarNumber (event, item) {
      item.carNumber = event
      this.addOrReplaceParking(item)
    },

    changeColor (event, item) {
      item.color = event
      this.addOrReplaceParking(item)
    },

    actionUpdate () {
      const arrUpdateReservation = this.arrUpdateParkingReservation
      const BWait = this.arrUpdateFacilityParkingCancelWaitList
      const parkingReservationBulk = {
        reservations: arrUpdateReservation,
        waitlists: BWait
      }

      this.setParkingReservationBulk({
        parkingReservationBulk: parkingReservationBulk
      })
      this.updateParkingReservationBulk()
    },

    async getRentalitemList () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`${BOOKING}`,
          variables: {
            id: parseInt(this.$route.query.id)
          },
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.setLoadingOverlayHide()
          this.checkInDate = data.data.booking.checkInDate
          this.checkOutDate = data.data.booking.checkOutDate
          this.facilityId = data.data.booking.room.roomType.facility.id
          const arrBooking = data.data.booking.parkings
          const arrCancelWait =
            data.data.facilityParkingCancelWaitlistList.items

          const arrBookingNew = []
          for (let j = 0; j < arrBooking.length; j++) {
            arrBooking[j].notifiedAt = null
            arrBookingNew.push(arrBooking[j])
          }

          this.listItemParking = arrCancelWait.map(wait => ({
            ...wait,
            isCancelWait: true
          })).concat(arrBookingNew)

          const arrayId = []
          const B = []
          for (let i = 0; i < this.listItemParking.length; i++) {
            if (!arrayId.includes(this.listItemParking[i].id)) {
              arrayId.push(this.listItemParking[i].id)
              B.push(this.listItemParking[i])
            }
          }
          this.getListNameParking()
          this.formatData(B)
        })
        .catch((error) => {
          this.setLoadingOverlayHide()
          console.error(error)
        })
    },

    async getListNameParking () {
      await this.$apollo
        .query({
          query: gql`${FACILITY_PARKING_LIST}`,
          variables: {
            facilityId: parseInt(this.facilityId),
            hideQtyZero: true
          }
        })
        .then((data) => {
          this.listNameParking = data.data.facilityParkingList
        })
        .catch((error) => {
          console.error(error)
        })
    },

    formatDate (date) {
      const dates = date.join(' ~ ')
      return dates.replace(/-/g, '/')
    },

    formatData (arr) {
      this.arrNew = []
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i]
        const arrDate = []
        arrDate.push(arr[i].fromDate)
        arrDate.push(arr[i].toDate)
        item.dates = arrDate
        this.arrNew.push(item)
      }
    },

    createNewParking () {
      this.addParkingVisible = true
      this.idx = this.idx + 1
    },

    getParkingLotList () {
      for (let i = 0; i < 3; i++) {
        this.parkingLotList.push({
          id: i,
          showDate: false,
          vehicleHeight: '指定無し',
          carNumber: '1234',
          color: '#E0E0E0',
          status: i > 1 ? 'キャンセル待ち通知' : '',
          parkingLot: i === 0 ? '機械式1' : i === 1 ? '機械式2' : '機械式3',
          dates: ['2019-09-10', '2019-09-20']
        })
      }
    }
  },
  components: {
    AddParkingReservation,
    CarColorPicker
  }
}
</script>

<style lang="scss" scoped>
.custom-add-value {
  font-size: 18px;
  color: #3083dc;
}
.btn-add-parking {
  border: var(--text__primary) 2px solid;
  background-color: var(--bt__while) !important;
  color: var(--text__primary);
}
.mdi-plus::before {
  color: #3083dc !important;
  font-size: 40px;
  font-weight: bold;
}
.text-size-normal::v-deep input {
  font-size: 14px;
}

.bordered {
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
}

::v-deep {
  .cus-picker {
    .v-input__slot {
      min-width: 190px !important;
    }
  }
  .text--black {
    .v-input__control {
      height: 40px !important;
      fieldset {
        height: 46px !important;
      }
      input {
        margin-bottom: 15px !important;
        text-align: center;
      }
    }
  }
  .v-input__slot {
    max-width: 170px !important;
  }
  .v-data-table {
    thead th {
      font-weight: normal;
      color: #666666 !important;
      font-size: 16px !important;
    }
    tbody tr td {
      font-size: 16px !important;
      color: #666666 !important;
    }
  }
  svg {
    color: #000000 !important;
    width: 30px;
    height: 30px;
  }
  .custom-text-file input {
    font-size: 18px !important;
    color: #666666;
  }
  .custom-select .v-select__selection--comma {
    font-size: 16px !important;
    color: #666666;
  }
  .custom-text-file .v-input__slot {
    max-width: 250px !important;
  }
  .custom-btn .v-btn__content {
    width: 60px !important;
    color: #666666;
    font-size: 16px;
  }
  .custom-number-car .v-btn__content {
    text-transform: none;
    color: #666666;
    font-size: 16px;
    max-width: 200px !important;
    width: 100px !important;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;

    overflow: auto;
  }
}
</style>
