<template>
  <div>
    <v-dialog :value="true" persistent width="950">
      <v-card class="pa-2">
        <v-card-title>
          <p class="custom-title mb-0">チェックイン・アウト日変更</p>
        </v-card-title>
        <v-card-text class="mt-4 pr-1">
          <div class="d-flex align-center">
            <v-text-field label="チェックイン" readonly
              :value="checkInDate ? formatDate(checkInDate) : '選択してください'"
              @click="showDialog = true"
            />
            <div class="mx-3">〜</div>
            <v-text-field label="チェックアウト" readonly
              :value="checkOutDate ? formatDate(checkOutDate) : '選択してください'"
              @click="showDialog = true"
            />
          </div>

          <v-form ref="form">
            <p class="title-calendar ml-5 mb-0">処理日</p>
            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
              offset-y min-width="auto">
              <template v-slot:activator="{ on }">
                <div>
                  <v-icon class="icon-calendar">mdi-calendar-month</v-icon>
                  <v-btn outlined v-model="cancelDate" v-on="on" small color="#888888"
                    class="bg--btn-white mr-5 datePicker">{{ formatDate(cancelDate) }}</v-btn>
                </div>
              </template>
              <v-date-picker v-model="cancelDate" :first-day-of-week="0" :locale="$i18n.locale" scrollable
                @input="menu = false" class="v-date-picker-custom"></v-date-picker>
            </v-menu>
            <v-col cols="6" class="pl-0">
              <v-select v-if="statusShowSelect" v-model="typeCancel" :items="['連絡なしノーショー', '通常キャンセル']"
                :label="$t('contractDetail.selectCancellationOnTheDay')" color="#C92D2D" />
            </v-col>
            <v-form ref="formCancelBooking">
              <div class="d-flex" v-for="(item, index) in cancelPenalties" :key="index">
                <template>
                  <v-col cols="3" class="pl-0">
                    <div class="title-list-day ">{{ index + 1 }}日目</div>
                    <div class="list-day">{{ formatDate(item.stayDate) }}</div>
                  </v-col>
                  <v-col cols="3" class="pl-0 text-right">
                    <div class="title-list-day">{{ $t('contractDetail.settingCancellationPolicy') }}</div>
                    <div class="value-rate">{{ formatPercent(item.originalPenaltyRate) }}</div>
                  </v-col>
                  <v-col cols="2">
                    <tvos-int-input :label="$t('contractDetail.cancellationRate')" color="#C92D2D"
                      :value="item.penaltyRate * 100" @input="$set(item, 'penaltyRate', $event / 100)" required prefix="%"
                      reverse :max="100">
                    </tvos-int-input>
                  </v-col>
                  <v-col cols="4">
                    <cancel-penalty-data :item="item" :cancelDate="cancelDate" />
                  </v-col>
                </template>
              </div>

              <div v-if="newPriceByDay.length">
                <div class="new-price-row header">
                  <div class="stay-date">宿泊日</div>
                  <div class="accommodation">日数追加による追加{{ hasAnyPointCharge ? 'ポイント' : '料金' }}</div>
                  <div class="fee" v-if="showChargeDetail">その他費用内訳</div>
                </div>
                <div class="new-price-row" v-for="(item, index) in newPriceByDay" :key="index">
                  <div class="stay-date">{{ formatDate(item.stayDate) }}</div>
                  <div class="accommodation">
                    {{ item.totalAccommodationPrice.toLocaleString() }}{{ hasAnyPointCharge ? 'ポイント' : '円' }}
                  </div>
                  <div class="fee" v-if="showChargeDetail">
                    <FormatSpan :value="`${getChargeDetails(item)}`" />
                  </div>
                </div>
              </div>
            </v-form>
          </v-form>
        </v-card-text>
        <v-card-text class="d-flex justify-end align-center pr-4">
          <v-btn color="var(--bt__red)" class="mr-3 d-flex align-center btn_status white--text" @click="closeDialog">
            <v-icon class="white--text"> mdi-close </v-icon>
            {{ $t('common.close') }}
          </v-btn>
          <v-btn class="white--text" color="var(--bt__blue)" @click="moveBooking()" :disabled="!canMove">
            チェックイン・アウト日変更して明細に移動
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <SelectFrameDialog v-if="showDialog"
      :showDialog="showDialog === true"
      :bookingId="bookingId"
      :selectedInventories="newStayDates"
      @close="showDialog = false"
      @setNewStayDates="setNewStayDates" />
  </div>
</template>

<script>
import { formatDate } from '@/constants/functions'
import gql from 'graphql-tag'
import { isoDate, addDays, getStayDateRangeArray } from '@/utils/dateUtil'
import CancelPenaltyData from './cancelPenaltyData.vue'
import SelectFrameDialog from './selectFrameDialog.vue'
import { BookingTypeIds } from '../../../constants/bookingType'
import FormatSpan from '@/components/shared/FormatSpan.vue'

export default {
  name: 'SettingMoveDialog',
  components: { CancelPenaltyData, SelectFrameDialog, FormatSpan },
  props: {
    bookingId: Number,
    bookingTypeId: Number,
    oldCheckInDate: String,
    oldCheckOutDate: String,
  },
  data () {
    return {
      showDialog: false,
      cancelPenalties: [],
      secondaryConfirmer: '今津康平',
      menu: false,
      cancelDate: isoDate(new Date()),
      typeCancel: '通常キャンセル',
      newStayDates: [],
      cancelDays: [],
      priceByDay: [],
    }
  },

  computed: {
    checkInDate() {
      return this.newStayDates[0]?.stayDate
    },
    checkOutDate() {
      if (this.checkInDate) {
        const lastDate = this.newStayDates[this.newStayDates.length - 1]
        return addDays(lastDate.stayDate, 1)
      }
      return undefined
    },
    showChargeDetail () {
      // only DP booking type needs to show charge detail
      return this.bookingTypeId === BookingTypeIds.DP
    },

    statusShowSelect () {
      if (!this.cancelPenalties || this.cancelPenalties.length === 0) return false
      const day1 = new Date()
      const day2 = new Date(this.cancelPenalties[0].stayDate)

      const difference = day1.getTime() - day2.getTime()
      return difference >= 0
    },

    oldStayDates () {
      return getStayDateRangeArray(this.oldCheckInDate, this.oldCheckOutDate)
    },

    newPriceByDay() {
      // return the priceByDay that are not in the old stay dates
      return this.priceByDay.filter(day => !this.oldStayDates.includes(day.stayDate))
        .map(day => {
          return {
            ...day,
            totalAccommodationPrice: day.accommodationPrice + day.bookingPlanFee + day.accommodationPriceSp + day.accommodationPriceFp,
            totalPoint: day.accommodationPriceSp + day.accommodationPriceFp,
          }
        })
    },

    hasAnyPointCharge() {
      return this.newPriceByDay.some(day => day.totalPoint > 0)
    },

    canMove () {
      return this.newStayDates.length > 0 && (
        this.checkInDate !== this.oldCheckInDate ||
        this.checkOutDate !== this.oldCheckOutDate
      )
    }
  },

  watch: {
    newStayDates () {
      this.calculateMovePrice()
    },
    cancelDate () {
      this.calculateMovePrice()
    },
  },

  mounted() {
    this.showDialog = true
  },

  methods: {
    formatDate,
    formatPercent (item) {
      return item * 100 + '%'
    },
    setNewStayDates ({ selectedInventories }) {
      this.newStayDates = [...selectedInventories].sort();
      this.showDialog = false
    },
    async calculateMovePrice () {
      const noShow = this.typeCancel !== '通常キャンセル'
      try {
        const result = await this.$gqlLoading({
          query: gql`query checkPriceForBookingMove (
            $bookingId: Int!
            $days: [BookingMoveDayInput!]!
            $cancelDate: DateString!
            $noShow: Boolean!
          ) {
            checkPriceForBookingMove (
              bookingId: $bookingId
              days: $days
              cancelDate: $cancelDate
              noShow: $noShow
            ) {
              days {
                stayDate
                accommodationPrice
                bookingPlanFee
                accommodationPriceSp
                accommodationPriceFp
                fees {
                  priceWithSalesTax
                  subject
                }
              }

              cancelPenalties {
                stayDate
                price
                spPoint
                fpPoint
                tvpPoint
                penaltyRate
                penaltyPrice
                penaltySpPoint
                penaltyFpPoint
                penaltyTvpPoint
              }
            }
          }`,
          variables: {
            bookingId: this.bookingId,
            days: this.newStayDates,
            cancelDate: this.cancelDate,
            noShow
          },
          fetchPolicy: 'no-cache'
        });
        this.cancelPenalties = result.data.checkPriceForBookingMove.cancelPenalties.map(p => {
          return {
            ...p,
            originalPenaltyRate: p.penaltyRate
          }
        })
        this.priceByDay = result.data.checkPriceForBookingMove.days
      } catch (error) {
        console.error(error)
      }
    },
    async moveBooking () {
      if (this.$refs.formCancelBooking.validate()) {
        const penaltyRates = []
        for (let i = 0; i < this.cancelPenalties.length; i++) {
          const item = {
            penaltyRate: this.cancelPenalties[i].penaltyRate,
            stayDate: this.cancelPenalties[i].stayDate
          }
          penaltyRates.push(item)
        }
        console.log('moveBooking v', {
          bookingId: this.bookingId,
          newStayDates: this.newStayDates,
          penaltyRates
        })
        const result = await this.$gqlLoading({
          query: gql`mutation moveBooking (
            $bookingId: Int!
            $newStayDates: [BookingMoveDayInput!]!
            $penaltyRates: [CancelPenaltyRateInput!]!
          ) {
            moveBooking (
              bookingId: $bookingId
              days: $newStayDates
              penalties: $penaltyRates
            ) {
              needsTvpAdjustment
            }
          }`,
          variables: {
            bookingId: this.bookingId,
            newStayDates: this.newStayDates,
            penaltyRates
          }
        })
        if (result.data.moveBooking.needsTvpAdjustment) {
          this.$store.dispatch('tvpPrePaymentConfirm')
        } else {
          this.$router.push({
            path: '/reservation/contract-detail',
            query: {
              id: this.bookingId,
              tab: 'billing-statement'
            }
          })
        }
      }
    },
    closeDialog () {
      this.$emit('close')
    },
    getChargeDetails (row) {
      if (row.fees.length === 0) return 'なし';

      return row.fees.map(fee => {
        return `${fee.subject}: ${fee.priceWithSalesTax.toLocaleString()}円`
      }).join('\n')
    }
  }
}
</script>

<style scoped lang="scss">
.custom-title {
  font-size: 20px;
  color: #000000 ;
  font-weight: bold !important;
}
.icon-calendar {
  color: #333333
}
.title-calendar {
  font-size: 10px;
  color: #000000
}
.title-list-day {
  font-size: 10px;
  color: #212121
}
.list-day {
  font-size: 14px;
  color:#212121 !important;
}
.value-rate {
  font-size: 14px;
  color: #212121;
}
::v-deep {
  .v-select__slot label {
    color: #C92D2D;
    font-size: 10px;
  }
  .v-select__slot input {
    color: #C92D2D;
    font-size: 10px;
  }
  .v-select__selection--comma {
    color: #C92D2D;
  }
  .datePicker .v-btn__content {
    color: #000000
  }
  .v-input {
    font-size: 10px !important;
  }
  .error--text .v-messages__message {
    font-size: 12px;
  }
}

.new-price-row {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  &.header {
    font-weight: bold;
    font-size: 1rem;
  }
  & .stay-date {
    width: 180px;
    text-align: center;
  }
  & .accommodation {
    width: 300px;
    text-align: center;
    justify-content: center;
    display: flex;
    gap: 10px;
  }
  & .fee {
    flex-grow: 1;
    display: flex;
    text-align: left;
  }
}
</style>
