<template>
  <div class="clearfix">
    <div class="tax-details">
      <div v-for="detail in taxDetails" :key="detail[0]" class="tax-detail">
        <div class="tax-rate">{{ detail[0] }}：</div>
        <div class="tax-amount">{{ detail[1] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { percent, toThousands } from '@/utils/filters'

export default {
  props: {
    totalByTaxRate: Array
  },
  computed: {
    taxDetails () {
      const taxDetails = this.totalByTaxRate
        .slice()
        .sort((a, b) => b.taxRate - a.taxRate)
        .map(tax => {
          const exempt = tax.taxRate === 0
          const name = exempt ? '非' : percent(tax.taxRate)
          return [`${name}課税対象`, `¥${toThousands(tax.total)} ${exempt ? '' : `（内税 ¥${toThousands(tax.salesTax)}）`}`]
        })
      return taxDetails
    }
  }
}
</script>

<style lang="scss" scoped>
.tax-detail {
  display: flex;
  align-items: center;

  & .tax-rate {
    width: 120px;
    text-align: right;
  }
}

.tax-details {
  float: right;
}

</style>
