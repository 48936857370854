<template>
  <BaseDialog
    :visible="visible"
    @close="emitClose">
    <template v-slot:content>
      <div class="text--large font-weight-bold item">利用可能ポイント数：{{ currentTvpPlusRegisteredAmount | toThousands }} Pt</div>
      <div class="item">
        <div class="label">ポイント利用</div>
        <div>
          <tvos-int-input v-model="localPoint" :min="0" :max="maxUsePoint" dense required @update:error="localPointError = $event"/>
        </div>
      </div>
      <div class="item">
        <div class="label">宿泊料金・キャンセル料</div>
        <div class="text--prm">¥{{ targetChargesTotal - (localPoint || 0) | toThousands}}</div>
      </div>

      <div class="item">
        <div class="label">残ポイント数</div>
        <div class="text--prm">{{ currentTvpPlusRegisteredAmount - (localPoint || 0) | toThousands}} Pt</div>
      </div>

      <div class="text-right">
        <v-btn color="primary" @click="update" outlined :disabled="localPointError">反映</v-btn>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'

export default {
  components: {
    BaseDialog
  },
  props: {
    visible: Boolean,
    currentTvpPlusRegisteredAmount: Number,
    maxUsePoint: Number,
    targetChargesTotal: Number,
    currentPrePayment: Number
  },
  data () {
    return {
      localPoint: null,
      localPointError: false
    }
  },
  watch: {
    visible (v) {
      if (v) {
        // reset the value
        this.localPoint = this.currentPrePayment
      }
    }
  },
  methods: {
    async update () {
      if (this.currentPrePayment === this.localPoint) {
        // not updated.
      } else {
        this.$emit('changed', this.localPoint)
      }
      this.emitClose()
    },
    emitClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  font-weight: bold;
}
.item {
  margin-bottom: 16px;
}
</style>
