<template>
  <div class="bg--silver pa-3 text-right fz-14">
    ご利用金額： <tvos-jpy :value="totalJpy" />
    <span class="ml-2" v-if="totalPoint > 0">{{ pointUnit }}: {{ totalPoint | toThousands }}pt</span>
  </div>
</template>

<script>
import { pointPrice, getPriceUnit } from '@/utils/billing'

export default {
  props: {
    billings: Array
  },
  computed: {
    allRows () {
      return this.billings.reduce((arr, forDay) => {
        return arr.concat(forDay)
      }, [])
    },
    totalJpy () {
      return this.allRows.reduce((sum, row) => {
        if (row.priceWithSalesTax) {
          return sum + row.priceWithSalesTax * row.quantity
        }
        return sum
      }, 0)
    },
    totalPoint () {
      return this.allRows.reduce((sum, row) => {
        const point = pointPrice(row)
        if (point) {
          return sum + point * row.quantity
        }
        return sum
      }, 0)
    },
    pointUnit () {
      let pointUnit = ''
      for (const row of this.allRows) {
        const point = pointPrice(row)
        if (point) {
          pointUnit = getPriceUnit(row)
        }
      }
      return pointUnit
    }
  }
}
</script>
