<template>
  <v-select
    :value="value"
    :disabled="disabled"
    :items="items"
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
    item-text="value"
    item-value="key"
    placeholder="性別"
    hide-details
    v-bind="$attrs"
    :class="{ dense }"
  />
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    value: String,
    dense: Boolean
  },
  computed: {
    items () {
      return [
        { value: '男性', key: 'Male' },
        { value: '女性', key: 'Female' },
        { value: 'その他', key: 'Other' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.dense {
  max-width: 150px;
}
</style>
