<template>
  <div>
    <p class="title-head mb-2">{{ $t("common.group") }}</p>
    <v-card class="pa-7" color="var(--bg__secondary)" elevation="5">
      <div class="px-2 pb-2 custom-table-text">
        <template v-if="isMw">
          MWグループ
        </template>

        <template v-else>
          {{ $t("common.group") }}ID:
          {{ booking.bookingGroup ? booking.bookingGroup.id : "" }}
        </template>
      </div>
      <hr />
      <template v-if="booking.bookingGroup">
        <div class="overflow-x-auto pt-1">
          <div
            class="
              d-flex
              custom-table-text
              justify-space-between
              align-center
              mb-1
            "
            v-for="(item, index) in booking.bookingGroup.bookings"
            :key="index"
          >
            <span class="text-no-wrap">{{ item ? item.id : "" }}</span>
            <span>{{ item.computed.status }}</span>
            <span>{{ item.room.roomType.name }}</span>
            <span>{{ item.room.name }}</span>
            <div>
              <div
                v-if="
                  booking.bookingGroup.createdClient ||
                  booking.bookingGroup.createdSubMember
                "
              >
                <span v-if="booking.bookingGroup.createdClient">{{
                  booking.bookingGroup.createdClient.name
                }}</span>
                <span v-else-if="booking.bookingGroup.createdSubMember">{{
                  booking.bookingGroup.createdSubMember.name
                }}</span>
              </div>
            </div>
            <template v-if="showExtraGroupInfo">
              <span>
                {{ getCheckStayDay(item.checkInDate, item.checkOutDate) }}
              </span>
              <span>
                {{ getStay(item.checkInDate, item.checkOutDate) }}
              </span>
            </template>
            <span>
              <v-btn
                @click="openBooking(item.id)"
                color="var(--text__primary)"
                outlined
                small
                target="_blank"
                :disabled="!checkPerUser"
              >
                {{ $t("buttons.reservationEdit") }}
              </v-btn>
            </span>
          </div>
        </div>
      </template>
      <v-btn
        v-if="addToBookingGroupAllowedType"
        class="mt-3"
        color="var(--text__primary)"
        outlined
        small
        @click="$emit('addGroup')"
        :disabled="!checkPerUser || !booking.contract"
      >
        <v-icon class="mr-2">mdi-plus</v-icon>
        {{ $t("contractDetail.newReservationsAdded") }}
      </v-btn>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    booking: Object,
    checkPerUser: Boolean
  },
  computed: {
    isMw () {
      return this.booking.bookingTypeId === 10
    },
    isOTA () {
      return this.booking.bookingTypeId === 8
    },
    addToBookingGroupAllowedType () {
      return !this.isMw && !this.isOTA
    },
    showExtraGroupInfo () {
      return this.isMw
    }
  },
  methods: {
    getCheckStayDay (checkInDate, checkOutDate) {
      var fromDate = new Date(checkInDate)
      var month = fromDate.getUTCMonth() + 1
      var day = fromDate.getUTCDate()
      const checkIn = month + '/' + day

      var toDate = new Date(checkOutDate)
      var months = toDate.getUTCMonth() + 1
      var days = toDate.getUTCDate()
      const checkOut = months + '/' + days
      return checkIn + '-' + checkOut
    },
    getStay (checkInDate, checkOutDate) {
      const date =
        (new Date(checkOutDate) -
          new Date(checkInDate)) /
        (1000 * 60 * 60 * 24)
      return date + '泊'
    },
    openBooking (id) {
      this.$goToRoute.booking.open(id)
    }
  }
}
</script>
