<template>
  <v-color-picker
    hide-details
    hide-canvas
    hide-sliders
    hide-inputs
    :value="value"
    @input="$emit('input', $event)"
    :swatches="swatches"
    show-swatches
    :disabled="disabled"
  />
</template>

<script>
export default {
  props: {
    value: String,
    disabled: Boolean
  },
  computed: {
    swatches () {
      const perChunk = 4
      return [
        '000000',
        'c0c0c0',
        'ffffff',
        '4169e1',
        '00008b',
        '008000',
        '66cdaa',
        'deb887',
        'ffff00',
        'ffd700',
        'ffa500',
        'ff0000',
        'c71585',
        'ff69b4'
      ].map(v => `#${v}`).reduce((resultArray, item, index) => {
        const chunkIndex = index % perChunk

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      }, [])
    }
  }
}
</script>
