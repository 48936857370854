<template>
  <div class="charge-info">
    <div class="charge-info-line">
      【参考】TOKYUポイント付与対象総額：<TvosJpy :value="chargeInfo.tokyuPointTargetTotal" />
    </div>
    <div class="charge-info-line">
      【参考】コンフォートメンバーズポイント：
      <template v-if="typeof chargeInfo.comfortPointTotal === 'number'">
        <TvosJpy :value="chargeInfo.comfortPointTotal" />相当
      </template>
      <template v-else>
        要備考確認
      </template>
    </div>
  </div>
</template>

<script>
import TvosJpy from '../DataItem/TvosJpy.vue'

export default {
  components: { TvosJpy },
  props: {
    chargeInfo: Object
  },
}
</script>

<style lang="scss" scoped>
.charge-info {
  text-align: right;
}
</style>
