<template>
  <v-dialog
    v-model="dialog"
    @click:outside="closeDialog"
    :content-class="isGrayDialog ? 'gray-dialog' : ''"
  >
    <p :class="isGrayDialog ? '' : 'page-title-main'">
      {{ title }}
      <slot name="title"></slot>
    </p>
    <slot name="content"></slot>
    <slot name="footer"></slot>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    visible: Boolean,
    title: String,
    contentClass: {
      type: String,
      default: ''
    },
    isGrayDialog: {
      type: Boolean,
      default: false
    },
    width: {
      type: [String, Number],
      default: 500
    }
  },
  data () {
    return {}
  },
  computed: {
    dialog: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  p.page-title-main {
    color: #000 !important;
    font-size: 22px  !important;
    font-weight: bold  !important
  }
  .v-form {
    label {
      color: #666666;
      font-size: 16px;
      padding-top: 19px;
    }
  }
  .btn-cancel {
    color: #FFFFFF;
    font-size: 17px;
    background: #DB3069 !important;
  }

  .btn-create {
    color: #FFFFFF;
    font-size: 17px;
    background: #004F8B !important;
  }
  background-color: var(--bg__white);
  padding: 25px;
  border-radius: 9px;
  .v-label {
    font-size: 14px;
  }
  &.gray-dialog {
    color: var(--text__gray);
    .v-input {
      /** {*/
      /*  color: var(--text__gray) !important;*/
      /*}*/
    }
    h2 {
      font-size: 30px;
      color: var(--text__inactive) !important;
    }
  }
}
::v-deep{
  .v-dialog{
    min-width: 400px;
    width: auto;
  }
}
</style>
