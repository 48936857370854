<template>
  <v-card class="px-5 px-sm-10 pt-5 py-10">
    <v-card-text>
      <v-row class="d-flex align-center justify-end">
        <v-btn
          color="var(--bt__red)"
          class="mr-3 d-flex align-center btn_status white--text"
          href="javascript:window.open('','_self').close();"
        >
          <v-icon class="white--text"> mdi-close </v-icon>
          {{ $t("common.close") }}
        </v-btn>
        <v-btn @click="actionUpdateData()" class="white--text" color="var(--bt__blue)" :disabled="!checkPerUser">
          {{ $t("common.save") }}
        </v-btn>
      </v-row>
      <v-row class="d-flex align-center justify-end">
        <v-btn class="mt-10 btn-add-parking" style="border: solid 3px; color: #4683D5" @click="addNewVisible = true" :disabled="!checkPerUser">
          <v-icon> mdi-plus </v-icon>
          <p class="custom-add-value mt-3">{{ $t("calendar.rental.addRentalListing") }}</p>
        </v-btn>
      </v-row>
    </v-card-text>
    <!-- <v-card-text class="mt-4">
      <span style="color: #666666; font-size: 16px">
        {{ $t("calendar.rental.rentalListing") }}============cut
      </span>
    </v-card-text> -->
    <v-form ref="form">
      <v-card-text>
      <v-data-table
        disable-sort
        :headers="header"
        :items="arrNew"
        hide-default-footer
        disable-pagination
        class="table-cus-td table-cus-th"
      >
        <template v-slot:[`item.period`]="{ item }">
          <div class="ml-4 d-flex align-center">
            <v-menu
              :ref="'menu'+item.id"
              :value="item.showDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              >
               <template v-slot:activator="{ on, attrs }">
                <div class="d-flex align-center">
                  <v-icon class="ml-n8 mr-2" style="color:#616161 !important; font-size:30px !important">mdi-calendar-month</v-icon>
                  <v-text-field
                    :rules="rule.datePicker"
                    :value="formatDate(item.dates)"
                    @click.stop="item.showDate = true"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    dense
                    outlined
                    class="mt-5 custom-input"
                    :disabled="!checkPerUser"
                  ></v-text-field>
                </div>
              </template>
                  <v-date-picker
                    @input="changeDate($event, item.id, item)"
                    :rules="rule.datePicker"
                    :max="checkOutDate"
                    :min="checkInDate"
                    no-title
                    scrollable
                    :value="item.dates"
                    range
                    :first-day-of-week="0"
                    :locale="$i18n.locale"
                    class="v-date-picker-custom"
                  >
                    <v-spacer></v-spacer>
                  <v-btn
                    color="var(--bt__blue)"
                    class="white--text"
                    @click="$refs['menu'+item.id].save(item.dates)"
                    :disabled="item.dates.length < 2"
                  >
                    {{ $t('buttons.save') }}
                  </v-btn>
                  </v-date-picker>
              </v-menu>
          </div>
        </template>
        <template v-slot:[`item.quantity`]="{ item }">
          <v-select
            @change="changeQuantity($event, item)"
            v-model="item.quantity"
            dense
            outlined
            :items="getItemQuantity(item)"
            class="text-size-normal mt-5 custom-select"
            :disabled="!checkPerUser"
          >
          </v-select>
        </template>

        <template v-slot:[`item.rentalName`]="{ item }">
          <span>{{ item.rentalItem.rentalItemDef.name }}</span>
        </template>

        <template v-slot:[`item.unitPrice`]="{ item }">
          <span>{{
            item.unitPrice.toLocaleString("ja-JP", {
              style: "currency",
              currency: "JPY",
            })
          }}</span>
        </template>

        <template v-slot:[`item.rentalPeriod`]="{ item }">
          <span>{{ item.rentalPeriod === "Day" ? "一泊" : "一滞在" }}</span>
        </template>

        <template v-slot:[`item.status`]="{item}">
          <v-btn color="#ffffff" @click="actionDelete(item.id)" :disabled="!checkPerUser">
            <span style="color: #FF0000; font-size: 16px">{{ $t("buttons.delete") }}</span>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    </v-form>
    <AddRentalListing
      v-if="addNewVisible"
      :arrNew="arrNew"
      :arrRentalItem="arrRentalItem"
      :checkInDate="checkInDate"
      :checkOutDate="checkOutDate"
      :quantityRental="quantityRental"
      :visible="addNewVisible"
      @close="addNewVisible = false"
    />
  </v-card>
</template>

<script>
import AddRentalListing from '../../dashboard/calendar/carPark/addRentalListing'
import {
  BOOKING,
  RENTAL_ITEM_LIST
} from '@/api/graphql/bookingDetail/booking-rental-list'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import gql from 'graphql-tag'

export default {
  name: 'RentalListing',
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      arrUpdateRental: [],
      testDate: ['2021-09-11', '2021-09-16'],
      menuDate: false,
      checkInDate: '',
      checkOutDate: '',
      quantityRental: null,
      arrRentalItem: [],
      facilityId: null,
      itemQuantity: [],
      arrNew: [],
      showDate: false,
      listRentalItem: [],
      addNewVisible: false,
      addParkingVisible: false,
      parkingLotList: [],
      selectParkingLotList: ['機械式1', '機械式2', '機械式3'],
      selectValue: [],
      rule: {
        datePicker: [v => this.checkdatePicker(v) || this.$t('rules.isRequired'),
          v => this.checkDuplicate(v) || this.$t('rules.isInvalid'),
          v => this.checkFormatdate(v) || this.$t('rules.isInvalid')
        ]
      },
      header: [
        // { text: '', value: 'id' },
        { text: this.$t('calendar.rental.rentalListing'), value: 'rentalName', width: '500px' },
        { text: this.$t('calendar.period'), value: 'period', width: '350px' },
        { text: this.$t('common.quantity'), value: 'quantity', width: '100px' },
        { text: this.$t('calendar.rental.unitPrice'), value: 'unitPrice' },
        { text: this.$t('common.paymentUnit'), value: 'rentalPeriod' },
        { text: '', value: 'status' }
      ]
    }
  },
  computed: {
    ...mapGetters(['getStatusCreate', 'getStatusUpdate'])
  },

  watch: {
    getStatusCreate () {
      this.getBookingRentalList()
    }
  },

  mounted () {
    this.getParkingLotList()
    this.getBookingRentalList()
  },
  methods: {
    ...mapMutations({
      setUpdateBookingRental: 'setUpdateBookingRental',
      setDeleteBookingRental: 'setDeleteBookingRental'
    }),
    ...mapActions(['updateRentalItemReservation', 'deleteRentalItemReservation']),
    ...mapMutations(['setStatusUpdate', 'setLoadingOverlayHide', 'setLoadingOverlayShow']),

    getItemQuantity (item) {
      const countQuantity = []
      for (let i = 0; i < this.arrNew.length; i++) {
        if (item.rentalItem.id === this.arrNew[i].rentalItem.id) {
          countQuantity.push(this.arrNew[i].quantity)
        }
      }
      let sum = 0
      countQuantity.forEach(value => {
        sum += value
      })

      const numList = []
      const tempItem = this.arrRentalItem.find(el => el.name === item.rentalItem.rentalItemDef.name)
      if (tempItem) {
        for (let i = 0; i <= (tempItem.quantity - sum + item.quantity); i++) {
          numList.push(i)
        }
        return numList
      }
    },

    checkFormatdate (event) {
      const arrRule = event.split(' ~ ')
      if (arrRule.length === 2) {
        const date1 = new Date(arrRule[0])
        const date2 = new Date(arrRule[1])
        const date3 = date2.getTime() - date1.getTime()
        if (date3 > 0) {
          return true
        } else {
          return false
        }
      }
    },

    checkdatePicker (date) {
      if (date !== null) {
        date = date.split(' ~ ')
        if (date.length < 2) {
          return false
        } else {
          return true
        }
      }
    },

    checkDuplicate (date) {
      date = date.split(' ~ ')
      if (date.length === 2) {
        if (date[0] === date[1]) {
          return false
        } else {
          return true
        }
      }
    },

    changeDate (event, item, items) {
      const arrNew = [...this.arrNew]
      const index = arrNew.findIndex(e => e.id === item)
      if (index !== -1) {
        if (event.length === 2) {
          const arr = []
          const date1 = new Date(event[0])
          const date2 = new Date(event[1])
          const date3 = date2.getTime() - date1.getTime()
          if (date3 > 0) {
            arr.push(date1.toISOString().substr(0, 10))
            arr.push(date2.toISOString().substr(0, 10))
          } else {
            arr.push(date2.toISOString().substr(0, 10))
            arr.push(date1.toISOString().substr(0, 10))
          }
          const data = {
            newQuantity: items.quantity,
            newFromDate: arr[0],
            newToDate: arr[1],
            rentalItemReservationId: items.id
          }
          this.arrUpdateRental.push(data)
        }
        arrNew[index].dates = event
      }
      this.arrNew = arrNew
    },

    changeQuantity (event, item) {
      this.statusChange = true
      const data = {
        newQuantity: event,
        rentalItemReservationId: item.id,
        newFromDate: item.dates[0],
        newToDate: item.dates[1]
      }
      this.arrUpdateRental.push(data)
    },

    formatDate (date) {
      const dates = date.join(' ~ ')
      return dates.replace(/-/g, '/')
    },

    formatData () {
      this.arrNew = []
      for (let i = 0; i < this.listRentalItem.length; i++) {
        const item = this.listRentalItem[i]
        const arrDate = []
        arrDate.push(this.listRentalItem[i].fromDate)
        arrDate.push(this.listRentalItem[i].toDate)
        item.dates = arrDate
        item.showDate = false
        this.arrNew.push(item)
      }
    },

    actionUpdateData () {
      const arrayId = []
      const B = []
      for (let i = this.arrUpdateRental.length - 1; i >= 0; i--) {
        if (!arrayId.includes(this.arrUpdateRental[i].rentalItemReservationId)) {
          arrayId.push(this.arrUpdateRental[i].rentalItemReservationId)
          B.push(this.arrUpdateRental[i])
        }
      }
      const arrSort = _.sortBy(B, 'newQuantity')

      if (this.$refs.form.validate()) {
        for (let i = 0; i < arrSort.length; i++) {
          if (this.getStatusUpdate) {
            this.setUpdateBookingRental({ updateBookingRental: arrSort[i] })
            this.updateRentalItemReservation()
          }
          this.setStatusUpdate(false)
        }
        this.arrUpdateRental = []
      }
    },
    actionDelete (item) {
      const deleteBookingRental = {
        rentalItemReservationId: item
      }
      this.setDeleteBookingRental({ deleteBookingRental: deleteBookingRental })
      this.deleteRentalItemReservation()
    },

    async getBookingRentalList () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`${BOOKING}`,
          variables: {
            id: parseInt(this.$route.query.id)
          },
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.setLoadingOverlayHide()
          this.checkInDate = data.data.booking.checkInDate
          this.checkOutDate = data.data.booking.checkOutDate
          this.facilityId = data.data.booking.room.roomType.facility.id
          this.listRentalItem = data.data.booking.rentalItems
          this.formatData()
          this.getRentalitemList()
          for (let i = 0; i <= 50; i++) {
            this.itemQuantity.push(i)
          }
        })
        .catch((error) => {
          this.setLoadingOverlayHide()
          console.error(error)
        })
    },

    async getRentalitemList () {
      await this.$apollo
        .query({
          query: gql`${RENTAL_ITEM_LIST}`,
          variables: {
            facilityId: parseInt(this.facilityId)
          },
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.quantityRental = data.data.quantity
          for (let i = 0; i < data.data.rentalItemList.length; i++) {
            const item = {}
            item.id = data.data.rentalItemList[i].id
            item.name = data.data.rentalItemList[i].rentalItemDef.name
            item.quantity = data.data.rentalItemList[i].quantity
            this.arrRentalItem.push(item)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },

    getParkingLotList () {
      for (let i = 0; i < 3; i++) {
        this.parkingLotList.push({
          id: i,
          showDate: false,
          rentalListing: i === 0 ? 'ブルーレイDVDレコーダー' : '麻雀セット',
          quantity: 1,
          unitPrice: i === 0 ? '¥0' : '¥2,200',
          paymentUnit: i === 0 ? '1滞在' : '１泊',
          dates: ['2019-09-10', '2019-09-20'],
          status: '削除'
        })
      }
    }
  },
  components: {
    AddRentalListing
  }
}
</script>

<style lang="scss" scoped>
.custom-add-value {
  font-size: 18px;
  color: #3083DC;
}
.btn-add-parking {
  border: var(--text__primary) 2px solid;
  background-color: var(--bt__while) !important;
  color: var(--text__primary);
}
.text-size-normal::v-deep input {
  font-size: 14px;
}
::v-deep {
  .v-data-table {
    thead th {
      font-weight: normal;
      color: #666666 !important;
      font-size: 16px !important;
    }
    tbody tr td {
      font-size: 16px !important;
      color: #666666 !important
    }
  }
  .mdi-plus::before {
  color: #3083DC !important;
  font-size: 40px;
  font-weight: bold;
}
   svg {
    color: #000000 !important;
  }
  .custom-input input{
    font-size: 18px !important;
    color: #666666
  }
  .custom-select .v-select__selection--comma {
    color: #666666 !important;
    font-size: 18px !important;
  }
  .custom-select svg {
    color: #000000 !important;
    width: 40px;
    height: 40px;
  }
}
</style>
<style lang="scss">
.table-cus-th tr th {
  white-space: nowrap;
  padding-bottom: 8px !important;
}
.table-cus-td {
  table tr td:first-child {
    width: 70px;
    @media (min-width: 1300px) {
      width: 100px;
    }
    @media (min-width: 1500px) {
      width: 130px;
    }
  }
}
</style>
