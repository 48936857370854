<!-- https://dev.to/loilo92/an-approach-to-vuejs-template-variables-5aik -->
<!-- From time to time, I have the need to temporarily store the results of a method call in Vue templates. This is particularly common inside loops, where we cannot easily use computed properties. -->
<script>
export default {
  name: 'Pass',

  render () {
    return this.$scopedSlots.default(this.$attrs)
  }
}
</script>
