<template>
  <v-row>
    <v-col sm="8" class="pt-0 pb-0">
      <h6 class="mt-4 text-card-gray fz-10 text-card-pay">精算方法{{ index }}</h6>
      <v-autocomplete
        v-model="method"
        :items="options"
        dense
        small
        outlined
        hide-details
        class="v-select-custom mr-3"
        item-text="name"
        item-value="id"
        no-data-text="見つかりませんでした。"
        :disabled="disabled"
        style="width: 300px"
        clearable
      ></v-autocomplete>
    </v-col>
    <v-col sm="4" class="pt-0 pb-0">
      <h6 class="mt-4 text-card-gray fz-10 text-card-pay">金額{{ index }}</h6>
      <v-text-field
        v-model="amount"
        dense
        solo
        hide-details
        class="amount-pay"
        type="number"
        @keypress="isNumber($event)"
        :disabled="disabled"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    options: Array,
    value: Object,
    index: Number
  },
  computed: {
    method: {
      get () {
        return this.value?.method
      },
      set (value) {
        this.$emit('input', { ...this.value, method: value })
      }
    },
    amount: {
      get () {
        return this.value?.amount
      },
      set (value) {
        this.$emit('input', { ...this.value, amount: value })
      }
    }
  },
  methods: {
    isNumber: function (evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 44 && charCode < 58 && charCode !== 47 && charCode !== 46 && charCode !== 45) || (charCode === 13)) {
        return true
      } else {
        evt.preventDefault()
      }
    }
  }
}
</script>
