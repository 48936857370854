<template>
  <v-dialog v-model="showDialog" persistent width="1600px">
    <v-card class="pa-5">
      <ReservationCalendarForMove
        :bookingId="bookingId"
        :defaultSelectedInventories="selectedInventories"
        @next="setNewStayDates"
        @close="close"
      />
      </v-card>
  </v-dialog>
</template>

<script>
import ReservationCalendarForMove from '@/views/dashboard/reservation/ReservationCalendarForMove';

export default {
  name: 'SelectFrameDialog',
  components: { ReservationCalendarForMove },
  props: {
    showDialog: Boolean,
    bookingId: Number,
    selectedInventories: Array
  },
  methods: {
    setNewStayDates (payload) {
      this.$emit('setNewStayDates', payload)
    },
    close () {
      this.$emit('close')
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
