<template>
  <div class="cancel-penalty-data">
    <div><div class="label">予約時料金:</div><div class="value">{{ amount | toThousands }} {{ unit }}</div></div>
    <div><div class="label">ペナルティ:</div><div class="value">{{ penaltyAmount | toThousands }} {{ unit }}</div></div>
    <div><div class="label">差額:</div><div class="value">{{ refundAmount | toThousands }} {{ unit }}</div></div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
export default {
  props: {
    item: Object,
    cancelDate: String
  },
  data () {
    return {
      priceWithoutTax: null // need this to calculate
    }
  },
  computed: {
    parsed () {
      if (this.item.price) {
        return {
          amount: this.item.price,
          penaltyAmount: Math.floor(this.priceWithoutTax * this.item.penaltyRate),
          unit: '円'
        }
      } else if (this.item.spPoint) {
        return {
          amount: this.item.spPoint,
          penaltyAmount: Math.floor(this.item.spPoint * this.item.penaltyRate),
          unit: 'SP'
        }
      } else if (this.item.fpPoint) {
        return {
          amount: this.item.fpPoint,
          penaltyAmount: Math.floor(this.item.fpPoint * this.item.penaltyRate),
          unit: 'FP'
        }
      } else if (this.item.tvpPoint) {
        return {
          amount: this.item.tvpPoint,
          penaltyAmount: Math.floor(this.item.tvpPoint * this.item.penaltyRate),
          unit: 'TVP'
        }
      } else {
        return {
          amount: 0,
          penaltyAmount: 0,
          unit: '円'
        }
      }
    },
    amount () { return this.parsed.amount },
    unit () { return this.parsed.unit },
    penaltyAmount () { return this.parsed.penaltyAmount },
    refundAmount () { return this.amount - this.penaltyAmount }
  },
  apollo: {
    priceWithoutTax: {
      query: gql`query getPriceWithoutTax (
        $price: Int!
        $cancelDate: DateString!
      ) {
        salesTax(
          price: $price
          salesTaxType: Normal
          taxIncluded: true,
          chargeDate: $cancelDate
        ) {
          priceWithoutSalesTax
        }
      }
      `,
      update: data => data.salesTax.priceWithoutSalesTax,
      skip () {
        return this.parsed.unit !== '円' || !this.cancelDate
      },
      variables () {
        return {
          price: this.parsed.amount,
          cancelDate: this.cancelDate
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.label{
  min-width: 85px;
}
.cancel-penalty-data {
  & > div {
    display: flex;
  }
}
</style>
