var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header-main"},[_c('h3',{staticClass:"font-weight-bold py-4 page-title-main"},[_vm._v(" 枠を選択してください ")]),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("buttons.close"))+" ")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.ready},on:{"click":_vm.next}},[_vm._v(" "+_vm._s(_vm.$t("buttons.next"))+" ")])],1)]),(_vm.$apollo.queries.booking.loading || !_vm.booking)?_c('div',[_vm._v("読み込み中...")]):_c('div',{staticClass:"px-main"},[_c('facility-room-type-calendar',{ref:"calendar",attrs:{"hideCancelWait":"","height":"800px","fixedFacility":"","default-values":{ facilityId: _vm.facilityId, roomTypeId: _vm.roomTypeId, fromDate: _vm.oldCheckInDate }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var facility = ref.facility;
        var fromDate = ref.fromDate;
        var toDate = ref.toDate;
        var roomType = ref.roomType;
        var completed = ref.completed;
        var refreshCount = ref.refreshCount;
        var dateRange = ref.dateRange;
        var dateColWidth = ref.dateColWidth;
return [_c('div',{staticClass:"d-flex calendar-block_tt"},[_c('div',{staticClass:"t-calendar-item__title"},[_c('div',{staticClass:"d-flex align-center cursor-pointer",on:{"click":function($event){return _vm.togglePriceClosed(roomType.id)}}},[(!_vm.isPriceClosed[roomType.id])?_c('v-icon',[_vm._v("mdi-chevron-down")]):_c('v-icon',[_vm._v("mdi-chevron-up")]),_c('div',[_vm._v("料金表示")])],1)]),_vm._l((dateRange),function(date){return _c('div',{key:date,staticClass:"t-calendar-item__data-item"})})],2),_c('fees-and-points-room-type',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPriceClosed[roomType.id]),expression:"isPriceClosed[roomType.id]"}],ref:_vm.feesAndPointKey(roomType.id),attrs:{"facility":facility,"fromDate":fromDate,"toDate":toDate,"roomType":roomType,"isShowAccomList":true,"isShowBookingList":true,"mwWeekCalendarList":[],"forReservation":"","dateColWidth":dateColWidth}}),_c('frame-setting-calendar-room-type',{attrs:{"refreshCount":refreshCount,"fromDate":fromDate,"toDate":toDate,"roomType":roomType,"completed":completed,"isInventorySelected":_vm.isInventorySelected,"isInventoryValid":_vm.isInventoryValid,"mode":"booking"},on:{"inventoryClick":_vm.inventoryClick}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }